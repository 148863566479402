import { Variants } from "framer-motion";

export const FadeIn=(direction:"up"|"down"="up"):Variants=>{
   return{
    initial:{
        y:direction==='up'?40:-60,
        opacity:0
    },
    animate:{
        y:0,
        opacity:1 ,
        transition:{
            duration:1.0,
            ease:'easeInOut'
        }
    },
}
    
}
export const Counter2:Variants={
    initial:{},
    animate:{
        transition:{
             staggerChildren:1.5,
             delayChildren:3.0
        }
    }
}

export const FadeShow=():Variants=>{
    return{
     initial:{
          opacity:0
     },
     animate:{
         y:0,
         opacity:1 ,
         transition:{
             duration:4.0,
             ease:'easeInOut'
         }
     },
 }
     
 }

export const Counter:Variants={
    initial:{},
    animate:{
        transition:{
             staggerChildren:2.0,
             delayChildren:1.0
        }
    }
}