import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MobileUfarm from "../MobileUfarm";

// import RocketUni from "../../../assets/image/rocketUni.png";
// import { Link } from 'react-router-dom';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const Accordion = withStyles({
  root: {
    border: "unset",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "white",

    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function CustomizedAccordions() {
  // const [expanded, setExpanded] = React.useState<string | false>('');

  // const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
  //   setExpanded(newExpanded ? panel : false);
  // };
  const ApplyBtn = () => {
    window.open("https://airtable.com/shrOYXo0btVCvILhw");
  };

  return (
    <div>
      <MobileUfarm />

      {/* <Accordion square>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography
            className="text-2xl flex justify-between items-center"
            style={{ width: "100%" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://fellowship-program.super.site/"
              style={{ color: "#000000DE" }}
              // className="leading-10 text-white"
            >
              Fellowship Program
            </a>
          </Typography>
        </AccordionSummary>
      </Accordion> */}
      {/* <Accordion square>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography
            className="text-2xl flex justify-between items-center"
            style={{ width: "100%" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://unifarm.notion.site/Crypto-Meme-Carnival-7268b26443f346c78d54f1a765eac251"
              style={{ color: "#000000DE" }}
              // className="leading-10 text-white"
            >
              Meme Carnival
              <span style={{ position: "relative", left: "0px" }}></span>
            </a>
          </Typography>
        </AccordionSummary>
      </Accordion> */}

      <Accordion square>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography
            className="text-2xl flex justify-between items-center"
            style={{ width: "100%" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="/launch"
              style={{ color: "#000000DE" }}
              // className="leading-10 text-white"
            >
              UniLaunch
              {/* <span style={{ position: "relative", left: "0px" }}></span> */}
              {/* <img
                src={RocketUni}
                style={{ position: "relative", right: "-140px", top: "-28px" }}
              /> */}
            </a>
          </Typography>
        </AccordionSummary>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography
            className="text-2xl flex justify-between items-center"
            style={{ width: "100%" }}
          >
            About
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-col text-xl">
            {/* <Link to="#" className='leading-10 mb-3 text-white'>Vission</Link>
              <Link to="#" className='leading-10 mb-3 text-white'>Mision</Link> */}
            <a
              href="https://careers.unifarm.co/#72efd83d0f8f4d2682522c1d9402db01"
              className="leading-10 mb-3 text-white text-white"
            >
              Team
            </a>
            <a
              href="https://fellowship-program.super.site/"
              className="leading-10 mb-3 text-white text-white"
            >
              Fellowship Program
            </a>
            <a
              href="https://uni.farm/buildinpublic/"
              className="leading-10 mb-3 text-white text-white"
            >
              Build in Public
            </a>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography
            className="text-2xl flex justify-between items-center"
            style={{ width: "100%" }}
          >
            Resources
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-col text-xl text-white">
            <a
              href="https://blog.unifarm.co/"
              className="leading-10 text-white mb-4"
            >
              Blog
            </a>
            <a
              href="https://docs.unifarm.co/"
              className="leading-10 mb-3 text-white"
            >
              Whitepaper
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://careers.unifarm.co/#64c6021516474f12bb96bf0381e2989c"
              className="leading-10 mb-3 text-white"
            >
              Career
            </a>
            {/* <Link to="#" className='leading-10 mb-3 text-white'>Knowledge Hub</Link> */}
            <a
              target="_blank"
              rel="noreferrer"
              href="/faq"
              className="leading-10 text-white"
            >
              FAQs
            </a>
          </div>
        </AccordionDetails>
      </Accordion>
      <button
        onClick={ApplyBtn}
        className="bg-DropColor pt-1.5 pb-1.5 pl-3 border-2 rounded-full text-2xl pr-3 w-full mt-4 grounded-radiants "
      >
        Apply for cohort
      </button>
    </div>
  );
}
