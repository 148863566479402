import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { motion } from "framer-motion";
import AOS from "aos";
import MyImage from "../../Exploresection/Subcomponent/ImageSection";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardImage from "./CardImage";

const useStyles = makeStyles((theme: Theme) => ({
  IpadCard: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: "300px !important",
    },
  },
}));

interface IsMyCard {
  Url: any;
  Imagewidth: number;
  Title: string;
}
const CaseStudyCard = ({ Url, Imagewidth, Title }: IsMyCard) => {
  useEffect(() => {
    AOS.init();
  });
  const [translate, setTranslet] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <motion.div animate={{ translateY: translate ? "-18px" : "0px" }}>
      {Mobile ? (
        <>
          <Card
            data-aos="zoom-in"
            className={classes.IpadCard + " " + "Ant-card-new"}
            onMouseEnter={() => setTranslet(true)}
            onMouseLeave={() => setTranslet(false)}
          >
            <div>
              <CardImage Url={Url} width={Imagewidth} margin={3} />
            </div>
            <div className="ml-5 mt-4">
              <b className="common-fontFamily">{Title}</b>
            </div>
          </Card>
        </>
      ) : (
        <Card
          data-aos="zoom-in"
          className={classes.IpadCard + " " + "Ant-card-new"}
          onMouseEnter={() => setTranslet(true)}
          onMouseLeave={() => setTranslet(false)}
        >
          <div>
            <MyImage Url={Url} width={Imagewidth} margin={3} />
          </div>
          <div className="ml-5 mr-5 mt-4">
            <b className="common-fontFamily">{Title}</b>
          </div>
        </Card>
      )}
    </motion.div>
  );
};
export default CaseStudyCard;
