import React, { useLayoutEffect } from "react";
import About from "./About";
import Tab from "./Tab/Tab";
import Terms from "./terms";
import Logo from "../../../assets/image/Logo/logo2.png";
// import Hero_Img2 from "../../../assets/Ido/Hero_img2.png";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../Header";
import Container from "@material-ui/core/Container";
import BattleverseGradientSmall from "../../../assets/image/BattleverseGradientsmall.png";
import AccordionSale from "./AccordionSale";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import BattleversePc from "../../../assets/image/bverse.png";
import BattleverseMobsmall from "../../../assets/image/BattleverseMobsmall.png";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100vh",
    position: "absolute",
    backgroundImage: `url(${BattleverseGradientSmall})`,
    backgroundSize: "cover",
  },
  MobileRoot: {
    width: "100%",
    height: "100vh",
    position: "absolute",
    background: "#494949",
    // backgroundImage: `url(${Mobile_hero})`,
    // backgroundSize: "contain",
    // backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
      marginTop:"100px"
    },
  },
  Main_div: {
    width: "100%",
    // height: "100vh",
    height: "100vh",
    position: "relative",
    top: 0,
    left: 0,
    paddingTop: "1px",
    [theme.breakpoints.down("xs")]: {
      height: "80vh",
    },
  },
  Title: {
    fontSize: "48px",
    fontWeight: 900,
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "58px",
    color: "#ffffff",
  },
  Date: {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#ffffff",
    marginTop: "15px",
  },
  Mobile_img: {
    width: "100%",
    height: "500px",
    marginTop: "75px",
  },
  Title_div: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    paddingTop: "330px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "80px",
    },
  },
}));

const Battleverse = () => {
  // useEffect(() => {

  //  window.location.href="/launch"

  // },[]);

  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      {Mobile ? (
        <div className={classes.MobileRoot}>
          <img src={BattleverseMobsmall} className={classes.Mobile_img} />
          {/* <img src={Mobile_root} style={{ marginTop: "80px", height: "400px", opacity: 0.2 }} /> */}
        </div>
      ) : (
        <div className={classes.Root}></div>
      )}
      {/* <img
        src={Hero_Img2}
        alt="hero"
        style={{
          width: "100%",
          height: "100vh",
          position: "absolute",
          objectFit: "cover",
        }}
      /> */}
      <div className={classes.Main_div}>
        <Container maxWidth="lg">
          <Header ClickHeader={() => null} />
          <div className={classes.Title_div}>
            <span className={classes.Title}>Battleverse IDO</span>
            <span className={classes.Date}>Date: 17/02/2022</span>
          </div>
        </Container>
      </div>
      <About />
      <AccordionSale />
      <Tab />
      <Terms />
      <div className="flex flex-col justify-center mt-16 items-center  "
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   marginTop: "4rem",
        //   alignItems: "center",
        //   flexDirection: "column",
        // }}
      >
        <div>
          <a href="/">
          </a>
            <img src={Logo}   style={{ height: "auto", width: 215 }} />
        </div>
        <a href="/">
          <span className= "text-base mt-10 mb-5"
            // style={{
            //   fontSize: "15px",
            //   marginTop: "40px",
            //   marginBottom: "20px",
            //   color: "black",
            // }}
          >
            Back To Home
          </span>
        </a>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Battleverse;
