import React from 'react'
import { makeStyles, Theme } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "211px",
        height: "280px",
        // border:'1px solid',
        "&:hover": {
            transform: 'scale(1.01)',
            filter: "drop-shadow(0px 10px 40px #E0E0E0)",
            background: '#FFFFFF',
            borderRadius: 15
        },
        [theme.breakpoints.down('xs')]: {

        }
    },
    CardDetail: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    empName: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "26px"
    },
    empDesg: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "20px"
    },
    AvtarStyle: {
        height: '136px', width: '136px', background: '#9D3FE7', marginTop: '1.2rem', marginBottom: '1.2rem'
    }
}))
interface IsProfileCard {
    EmpName: string
    EmpDesignation: string
}
const ProfileCard = ({ EmpName, EmpDesignation }: IsProfileCard) => {
    const classes = useStyles()
    const [ViewIcone,setIcon]=React.useState(false)
    return (
        <>
            <div className={classes.root} onMouseEnter={()=>setIcon(true)} onMouseLeave={()=>setIcon(false)}>
                <div className={classes.CardDetail}>
                    <Avatar className={classes.AvtarStyle} />
                    <span className={classes.empName}>{EmpName}</span>
                    <span className={classes.empDesg}>{EmpDesignation}</span>
                    {
                        ViewIcone?
                        <div style={{ marginTop: '0.5rem' }}>
                        <LinkedInIcon style={{ fontSize: 20 }} />
                        &nbsp;&nbsp;
                        <GitHubIcon style={{ fontSize: 20 }} />
                    </div>
                    :null
                    }
                   
                </div>
            </div>
        </>
    )
}
export default ProfileCard