import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
    marginBottom: "5.4rem",
    background: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      // display:'none'
    },
  },

  mainRoot: {
    width: "1132px",
    // height: "170px",
    left: "154px",
    top: "1938px",
    background: "#FFFFFF",
    border: "1px solid #F0EBF8",
    boxSizing: "border-box",
    borderRadius: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "92%",
      height: "100%",
    },
  },
  TermsHead: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    marginBottom: "0.8rem",
  },
  Ptag2: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    marginBottom: "0.5rem",
    color: "#332233",
  },

  Discount: {
    display: "flex",
    // border: "1px solid",
    borderBottom: "1.5px solid #c5c2c2",
    // width:"30%",
    justifyContent: "space-between",
    // padding:"10px",
    backgroundColor: "#F0EBF8",
    color: "#673AB7",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
     
    },
  },

  DiscountList: {
    display: "flex",
    // border: "1px solid",
    // width:"30%",
    borderBottom: "1.5px solid #c5c2c2",
    justifyContent: "space-between",
    // padding:"10px",
    backgroundColor: "#FFFFFF",
    color: "#616161",
  },

  DiscountTable: {
    display: "flex",
    flexDirection: "column",
    // marginBottom: "32px",
    width: "45%",
    border: "2px ",
    marginTop: "10px",
    borderRadius: "10px 10px 0px 0px",
    overflow: "hidden",
    boxShadow: "2px 2px 2px 2px #E0E0E0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom:"0px",
      boxShadow: "0px 0px 0px",
      overflow: "auto",
      // whiteSpace: "nowrap",

    },
  },

  SubDiv: {
    padding: "16px 24px 16px 16px",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "33.33%",
      padding: "17px 12px 11px 12px"
    },
  },

  FontSize:{
    [theme.breakpoints.down("xs")]: {
     fontSize: "13px"
    },

  },


  ConditionList: {
    listStyleType: "number",
    marginBottom: "10px ",
    fontSize: "16px",
    listStylePosition: "inside",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));

const TermsConditions = [
  "Getting whitelisted will make you eligible but does not guarantee you an allocation.",
  "We will follow FCFS for the token sale.",
  "To participate in stakers round you must be holding UniFarm’s staking NFT.",
  "To participate in community round you must be holding  SPORTZCHAIN IDO NFT, which can be earned by participating in Givelab competition.",
  "Price Protection Plan is available for a limited period only",
  " All the investments above $200 will be eligible for the Discount as per slab given below:",
];

const Terms = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.mainRoot}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginTop: "2rem",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <span className={classes.TermsHead}>Terms and conditions</span>

          <div>
            {TermsConditions.map((terms, ind) => {
              return (
                
                <li key={ind} className={classes.ConditionList}>
                  {terms}
                </li>
              );
            })}
          </div>

          <div className={classes.DiscountTable}>
            <div className={classes.Discount}>
              <div className={classes.SubDiv}>
                <span style={{fontSize:"14px"}}>CATEGORY</span>
              </div>
              <div className={classes.SubDiv}>
                <span style={{fontSize:"14px"}}>DISCOUNT</span>
                </div>
              <div className={classes.SubDiv}>
                <span style={{fontSize:"14px"}}>DISCOUNT PRICE</span>
              </div>
            </div>
            <div className={classes.DiscountList}>
              <div className={classes.SubDiv}>Stakers</div>
              <div className={classes.SubDiv}>16.66%</div>
              <div className={classes.SubDiv}>$0.002</div>
            </div>
            <div
              className={classes.DiscountList}
              style={{ borderBottom: "none" }}
            >
              <div className={classes.SubDiv}>Community</div>
              <div className={classes.SubDiv}>10%</div>
              <div className={classes.SubDiv}>$0.00216</div>
            </div>
          </div>

          {/* <div>
            <div style={{ display: "flex", marginLeft: 7 }}>
              <div>
                <FiberManualRecordIcon
                  style={{ fontSize: "11px", marginTop: 0 }}
                />
              </div>
              &nbsp;
              <span className={classes.Ptag2}>
                Getting whitelisted will make you eligible but does not
                guarantee you an allocation.
              </span>
            </div>
            <div style={{ display: "flex", marginLeft: 7 }}>
              <div>
                <FiberManualRecordIcon
                  style={{ fontSize: "11px", marginTop: 0 }}
                />
              </div>
              &nbsp;
              <span className={classes.Ptag2}>
                We will follow FCFS for the token sale.
              </span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Terms;
