import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import uploadIcon from "../../assets/gallery/uploadIcon.png";
import DeleteIcon from "@mui/icons-material/Delete";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 1000,
  borderRadius: "15px",
  boxShadow: 24,
  backgroundColor: "white",
  padding: "34px",
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Heading = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #212121;
`;
const ImagesContainer = styled.div<{ notEmpty: boolean }>`
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  height: 480px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  ${({ notEmpty }) =>
    notEmpty &&
    `
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    overflow-y: scroll;
        `};
`;

const Text = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  margin: 0px;
`;
const MainButton = styled.button`
  background: #673ab7;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  margin: 20px auto;
  padding: 15px 90px;
  cursor: pointer;
`;
const Input = styled.input`
  display: none;
`;
const UpLoadIcon = styled.img``;

const Images = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;
const Image = styled.img`
  width: 155px;
  height: 155px;
  object-fit: cover;
  border-radius: 15px;
  border: 1px solid #bbbaba10;
`;
const AddMoreButton = styled.button`
  width: 155px;
  height: 155px;
  border: 2px dashed #616161;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #616161;
`;
const ImageWrapper = styled.div`
  position: relative;
`;
const StyledDeleteIcon = styled(DeleteIcon)`
  position: absolute;
  top: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.54);
  background: #e0e0e0;
  border-radius: 50%;
  padding: 4px;
  font-size: 26px;
  cursor: pointer;
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 24px;
`;
const Button = styled.button<{ outlined?: boolean }>`
  background: #673ab7;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #ffffff;
  padding: 14px 63px;
  cursor: pointer;
  ${({ outlined }) =>
    outlined &&
    `

    background: #ffffff;
    border: 1px solid #673ab7;
    color: #673ab7;
    box-shadow: none;

  `};
`;

interface Props {
  children: React.ReactNode;
  images: Array<Blob | MediaSource>;
  setImages: React.Dispatch<React.SetStateAction<Array<Blob | MediaSource>>>;
}

export default function UploadImageModal({
  children,
  images,
  setImages,
}: Props) {
  const [open, setOpen] = React.useState(false);

  function handleChange(e: any) {
    console.log(e.target.files);
    setImages([...images, e.target.files[0]]);
  }
  const handleOpen = () => {
    event.preventDefault();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const hiddenFileInput = React.useRef(null);

  // delete image
  const deleteImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };
  //handle cancel
  const handleCancel = () => {
    setImages([]);
    handleClose();
  };
  const authToken =
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5qOEozakNNNGhKUWdiWHRGOXJPTSJ9.eyJpc3MiOiJodHRwczovL2Rldi0ta3EtdjgxNC51cy5hdXRoMC5jb20vIiwic3ViIjoiWGk3ZDUxQXZhSUdRUmRzMmx5R0Rqa3VRS0VWUGk4cEFAY2xpZW50cyIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6MzAwMSIsImlhdCI6MTY2MzgyNDg4NiwiZXhwIjoxNjY2NDE2ODg2LCJhenAiOiJYaTdkNTFBdmFJR1FSZHMybHlHRGprdVFLRVZQaThwQSIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.s9Q1EknsgbPt9juiXwVGPk3nHHaFC45BNBgUUnaSXSFcBz7xACHR3f4WNT5UJ53nDkjyMhgucnYmjJF7vDaEFyyaht--8J8ylHSbPj-2wov1xt3dHN-zVKJRKbOIsL2UTjiwpLfCd4rTZi38LFJwZeKpDsHtXaOR_elAhQ5qxtnsqM-Uj8-TAp21lRLoufH05zID-FgJ7PD605xolX7YZPSOilL9rReNZ11-JlI3k2DZ6Nwp-NAovqAODvXI1cWj4nduc16bYskRhgeoajFRsMOfSq2amu2eUYYNJ4-KPhOuFwaNOADE6N0eiaBb7fCizMi32vVzh7YuJhc0VXqaaQ";
  //handle upload
  const handleUpload = () => {
    console.log("upload");
    console.log(images);
    handleClose();
    fetch("http://143.244.133.3:3001/api/upload/bannerImage/8", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + authToken,
      },
      body: JSON.stringify(images),
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div>
      <div onClick={handleOpen}>{children}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Header>
            <Heading>Upload Image</Heading>
            <CloseIcon
              onClick={handleClose}
              style={{ color: "#212121", cursor: "pointer" }}
            />
          </Header>
          <ImagesContainer notEmpty={images.length > 0}>
            {images.length === 0 ? (
              <>
                <UpLoadIcon src={uploadIcon} />

                <Text>Drop files to upload</Text>
                <Text>OR</Text>
                <MainButton onClick={handleClick}>Browse</MainButton>
              </>
            ) : (
              <Images>
                {images.map((image, id) => (
                  <ImageWrapper key={id}>
                    <Image src={URL.createObjectURL(image)} />
                    <StyledDeleteIcon onClick={() => deleteImage(id)} />
                  </ImageWrapper>
                ))}
                <AddMoreButton onClick={handleClick}>Add More</AddMoreButton>
              </Images>
            )}

            <Input type="file" ref={hiddenFileInput} onChange={handleChange} />
          </ImagesContainer>

          {images.length > 0 && (
            <Buttons>
              <Button onClick={() => handleCancel()} outlined>
                Cancel
              </Button>
              <Button onClick={() => handleUpload()}>Upload</Button>
            </Buttons>
          )}
        </Box>
      </Modal>
    </div>
  );
}
