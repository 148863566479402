import React from 'react'
import One from '../../../assets/image/team/1.png'
import { makeStyles, Theme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) => ({
    root:{
        display:'flex',
        alignItems:'center',
        marginTop:'4rem'
    },
    TextDiv: {
       
        width: "529px",
        height: "210px",
        left: "100px",
        top: "311px",
        [theme.breakpoints.down('xs')]: {

        }
    },
    TextH1: {
        fontFamily: "Nexa",
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "48px",
        lineHeight:'3.5rem',
       
        },
    ImageDiv: {
        
        width: "668px",
        height: "379px",
        left: "650px",
        top: "180px"
    }
}))
const HeroSection = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
        
            <div className={classes.TextDiv}>
                <span className={classes.TextH1}>Come for the mission, Stay for the team</span>
                <br/>
                <p>With over 30 core team members in 7+ countries, we at Unifarm, link the world finest people in crypto with the world
                    top Defi projects.</p>
            </div>
            <div className={classes.ImageDiv}>
                <img src={One} />
            </div>
        </div>
    )
}
export default HeroSection