import { motion } from 'framer-motion'
import React from 'react'
import { CounterCoin } from '../../../Animation/Coin'
import { makeStyles,Theme} from '@material-ui/core/styles';
const useStyles = makeStyles((theme:Theme) => ({
    fontClass: {
        fontWeight: 900, fontFamily: 'Nexa !important'
    },
    root:{
        [theme.breakpoints.down('xs')]:{
            width:'160px'
        }
    }
}));
interface IsCard {
    url: any;
    width: any;
    title: any;
    total: any
}
const CounterCard = ({ url, width, title, total }: IsCard) => {
    const classes = useStyles()
    return (
        <div className={classes.root+" "+"flex flex-col justify-center items-center mt-7 mb-7"}>
            <motion.img variants={CounterCoin} initial="initial" animate="animate" src={url} width={width} className='mb-5'></motion.img>
            <b className={"text-lg sm:text-sm mt-1 mb-1" + ' ' + classes.fontClass} >{title}</b>
            <b className={"text-2xl sm:text-sm " + ' ' + classes.fontClass}>{total}</b>
        </div>
    )
}
export default CounterCard