import React, { useEffect } from "react";
import SectionTitle from "../Sectiontitle/index";
import Slider from "./SubComponent/Slider";
// import ProjectPartner from "../../assets/Banner/ProjectPartner_1.png"

import AOS from "aos";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MobileSliderProjectPanter from "./SubComponent/MobileSliderProjectPatner";

const ProjectPatner = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div style={{marginTop:"60px"}}>
      <SectionTitle title="Partner Projects" />


        {/* <div>

         {Mobile ? <MobileSliderProjectPanter />:<Slider/> }     

        </div> */}

      <div data-aos="fade-right">
        {Mobile ? <MobileSliderProjectPanter /> : <Slider />}
      </div>
    </div>
  );
};

export default ProjectPatner;
