import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { motion } from "framer-motion";
import React from "react";
import {
  // Coin,
  CoinWrapper,

  // CoinOpposite,
  // RotateEth,
} from "../../Animation/Coin";
import { Counter, FadeIn } from "../../Animation/Counter";
// import MobileCoin from '../../assets/image/SectionImage/coin.png'
// import Coins from '../../assets/image/SectionImage/White.png'

// Animation content--->
// import MainImage from "../../assets/image/AnimateImage/Artboard1Header.svg";
// import BNB from "../../assets/image/AnimateImage/BNB.svg";
// import ETH from "../../assets/image/AnimateImage/ETH.svg";
// import POLYGON from "../../assets/image/AnimateImage/POLYGON.svg";
// import AVE from "../../assets/image/AnimateImage/Ave.png";
// <--------- end

import Thunder from "../../assets/image/logo_wagmi.png";
import WebGIF from "../../assets/image/Web-Gif.gif";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  Eth: {
    position: "absolute",
    left: "8.5rem",
    width: "140px",
    top: "15rem",
    [theme.breakpoints.down("xs")]: {
      width: "87px",
      top: "7.5rem",
      left: "3.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      top: "7.5rem",
      left: "3.5rem",
    },
  },
  Ave: {
    position: "absolute",
    left: "18.5rem",
    width: "140px",
    top: "15rem",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      top: "7.5rem",
      left: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      top: "7.5rem",
      left: "10rem",
    },
  },
  BNB: {
    position: "absolute",
    left: "15.5rem",
    top: "2.5rem",
    width: "200px",
    [theme.breakpoints.down("xs")]: {
      width: "100px",
      top: "1rem",
      left: "9rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      top: "1rem",
      left: "9rem",
    },
  },
  POLY: {
    position: "absolute",
    top: "6.5rem",
    width: "170px",
    left: "4rem",
    [theme.breakpoints.down("xs")]: {
      width: "100px",
      top: "2rem",
      left: "1.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      top: "2rem",
      left: "1.5rem",
    },
  },
  MainWrap: {
    width: "500px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%",
    },
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "7rem",
    },
  },
  root2: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "-10rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "7rem",
    },
  },
  motionWrapper: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10rem",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-7rem",
      height: 290,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

const Herosection = () => {
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const LaunchApp = () => {
    window.open("https://app.unifarm.co/");
  };

  return (
    <div className={classes.root2}>
      <div
        className={
          classes.root +
          " " +
          "flex sm:flex-col-reverse items-center justify-between mt-3  mb-5"
        }
      >
        <motion.div
          className="flex flex-col"
          variants={Counter}
          initial="initial"
          animate="animate"
        >
          <motion.span
            variants={FadeIn("down")}
            className="text-white sm:text-center text-lg common-fontFamily sm:mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: Mobile ? "center" : "start",
            }}
          >
            {/* Decentralized farming pool of DEFI’s top projects */}
            Introducing&nbsp;<b>WAGMI Farms</b>
            <img src={Thunder} width={40} />
          </motion.span>
          {Mobile ? (
            <motion.span
              variants={FadeIn("down")}
              className="  sm:text-center mt-4 mb-4  font-heigh text-gradiant common-header-fontFamily"
              style={{ lineHeight: 1.1, fontSize: "2rem" }}
            >
              {/* One farm to rule <br /> them all. */}
              LET YOUR CRYPTO <br />
              WORK FOR YOU
            </motion.span>
          ) : (
            <motion.span
              variants={FadeIn("down")}
              className=" sm:text-4xl sm:text-center mt-4 mb-4  font-heigh text-gradiant common-header-fontFamily"
              style={{ lineHeight: 1.1, fontSize: "3rem" }}
            >
              {/* One farm to rule them <br />
              all. */}
              LET YOUR CRYPTO <br />
              WORK FOR YOU
            </motion.span>
          )}
          <motion.span
            variants={FadeIn("down")}
            className="text-white sm:text-center text-lg mb-5 common-fontFamily"
          >
            {/* Stake one token and farm multiple tokens | Upto 250% APY   */}
            Earn upto 400% return in the current market
          </motion.span>
          <motion.div variants={FadeIn("down")}>
            <div className="sm:flex sm:justify-center">
              <button className="Submit-btn" onClick={LaunchApp}>
                {/* Launch App */}
                Start Earning
              </button>
            </div>
          </motion.div>
        </motion.div>
        <motion.div variants={CoinWrapper} initial="initial" animate="animate">
          <img
            src={WebGIF}
            width={580}
            style={{
              borderRadius: 20,
              marginTop: Mobile ? "0rem" : "3rem",
              marginBottom: "2rem",
            }}
          />
        </motion.div>
        {/* <motion.div
          className={classes.motionWrapper}
          variants={CoinWrapper}
          initial="initial"
          animate="animate"
        >
          <motion.img variants={Coin} src={POLYGON} className={classes.POLY} />
          <motion.img variants={Coin} src={BNB} className={classes.BNB} />

          <motion.img variants={Coin} src={ETH} className={classes.Eth} />
          <motion.img variants={Coin} src={AVE} className={classes.Ave} />
          <img src={MainImage} className={classes.MainWrap} />
        </motion.div> */}

        {/* <motion.div variants={CoinWrapper} initial="initial" animate="animate" className="sm:h-96">
                {Mobile ? <motion.img variants={Coin} initial="initial" animate="animate" src={Coins} width="500" className='mt-5' ></motion.img> : <motion.img variants={Coin} initial="initial" animate="animate" src={Coins} width="500" ></motion.img>}
            </motion.div> */}
      </div>
    </div>
  );
};
export default Herosection;
