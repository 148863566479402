import React from "react";
import { useStyles } from "./LaunchpadStyle";
import PastCard from "./SubComponents/PastCard";
// import Past from '../../assets/UnifarmLandingPage/past.png'
// import Past2 from '../../assets/UnifarmLandingPage/past2.png'
// import Past3 from '../../assets/UnifarmLandingPage/past3.png'

import Label1 from "../../assets/UnifarmLandingPage/label1.png";
import Label2 from "../../assets/UnifarmLandingPage/label2.png";
// import Label3 from '../../assets/UnifarmLandingPage/label3.png'
// import { Button } from '@material-ui/core'
import BattleverseImage from "../../assets/image/BattleversePast.png";
import Gameyoo from "../../assets/image/gameyoo1.png";
import DcdEcoUp from "../../assets/image/DcdEcoUp.png";
import Dcd from "../../assets/image/dcd1.png";
import WizardTop from "../../assets/image/WizardTop.png";
import WizardLogo from "../../assets/image/WizardLogo.png";
import LegionBg from "../../assets/image/legionbg.png";
import LegionLogo from "../../assets/image/legionlogo.png";
import Sportzchain from "../../assets/image/sportzchain_image.png";
import SportzchainLogo from "../../assets/image/sportzchain_logo.png";

const PastIdos = () => {
  const classes = useStyles();
  const [DataIndex] = React.useState(6);
  const PastDataList = [
    {
      cardIamge: WizardTop,
      idoLabel: WizardLogo,
      idoName: "Wizardia IDO",
      idoContent: `Immerse yourself into the Play-to-earn magic of Wizardia.It is a Play-to-Earn online role-playing strategy game with unique NFTs at it’s core. Players get rewards from Wizardia by monetary investments.`,
      linkToPage: "/launch-WizardiIDO",
      tags: ["Private Sale"],
      bullets: [],
    },
    {
      cardIamge: BattleverseImage,
      idoLabel: Label1,
      idoName: "Battleverse IDO",
      idoContent: `BattleVerse is a multichain play-to-earn metaverse powered by DeFi &
            NFT and blockchain technology. It enables users to manage gaming
            assets by interacting with different blockchains.`,
      linkToPage: "/launch-BattleverseIDO",
      tags: ["Gaming"],
      bullets: ["92 Users Participated", "Funds Raised $10k", "5X at TGE"],
    },
    {
      cardIamge: Gameyoo,
      idoLabel: Label2,
      idoName: "Gameyoo IDO",
      idoContent: `A Bridge for Traditional and Blockchain Gaming.GameYoo seeks to rebalance the industry by bringing more traditional gamers into the GameFi fold.`,
      linkToPage: "/launch-GameyooIDO",
      tags: ["Gaming"],
      bullets: [],
    },
    {
      cardIamge: DcdEcoUp,
      idoLabel: Dcd,
      idoName: "DCD Ecosystem IDO",
      idoContent: `DCD Ecosystem is a GameFi Infrastructure Protocol, built to help gamers build games and integrate DeFi elements.Unique modules allow you to create a provably honest online game without the use of centralized servers. `,
      linkToPage: "/launch-DCDEcosystem",
      tags: ["Gaming"],
      bullets: [],
    },
    {
      cardIamge: LegionBg,
      idoLabel: LegionLogo,
      idoName: "Legion Network IDO",
      idoContent: `The Worlds' First Blockchain Ecosystem that combines the best services in the industry in one Super App | Crypto | NFTs | GameFi`,
      linkToPage: "/LegioNetwork",
      tags: ["Defi"],
      bullets: [],
    },
    {
      cardIamge: Sportzchain,
      idoLabel: SportzchainLogo,
      idoName: "Sportzchain IDO",
      // idoContent: `World’s first Engage-To-Earn (E2E) platform for sports fans powered by $SPN. We are building a digital world for fans offering Sports Tokens (Fan Token 2.0), Watch-To-Earn, Fan Commerce, Gamefi and more.`,
      idoContent: `World’s first Engage-To-Earn (E2E) platform for sports fans powered by $SPN. We are building a digital world for fans offering Sports Tokens (Fan Token 2.0), Watch-To-Earn, Fan Commerce, Gamefi and more.`,
      linkToPage: "/launch-SportzchainIDO",
      tags: ["Trending"],
      bullets: [],
    },
  ];
  // const LoadMore=()=>
  // {
  //     setIndex(DataIndex+3)
  // }
  return (
    <>
      <div className={classes.pastHeader}>Past IDOs</div>
      <div className={classes.PastCardResponsive}>
        {PastDataList.slice(0, DataIndex).map((data, index) => {
          return (
            <PastCard
              cardImage={data.cardIamge}
              idoImage={data.idoLabel}
              idoName={data.idoName}
              idoContent={data.idoContent}
              key={index}
              linkToPage={data.linkToPage}
              tags={data.tags}
              bullets={data.bullets}
            />
          );
        })}
      </div>
      {/* <div className={classes.ViewMoreDiv}>
            <Button onClick={LoadMore} className={classes.ViewMore}>Load More</Button>
            </div> */}
    </>
  );
};
export default PastIdos;
