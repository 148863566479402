import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@mui/material/Grid";
import Logo from "../../../../assets/Ido/Logo.png";
import Lock from "../../../../assets/Ido/Lock.png";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import Poly from "../../../../assets/Ido/Poly.png";
import Time from "../../../../assets/Ido/Time.png";
import Binance from "../../../../assets/image/binance.png";

const useStyles = makeStyles((theme) => ({
  Container: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  Root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  Title_Div: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "50px",
  },
  Title_text: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "26px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
  },
  Title_text2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
    marginTop: "30px",
  },
  Grid: {
    paddingLeft: "20px",
    paddingRight: "20px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0 px",
      marginBottom: "20px",
    },
  },
  Main_Card: {
    width: "100%",
    height: "290px",
    background: "#ffffff",
    border: "0.688559px solid #E0E0E0",
    borderRadius: "8.95127px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      marginTop: "20px",
    },
  },
  Card_title: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
    marginTop: "20px",
  },
  Sub_Main: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  Sub_card_div: {
    width: "160px",
    height: "140px",
    background: "#FFFFFF",
    border: "0.688559px solid #E0E0E0",
    boxSizing: "border-box",
    borderRadius: "8.95127px",
    marginLeft: "10px",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "5px",
    cursor: "pointer",
    "&:hover": {
      filter: "drop-shadow(4px 4px 25px #E0E0E0)",
    },
  },
  Sub_card_Img: {
    width: "30px",
    height: "30px",
  },
  Sub_card_Text: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
    marginTop: "10px",
  },
  bottom_sub_card: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  Matic_div: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    marginBottom: "35px",
  },
  Matic_title: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
  },
  LockIcon: {
    width: "15px",
    height: "15px",
    marginLeft: "10px",
    marginTop: "3px",
  },
  Live_icon: {
    background: "red",
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    marginRight: "5px",
  },
  live_text: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
    marginTop: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Matic_sub_div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "30px",
    flexDirection: "row",
  },
  Matic_text1: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#616161",
  },
  Matic_text2: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
  },
  frame_div: {
    marginTop: "100px",
  },
  Mobile_title_text: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "17px",
    fontFamily: "Inter",
    fontStyle: "normal",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
    marginTop: "15px",
  },
  Network_div: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
  },
  Poly_img_div: {
    width: "25px",
    height: "25px",
    border: "0.5px solid #E0E0E0",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  Poly_img: {
    width: "16px",
    height: "16px",
  },
  Time_img: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  Frame: {
    width: "100%",
    height: "970px",
    display:'block',
    [theme.breakpoints.down("xs")]: {
      height: "930px",
    },
  },
}));

const Participate = () => {
  const theme = useTheme();
  const classes = useStyles();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div>
      <Container maxWidth="lg" className={classes.Container}>
        <div className={classes.Root}>
          {Mobile ? (
            ""
          ) : (
            <div className={classes.Title_Div}>
              <span className={classes.Title_text}>
                Stake $UFARM, Get Whitelisted for the IDO
              </span>
              <span className={classes.Title_text2}>
                Users need to stake 25,000 $UFARM tokens to get whitelisted for
                the BattleVerse IDO. This Single-Staking Cohort is on Binance
                Network.
              </span>
            </div>
          )}
          <Grid container>
            <Grid item lg={6} xs={12} className={classes.Grid}>
              <div className={classes.Main_Card}>
                <span className={classes.Card_title}>Important Details</span>
                {Mobile ? (
                  <span className={classes.Mobile_title_text}>
                    Users need to stake Min 25000 UFARM tokens in UFARM - MATIC
                    Special Farm to get whitelisted for this IDO.
                  </span>
                ) : (
                  ""
                )}
                <div className={classes.Sub_Main}>
                  <div className={classes.Sub_card_div}>
                    <img src={Logo} className={classes.Sub_card_Img} />
                    <span className={classes.Sub_card_Text}>
                      UFARM <br /> Stake 25,000 $UFARM, and farm $BNB.
                    </span>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} className={classes.Grid}>
              <div className={classes.Main_Card}>
                <div className={classes.Matic_div}>
                  <div style={{ display: "flex" }}>
                    <span className={classes.Matic_title}>Cohort 36</span>
                    <img src={Lock} className={classes.LockIcon} />
                  </div>
                  <span className={classes.live_text}>
                    Go Live on February 16,2022 @ 1:30 pm UTC
                  </span>
                </div>
                <div className={classes.Matic_sub_div}>
                  <span className={classes.Matic_text1}>Live On</span>
                  <div className={classes.Network_div}>
                    <div className={classes.Poly_img_div}>
                      <img src={Binance} className={classes.Poly_img} />
                    </div>
                    BSC Network
                  </div>
                </div>
                <div className={classes.Matic_sub_div}>
                  <span className={classes.Matic_text1}>Minimum APY</span>
                  <span className={classes.Matic_text2}>20%</span>
                </div>
                <div className={classes.Matic_sub_div}>
                  <span className={classes.Matic_text1}>Ends In</span>
                  <div className={classes.Network_div}>
                    <img src={Time} className={classes.Time_img} />
                    90 Days
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div id="battleverse" className={classes.frame_div}>
            <iframe
              src="https://gleam.io/HZ3NN/battleverse-ido-at-unifarm-launch"
              className={classes.Frame}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Participate;
