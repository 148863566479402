import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
// import RoadMapSvg from '../../../assets/SvgComponent/Roadmap'
// import RoadMapSvg1 from '../../../assets/SvgComponent/RoadMap2'
import mp1 from'../../../assets/image/RoadMap/mobile1.png'
import mp2 from'../../../assets/image/RoadMap/mobile2.png'
import mp3 from'../../../assets/image/RoadMap/Mobile6.png'
import mp4 from'../../../assets/image/RoadMap/mobile4.png'
import mp5 from'../../../assets/image/RoadMap/mobile5.png'
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
    Image1: {
        // marginTop:'4.45rem',
       
    },
    Image2: {
        // marginTop:'0rem',
       
        
    }
}));
const MobileSlider=()=>
{
    const classes=useStyles()
    const [selectedCarouselIndex, setSelectedCarouselIndex] = useState(3);
    const carouselRef = useRef(null);
    useEffect(() => {
        if (carouselRef.current?.slickGoTo)
          carouselRef.current.slickGoTo(selectedCarouselIndex);
          console.log(setSelectedCarouselIndex(1))
      }, [selectedCarouselIndex]);
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:false,
        initialSlide: selectedCarouselIndex
      
    };
    return(
         <div >
                <Slider {...settings}  ref={carouselRef}>
                    <div className="flex flex-col justify-between" >
                   <img src={mp1}  className={classes.Image1}/>
                   </div>
                   <div className="flex flex-col justify-between">
                   <img src={mp2} className={classes.Image2}/>
                   </div>
                   <div className="flex flex-col justify-between">
                   <img src={mp3} className={classes.Image2}/>
                   </div>
                   <div className="flex flex-col justify-between">
                   <img src={mp4} className={classes.Image2}/>
                   </div>
                   <div className="flex flex-col justify-between">
                   <img src={mp5} className={classes.Image2}/>
                   </div>
                  
                    </Slider>
            </div>
    )
}
export default MobileSlider