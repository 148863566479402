import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export const useMobile = () => {
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return Mobile;
};
export const useIpad = () => {
    const theme = useTheme();
    const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
    return Ipad;
  };
