import { makeStyles, Theme } from '@material-ui/core/styles'
import AOS from 'aos'
import React, { useEffect } from 'react'
import Insta from '../../../assets/image/SocialMediaIcon/Instagram1.png'
import telegram from '../../../assets/image/SocialMediaIcon/Telegram1.png'
import twitter from '../../../assets/image/SocialMediaIcon/Twitter1.png'

import Youtube from '../../../assets/image/SocialMediaIcon/Youtube1.png'
import Facebook from '../../../assets/image/SocialMediaIcon/Facebook1.png'
import LinkedIN from '../../../assets/image/SocialMediaIcon/LinkedIN1.png'
import Discord1 from '../../../assets/image/SocialMediaIcon/Discord1.png'
import Reddit from '../../../assets/image/SocialMediaIcon/Reddit.png'
const useStyles = makeStyles((theme: Theme) => ({
    imageWidth: {
        height: 30, width:30,
        [theme.breakpoints.down('xs')]: {
            height: 25, width: 25,marginRight:10
        }
    },
    divWidth: {
        width: 135,
        [theme.breakpoints.down('xs')]: {
            width: 120,
        }
    },
    divWidth2: {
        width: 135,
        [theme.breakpoints.down('xs')]: {
            width: 126,
        }
    },
    social: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: '3.6rem !important'
        }
    },

    discord:{
        width:30,
        height:30,
        [theme.breakpoints.down('xs')]: {
            height: 25, width: 25,
        }
    }
}));
const Social = () => {
    const classes = useStyles()
    useEffect(() => {
        AOS.init({
            duretion: 2000
        })
    })
    return (
        <div data-aos="fade-left" className={classes.social + ' ' + 'ml-desktop  sm:mt-3  lg:ml-9'} >
            <b className='text-lg leading-footer sm:text-lg '>Social</b>
            <br/>
            <div className={'flex sm:mt-2 items-center justify-between mt-3'+' '+classes.divWidth2} style={{marginBottom:'1.5rem'}}>
                <a href="https://www.instagram.com/unifarm_official/" ><img src={Insta} className={classes.imageWidth} /></a>
                <a href="https://telegram.me/myunifarm" ><img src={telegram} className={classes.imageWidth} /></a>
                <a href="https://twitter.com/unifarm_" > <img src={twitter} className={classes.imageWidth} /></a>
                <a href="https://www.reddit.com/r/UniFarm_/" > <img src={Reddit} className={classes.imageWidth} /></a>
            </div>
            <div className={'flex sm:mt-2 items-center justify-between mt-1'+' '+classes.divWidth} >
                <a href="https://www.youtube.com/channel/UCqoPp0xDua3FKyVnF68EGcw" ><img src={Youtube} className={classes.imageWidth} /></a>
                <a href="https://www.facebook.com/UniFarmOfficial/ " ><img src={Facebook} className={classes.imageWidth} /></a>
                <a href="https://www.linkedin.com/company/unifarm-co/" > <img src={LinkedIN} className={classes.imageWidth} /></a>
                <a href="https://rebrand.ly/DiscordUFARM" > <img src={Discord1} className={classes.discord} /></a>
            </div>
        </div>
    )
}
export default Social