import React from 'react'
import SectionTeam from '../TeamSectionTitle/index'
import ProfileCard from './ProfileCard'
import { makeStyles, Theme } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
const useStyles = makeStyles((theme: Theme) => ({
    Cardroot: {
        display: 'flex',
        flexFlow: 'wrap',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('xs')]: {

        }
    },
    ButtonDiv:{
        display: 'flex', justifyContent: 'center',marginTop:'2rem'
    },
    ButtonRoot: {
        width: "212px",
        height: "45px",
        color: 'white',
        textTransform: 'capitalize',
        background: "linear-gradient(96.19deg, #CB218E -2.9%, #663399 101.91%)",
        boxShadow: "0px 6px 24px rgba(203, 33, 142, 0.3)",
        borderRadius: "40px"
    }
}))
const Profile = () => {
    const classes = useStyles()
    return (
        <>
            <SectionTeam
                title='Our Team'
                description='The best from the best'
            />
            <div>
                <div className={classes.Cardroot}>
                    <ProfileCard
                        EmpName='Gopal'
                        EmpDesignation='Ux/ Ui Designer'
                    />
                    <ProfileCard
                        EmpName='Akash'
                        EmpDesignation='Sr. React Developer'
                    />
                    <ProfileCard
                        EmpName='Manoj'
                        EmpDesignation='Sr. Ux/ Ui Designer'
                    />
                    <ProfileCard
                        EmpName='Shreya'
                        EmpDesignation='Content Head'
                    />
                    <ProfileCard
                        EmpName='Name'
                        EmpDesignation='Designation'
                    />
                    <ProfileCard
                        EmpName='Gopal'
                        EmpDesignation='Ux/ Ui Designer'
                    />
                    <ProfileCard
                        EmpName='Akash'
                        EmpDesignation='Sr. React Developer'
                    />
                    <ProfileCard
                        EmpName='Manoj'
                        EmpDesignation='Sr. Ux/ Ui Designer'
                    />
                    <ProfileCard
                        EmpName='Shreya'
                        EmpDesignation='Content Head'
                    />
                    <ProfileCard
                        EmpName='Name'
                        EmpDesignation='Designation'
                    />

                </div>
                <div className={classes.ButtonDiv}><Button className={classes.ButtonRoot}>View More</Button></div>
            </div>
        </>
    )
}
export default Profile