import React from "react";

interface IsBannerItem {
    url: any;
    
  }

 const BannerItem = ({url}: IsBannerItem)=>{
    return(
        <img src={url}
         className="mb-5  w-full h-fit sm:mobile-projectPartnetBanner-width  sm:h-mobile-image-height"
        //  style={{height:'fit-content',width:'100%'}}  
         />

    )
 } 

 export default BannerItem;