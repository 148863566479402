import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  ContainerClass: {
    overflow: "hidden",
  },
}));
interface IsContainer {
  children: React.ReactNode;
}
const Container1 = (props: IsContainer) => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.ContainerClass}>
      {props.children}
    </Container>
  );
};
export default Container1;
