import AOS from 'aos'
import React, { useEffect } from 'react'
import Contain from './SubComponent/Contain'
import Wrapper from './SubComponent/Wrapper'
interface IsAbout{
    RoadMapClick:()=>void
    WhyUnifarmClick:()=>void
    isPage:boolean
}
const About = ({RoadMapClick,WhyUnifarmClick,isPage}:IsAbout) => {
    useEffect(()=>{
        AOS.init({
            duretion:1000,
        
        })
    })
    return (
        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" className="sm:ml-6 sm:mt-2">
            <Wrapper>
            <b className='text-lg leading-footer sm:text-lg'>About</b>
            {
                isPage?null:<div onClick={WhyUnifarmClick} className='text-base leading-9 text-white sm:text-sm sm:leading-8' style={{whiteSpace:"nowrap"}}>Why UniFarm?</div>
            }
            
            <Contain url="https://docs.unifarm.co/" title="Whitepaper" />
            {
                isPage?null:<div onClick={RoadMapClick} className='text-base leading-9 text-white sm:text-sm sm:leading-8'>Roadmap</div>
            }
            
            <Contain url="https://blog.unifarm.co/" title="Blog" />
            <Contain url="https://airtable.com/shr68zlaX4wFMOjGe" title="Influencer Collaboration" />
            <Contain url="https://uni.farm/buildinpublic" title="Build in Public" />
            <Contain url="/gallery" title="Gallery" />
        </Wrapper>
        </div>
    )
}
export default About