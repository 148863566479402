import React, { useEffect } from "react";
import AOS from "aos";
import { makeStyles } from "@material-ui/core/styles";
import SectionTitle from "../Sectiontitle";
// import RadioFlex from "./SubComponent/RadioFlex";
import ProjectSuscribe from "./SubComponent/ProjectSuscribe";
import UserSubscribe from "./SubComponent/UserSubscribe";
import User from "../../assets/image/IconeImage/user.png";
import Project from "../../assets/image/IconeImage/project.png";
import "antd/dist/antd.css";
// import { Radio } from "antd";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { makeStyles, Theme } from '@material-ui/core/styles';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import InfoIcon from '@material-ui/icons/Info';
// import validator from 'validator';
// const useStyles = makeStyles((theme:Theme) => ({
//     SubscribeClass:{
//         marginTop:'-6.5rem',marginBottom:'4rem',textAlign:'left',width:'33%',color:'red',
//         display:'flex',alignItems:'center',
//         [theme.breakpoints.down('xs')]:{
//             width:'77%'
//         }
//     },
//     SubscribeClass2:{
//         marginTop:'-6.5rem',marginBottom:'4rem',textAlign:'left',width:'33%',color:'green',
//         display:'flex',alignItems:'center',
//         [theme.breakpoints.down('xs')]:{
//             width:'77%'
//         }
//     }
//   }));

const useStyles = makeStyles((theme) => ({
  Root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: "18px",
    fontWeight: 500,
    color: "#332233",
    marginLeft: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  Group: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    [theme.breakpoints.down("xs")]: {
      gap: "5px",
    },
  },

  IFrame: {
    width: "100%",
    height: "150px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      height: "200px",
    },
  },
}));

const Suscribe = () => {
  const classes = useStyles();

  const [value, setValue] = React.useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {

    
    AOS.init();
  }, []);

  //    const [inputvalue,setInputValue]=React.useState()
  //    const [Error,setError]=React.useState(0)
  //    const [SubmitBtn,setBtn]=React.useState(false)
  //    const classes=useStyles()
  // const onChangeInput=(e)=>
  // {
  //     setInputValue(e.target.value)
  //     setBtn(true)
  //     if(validator.isEmail(e.target.value))
  //     {
  //         setError(0)
  //     }
  //     else
  //     {
  //         setError(1)
  //     }
  // }
  // const onSubmit=()=>
  // {
  //     setError(2)
  // }


  return (
    <>
      <SectionTitle title="Subscribe" />
      <div className={classes.Root}>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={onChange}
          className={classes.Group}
        >
          <div data-aos="fade-right">
            <FormControlLabel
              value={1}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 35,
                      color: "#663399",
                    },
                  }}
                />
              }
              style={{ margin: "0px" }}
              label={
                <>
                  <div className="flex items-center">
                    <span className={classes.title}>I am a User</span>
                    &nbsp;&nbsp;
                    <img src={User} width={15} />
                  </div>
                </>
              }
            />
          </div>
          <div data-aos="fade-left">
            <FormControlLabel
              value={2}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 35,
                      color: "#663399",
                    },
                  }}
                />
              }
              style={{ margin: "0px" }}
              label={
                <>
                  <div className="flex items-center">
                    <span className={classes.title}>I am a Project</span>
                    &nbsp;&nbsp;
                    <img src={Project} width={24} />
                  </div>
                </>
              }
            />
          </div>
        </RadioGroup>

               
        

        {
          
          value === 1 ? <UserSubscribe /> : <ProjectSuscribe />
        }



          


        {/* <UserSubscribe /> 
        <ProjectSuscribe /> */}

        {/* <Radio.Group
          onChange={onChange}
          value={value}
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div data-aos="fade-right">
            <Radio value={1}>
              <div className="flex items-center">
                <span className={classes.title}>I am a User</span>
                &nbsp;&nbsp;
                <img src={User} width={15} />
              </div>
            </Radio>
          </div>
          <div data-aos="fade-left">
            <Radio value={2}>
              <div className="flex items-center">
                <span className={classes.title}>I am a Project</span>
                &nbsp;&nbsp;
                <img src={Project} width={24} />
              </div>
            </Radio>
          </div>
        </Radio.Group> */}


          
{/* {value === 1 ? <iframe
        src="https://unifarm.ck.page/16dddcd3e0"
        className={classes.IFrame}
      /> : <iframe
      src="https://unifarm.ck.page/475a205fd6"
      className={classes.IFrame}
    />
    } */}


       
      </div>

      {/* <Input
        placeholder="Enter your email"
        className="mail-input"
        value={inputvalue}
        onChange={onChangeInput}
      />
      {SubmitBtn ? (
        <Button
          type="primary"
          shape="round"
          className="button-background text-themeColor flex items-center  submit-button2"
          onClick={onSubmit}
        >
          Subscribe
        </Button>
      ) : (
        <Button
          type="primary"
          shape="round"
          className="button-background text-themeColor flex items-center  submit-button"
          disabled
          style={{ color: "white" }}
        >
          Subscribe
        </Button>
      )}
      {Error === 1 ? (
        <span className={classes.SubscribeClass}>
          <InfoIcon />
          Enter Valid Email
        </span>
      ) : Error === 2 ? (
        <span className={classes.SubscribeClass2}>
          <CheckCircleIcon />
          Your submission has been received
        </span>
      ) : null} */}
    </>
  );
};
export default Suscribe;
