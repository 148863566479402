import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Twitter from "../../../assets/Ido/Twitter.png";
// import Browser from "../../../assets/Ido/Browser.png";
import Discord from "../../../assets/Ido/Discord.png";
import Link from "../../../assets/Ido/Link.png";
import Medium from "../../../assets/Ido/Medium.png";
import Telegram from "../../../assets/Ido/Telegram.png";
import Global from "../../../assets/Ido/Global_Sportchain.png";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "90px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "80px",
    },
    [theme.breakpoints.down(376)]: {
      marginTop: "30%",
    },
  },
  Main_div: {
    width: "690px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  Title: {
    fontSize: "34px",
    fontWeight: 900,
    lineHeight: "44px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30 px",
    },
  },
  Text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#000000",
    marginTop: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  Text2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  Button_div: {
    display: "flex",
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  Learn_button: {
    width: "300px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    borderRadius: "30px",
    border: "1px solid",
    borderColor: "#CB218E",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    marginRight: "15px",
    color: "#332233",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
      marginRight: "0px",
    },
  },
  Check_button: {
    width: "300px",
    height: "45px",
    background: "linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%)",
    boxShadow: "0px 6px 24px rgba(203, 33, 142, 0.3)",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    border: "1px solid",
    borderColor: "#CB218E",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "white",
    marginLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
      marginLeft: "0px",
      marginTop: "30px",
    },
  },
  Icons: {
    width: "25px",
    height: "25px",
    marginRight: "10px",
    marginLeft: "10px",
  },
  Address:{
    fontWeight:"bold",
    fontSize:"14px",
    marginTop:"5px",
    [theme.breakpoints.down("xs")]: {
    fontSize: "12px",
    },
  }
}));

const About = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <div className={classes.Root}>
        <div className={classes.Main_div}>
          <span className={classes.Title}>About The Project</span>

          <div>
            <div style={{padding:"0 10px"}}>
              <p className={classes.Text}>
                World’s first Engage-To-Earn (E2E) platform for sports fans
                powered by $SPN. We are building a digital world for fans
                offering Sports Tokens (Fan Token 2.0), Watch-To-Earn, Fan
                Commerce, Gamefi and more.
              </p>
            </div>
            <div className={classes.Text}>
              <p>Want to know more about Sportzchain: <a target="_blank" rel="noreferrer" href="https://youtu.be/wmNe_HaedJE" className="cursor-pointer">https://youtu.be/wmNe_HaedJE</a></p>
              
            </div>

            <div>
              <p className={classes.Address}>
                Contract address: 0x32ea3dc70e2962334864a9665254d2433e4ddbfd
              </p>
            </div>
          </div>

          <span className={classes.Text}>
          {/* World’s first Engage-To-Earn (E2E) platform for sports fans
                powered by $SPN. We are building a digital world for fans
                offering Sports Tokens (Fan Token 2.0), Watch-To-Earn, Fan
                Commerce, Gamefi and more. */}
            {/* BattleVerse is a multichain play-to-earn metaverse powered by DeFi x
            NFT and blockchain technology. It enables users to manage gaming
            assets by interacting with different blockchains. Users can make
            characters that fight with each other, farm resources, and
            participate in tournaments of different levels with PVP and PVE
            modes. Battleverse is a thrilling multichain play-to-earn metaverse
            game powered by blockchain technology, in which players can collect
            heroes, engage in combat, own land, and farm resources. The
            Battleverse universe is made up of several inner and outer worlds.
            The inner realms are home to six diverse races, each with its own
            set of goals and one thing in common: battle. Players can also
            purchase and sell assets on the in-game marketplace, along with
            purchasing tickets to participate in tournaments. */}
          </span>
          {/* <span style={{marginTop:"10px",fontWeight:"bold"}}>Contract address: 0x32ea3dc70e2962334864a9665254d2433e4ddbfd</span> */}

          <div className={classes.Button_div}>
            {/* <a target="_blank" rel="noreferrer" href="https://sportzchain.com/"> */}
            <a target="_blank" rel="noreferrer" href=" https://uni.farm/sportzchain-memo">
              <button className={classes.Learn_button}>
              Learn More About Sportzchain  
              </button>
            </a>
            {/* <a
              href="https://app.unifarm.co/launch/56"
              target="_blank"
              rel="noreferrer"
            >
              <button className={classes.Check_button}>
                Check your eligibility
              </button>
            </a> */}
          </div>
          <div style={{ display: "flex", marginTop: "50px" }}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/sportzchain"
            >
              <img src={Twitter} alt="img" className={classes.Icons} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://t.me/SportzchainCommunity"
            >
              <img src={Telegram} alt="img" className={classes.Icons} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.gg/E4X3Gg9nbK"
            >
              <img src={Discord} alt="img" className={classes.Icons} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://medium.com/@SportZchain"
            >
              <img src={Medium} alt="img" className={classes.Icons} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://sportzchain.com/"
            >
              <img src={Global} alt="img" className={classes.Icons} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://linktr.ee/sportzchain"
            >
              <img src={Link} alt="img" className={classes.Icons} />
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default About;
