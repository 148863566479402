import React from "react";

interface Isimage {
  Url: any;
  width: number;
  margin: number;
}
const MyImage = ({ Url, width, margin }: Isimage) => {
  return (
    <img
      src={Url}
      width={width}
      className={"mb-" + margin + ""}
      style={{  }}
    />
  );
};
export default MyImage;
