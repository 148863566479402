import { Button } from "@mui/material";
import styled from "styled-components";
export const ProjectItemsWrap = styled.div`
  height: 196px;
  width: 413.90887451171875px;
  left: 0px;
  top: 0px;
  border-radius: 15px;
  padding: 30px 20px 30px 30px;
  background: #ffffff;
  border: 1px solid #e0d6eb;

  box-shadow: 14px 14px 40px #f7f7f7;
  border-radius: 15px;
  display: flex;
  align-items: center;
  :hover {
    box-shadow: 14px 14px 40px #f7f7f7;
    cursor: pointer;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const MainDiv = styled.div`
  display: flex;
  align-items: start;
  column-gap: 15px;
`;
export const ProjectContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 13px;
`;
export const ProjectTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height, or 130% */

  color: #332233;
`;

export const ProjetContent = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  color: #332233;
`;
export const ProjectImage = styled.img`
  height: 32px;
  width: 56px;
`;
export const ProjectDiv = styled.div`
  height: 424px;
  width: 875.3948974609375px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  margin: 0px auto;
  @media (max-width: 430px) {
    height: auto;
    width: 100%;
    row-gap:20px;
  }
`;
export const ProjectDiv1 = styled.div`
  height: 196px;
  width: 875.3948974609375px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 430px) {
    flex-direction: column;
    width:100%;
    row-gap:20px;
    height: auto;
  }
`;
export const ContactButton=styled(Button)`
width: 165px !important;
height: 55px !important;

background: linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%) !important;
box-shadow: 0px 6px 24px rgba(203, 33, 142, 0.3) !important;
border-radius: 40px !important;
font-family: 'Inter' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 16px !important;
line-height: 19px !important; 

color: #FFFFFF !important;
text-transform: capitalize !important;
margin:0px auto !important;
`