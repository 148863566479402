import styled from 'styled-components'
export const SectionTitleWrap= styled.div`
text-align: center;
width: 100%;
margin-top:4rem;
margin-bottom:4rem;
@media (max-width: 430px) {
    margin-top:3rem;
}
`
export const SectionTitle=styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 36px;
margin-bottom:0.5rem;
color: #332233;
@media (max-width: 430px) {
    font-weight: bold;
    font-size: 25px;
}
`
export const SectionContent=styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
text-align: center;

color: #332233;
@media (max-width: 430px) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #332233;
}
`

export const UserItemWrap=styled.div`
display: flex;
align-items:center;
column-gap:15px;
@media (max-width: 430px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    row-gap: 14px;
}
`
export const ItemImage=styled.img`
height: 100.80000305175781px;
width: 100.80000305175781px;
`
export const UserContentWrap=styled.div`
display: flex;
flex-direction: column;
row-gap: 10px;
@media (max-width: 430px) {
    row-gap: 12px;
}
`
export const ItemTitle=styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 36px;

color: #332233;
@media (max-width: 430px) {
    text-align:center;
    font-size: 24px;
}
`
export const ItemContent=styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;

color: #332233;
@media (max-width: 430px) {
    text-align:center;
}

`