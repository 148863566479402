import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@mui/material/Grid";
// import Logo from "../../../../assets/Ido/Logo.png";
// import Lock from "../../../../assets/Ido/Lock.png";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import Poly from "../../../../assets/Ido/Poly.png";
// import Time from "../../../../assets/Ido/Time.png";
// import Binance from "../../../../assets/image/polygon.png";
// import Polygon from "../../../../assets/image/Polygon.png";
// import GameIDO from "../../../../assets/image/GameIDO.png"
// import Ellipse from "../../../../assets/image/LiveEllipse.png";

const useStyles = makeStyles((theme) => ({
  Container: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  Root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  Title_Div: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "50px",
  },
  Title_text: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "26px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
  },
  Title_text2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
    marginTop: "30px",
  },
  Grid: {
    paddingLeft: "20px",
    paddingRight: "20px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0 px",
      marginBottom: "20px",
    },
  },
  Main_Card: {
    width: "100%",
    // height: "290px",
    // margin:"0 auto",
    background: "#ffffff",
    border: "0.688559px solid #E0E0E0",
    borderRadius: "8.95127px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      marginTop: "20px",
      padding: "20px 10px",
    },
  },
  Important_Details: {
    // marginTop: "30px",
    textAlign: "left",
    width: "70%",
    marginTop: "15px",
    display: "flex",
    justifyContent: "center",
    // marginBottom:"15px",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
      fontSize: "14px",
    },
  },

  Card_title: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  Sub_Main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "20px",
  },
  Sub_card_div: {
    width: "160px",
    height: "140px",
    background: "#FFFFFF",
    border: "0.688559px solid #E0E0E0",
    boxSizing: "border-box",
    borderRadius: "8.95127px",
    marginLeft: "10px",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "5px",
    cursor: "pointer",
    "&:hover": {
      filter: "drop-shadow(4px 4px 25px #E0E0E0)",
    },
  },
  Sub_card_Img: {
    width: "30px",
    height: "30px",
  },
  Sub_card_Text: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
    marginTop: "10px",
  },
  bottom_sub_card: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  Learn_button: {
    width: "200px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    borderRadius: "30px",
    border: "1px solid",
    borderColor: "#CB218E",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "15px",
    fontFamily: "Inter",
    fontStyle: "normal",
    marginRight: "15px",
    marginLeft: "15px",
    color: "#332233",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0px",
      height: "40px",
      padding: "6px",
      fontSize: "14px",
    },
  },

  Matic_div: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    marginBottom: "35px",
  },
  Matic_title: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
  },
  LockIcon: {
    width: "15px",
    height: "15px",
    marginLeft: "10px",
    marginTop: "3px",
  },
  Live_icon: {
    background: "red",
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    marginRight: "5px",
  },
  live_text: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
    marginTop: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Matic_sub_div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginBottom: "30px",
    flexDirection: "row",
    width: "100%",
  },
  Matic_text1: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#616161",
  },
  Matic_text2: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
  },
  frame_div: {
    marginTop: "100px",
  },
  Mobile_title_text: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "17px",
    fontFamily: "Inter",
    fontStyle: "normal",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
    marginTop: "15px",
  },
  Network_div: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
  },
  Poly_img_div: {
    width: "25px",
    height: "25px",
    border: "0.5px solid #E0E0E0",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  Poly_img: {
    width: "16px",
    height: "16px",
  },
  Time_img: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },

  YF_Button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: "10px",

    [theme.breakpoints.down("xs")]: {
      display: "flex",
      // flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "0 5px",
    },
  },

  Frame: {
    width: "100%",
    height: "970px",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      height: "930px",
    },
  },
}));

const ImportantDetails = [
  "Winner NFT airdrop will be done till 09 Sep @7 am UTC",
  "Token Sale page for stakers will go live on 09 Sep @7 am UTC",
  "Token Sale page for community will go live on 10 Sep @7 am UTC",
  "Token Sale page for stakers and community will close on 12 Sep @ 7 pm UTC",
  "Token Sale page for FCFS will go live on 12 Sep @7 pm UTC",
  "Token Sale page for FCFS will close on 13 Sep @5 am UTC",
  
];

const Participate = () => {
  const theme = useTheme();
  const classes = useStyles();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      <Container maxWidth="lg" className={classes.Container}>
        <div className={classes.Root}>
          {Mobile ? (
            ""
          ) : (
            <div className={classes.Title_Div}>
              <span className={classes.Title_text}>
                {/* Stake $UFARM, Get Whitelisted for the IDO */}
                Stake In V2 Farms, Get Whitelisted for Sportzchain IDO
              </span>
              <span className={classes.Title_text2}>
                {/* Users need to stake 25,000 $UFARM tokens to get whitelisted for
                the BattleVerse IDO. This Single-Staking Cohort is on Binance
                Network. */}
                Users need to stake in any of the v2 farms to be eligible for
                the IDO
              </span>
            </div>
          )}
          {/* <Grid container> */}
          <Grid item lg={6} xs={12} className={classes.Grid}>
            <div className={classes.Main_Card}>
              <span className={classes.Card_title}>Important Details</span>
              {Mobile ? "" : ""}
              <div className={classes.Important_Details}>
                <div>
                  {ImportantDetails.map((details, ind) => {
                    return (
                      <li key={ind} className="mb-4 ">
                        {details}
                      </li>
                    );
                  })}
                </div>
              </div>
              <div className={classes.YF_Button}>
                  <div>

                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.unifarm.co/v2/farms?tab=allfarms"
                >
                  <button className={classes.Learn_button}>
                    Stake in YF V2
                  </button>
                </a>
                  </div>

                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    margin: "0 5px",
                  }}
                >
                  Or
                </span>

                <div>
                <a target="_blank"
                  rel="noreferrer"
                  href="https://app.unifarm.co/liquidity-farming?tab=allfarms">

                <button className={classes.Learn_button}>Stake in LFV2</button>
                </a>

                </div>
              </div>

            </div>
          </Grid>


              {/* <div className={classes.Sub_Main}>
                  <div className={classes.Sub_card_div}>
                    <img src={Logo} className={classes.Sub_card_Img} />
                    <span className={classes.Sub_card_Text}>
                      UFARM <br /> Stake 25,000 $UFARM, and farm $BNB.
                    </span>
                  </div>
                </div> */}


          {/* <Grid item lg={6} xs={12} className={classes.Grid}>
              <div className={classes.Main_Card}>
                <div className={classes.Matic_div}>
                  <div className="flex justify-center items-center ">
                    <div className="rounded-full mr-2 w-2 h-2 overflow-hidden rounded-full">
                      <img
                        src={Ellipse}
                        alt="LiveEllipse"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <div>
                      <span className={classes.Matic_title}>Live</span>
                    </div>
                  </div>
                  
                </div>
                <div className={classes.Matic_sub_div}>
                  <span className={classes.Matic_text1}>Live On</span>
                  <div>
                    <p className={classes.Matic_text1}>Live On</p>
                  </div>
                  <div className={classes.Network_div}>
                    <div className={classes.Poly_img_div}>
                      <img src={Polygon} className={classes.Poly_img} />
                    </div>
                    Polygon Network
                  </div>
                </div>
                <div className={classes.Matic_sub_div}>
                  <span className={classes.Matic_text1}>Minimum APY</span>
                  <span className={classes.Matic_text2}>20%</span>
                </div>
                <div className={classes.Matic_sub_div}>
                  <span className={classes.Matic_text1}>Ends In</span>
                  <div className={classes.Network_div}>
                    <img src={Time} className={classes.Time_img} />
                    90 Days
                  </div>
                </div>
                <div className={classes.YF_Button}>
                  <button className={classes.Learn_button}>
                    Stake in YF V2
                  </button>
                  <span style={{ fontWeight: "bold", fontSize: "16px" , margin:"0 5px" }}>
                    Or
                  </span>
                  <button className={classes.Learn_button}>
                    Stake in LFV2
                  </button>
                </div>
              </div>
            </Grid> */}
          {/* </Grid> */}
          <div id="battleverse" className={classes.frame_div}>
            {/* <img src={GameIDO} />     */}

            <iframe
              // src="https://gleam.io/HZ3NN/battleverse-ido-at-unifarm-launch"
              src="https://giv.gg/gTPjhk"
              className={classes.Frame}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Participate;
