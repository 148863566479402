
import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
    root:{
        width:'100%',
        [theme.breakpoints.between('sm','md')]:{
            width:'100%'
        }
    }
}))
interface IsWrapper{
children:React.ReactNode
}
const Wrapper =(props:IsWrapper)=>
{
    const classes=useStyles()
    return(
        <div className={classes.root+' '+'flex flex-col ml-desktop sm:ml-mobile md:ml-5 lg:ml-8'} >
            {props.children}
        </div>
    )
}
export default Wrapper