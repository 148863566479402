import React from "react";
import Card from "@mui/material/Card";
import M2_img from "../../../assets/Element/M2_img.png";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import Report from "../SubComponent/Report/index";
interface isNotFound {
  Click: () => void;
  Name: string;
}
const useStyles = makeStyles((theme) => ({
  CardRoot: {
    width: "400px",
    height: "100%",
    borderRadius: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  spanText: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  ReportButton: {
    background: "#C62828",
    boxShadow: "0px 7px 18px -2px rgba(198, 40, 40, 0.51)",
    borderRadius: "10px",
    width: "380px",
    height: "50px",
    textTransform: "none",
    fontWeight: 500,
    fontSize: "15px",
    "&:hover": {
      background: "#C62828",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      background: "#C62828",
      boxShadow: "0px 7px 18px -2px rgba(198, 40, 40, 0.51)",
      borderRadius: "10px",
    },
  },
  ReportImage: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
  ReportHeading: {
    textAlign: "left",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  Reporttext: {
    textAlign: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  ButtonDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  SpanDiv: {
    fontSize: "16px",
    fontWeight: 500,
    marginTop: "10px",
  },
  HeaderDiv: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      wordWrap: "break-word",
      width: "100%",
      textAlign: "CENTER",
    },
  },
  ContentDiv: {
    paddingLeft: "20px",
    textAlign: "center",
    marginTop: "10px",
  },
}));
const NotFound = ({ Click, Name }: isNotFound) => {
  const classes = useStyles();
  const [EmailVarifation, setEmailVarification] = React.useState(false);
  return (
    <>
      {EmailVarifation ? (
        <Report CloseClick={Click} ReportName={Name} />
      ) : (
        <Card className={classes.CardRoot}>
          <div className={classes.ReportImage}>
            <div>
              <img src={M2_img} alt="img" />
            </div>
            <div className={classes.ContentDiv}>
              The&nbsp;<b>{Name}</b> you inquired is not an official team member
              of UniFarm.
            </div>
            <div className={classes.ContentDiv}>
              Note: To protect your assets, do not interact with accounts from
              unofficial UniFarm channels or who pretend to be UniFarm team
              members.
            </div>
            {/* <div className={classes.HeaderDiv}>
              <span className={classes.ReportHeading}>
                The&nbsp;<b>{Name}</b> you inquired is not an official team
                member of UniFarm.
              </span>
            </div>
            <div className={classes.ContentDiv}>
              <span className={classes.Reporttext}>
               
              </span>
            </div> */}
            <div className={classes.ButtonDiv}>
              <Button
                onClick={() => setEmailVarification(true)}
                variant="contained"
                className={classes.ReportButton}
                // hidden={true}
              >
                Report
              </Button>
            </div>
            <div className={classes.SpanDiv}>
              <span onClick={Click} className={classes.spanText}>
                Okay
              </span>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default NotFound;
