import React from "react";
const Thunderfarms = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
      }}
    >
      <iframe
        src="https://ufarm.manojsreeram.in/#"
        title="Thunder farms"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};
export default Thunderfarms;
