import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "60px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
    },
  },
}));

export const Instruction = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div className={classes.Root}>
        <div className="flex flex-col justify-start align-start">
        <p style={{fontWeight: "bold",margin:"5px 0"}}>
        Note:    1. **All the investments above $200 will be eligible for the
          Discount**
        </p>
        <p style={{margin:"5px 0"}}>
           2. Users can also buy Price Protection Plan via <span style={{fontWeight: "bold"}}>**10000 UFARM**</span>  tokens, Enjoy 100% protection on token price

        </p>
        <p style={{margin:"5px 0"}}>
          3.  Users with Price Protection will have two options at every unlock, they can either claim the $SPN tokens or the stablecoins invested. 
        </p>

        <p style={{margin:"5px 0"}}>4.Price Protection Plan will be available for a limited duration only</p>
        </div>
      </div>
    </Container>
  );
};
