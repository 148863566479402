import { Variants } from "framer-motion";
export const CoinWrapper:Variants={
    initial:{
        x:1000,
       overflow:'hidden',
       
    },
    animate:{
        x:0,
        transition:{
             delay:1.1,
             duration:1.1,
             type:'spring'
        }
    }
}

export const Coin:Variants={
    initial:{},
    animate:{
        y:[30,0,30],
        transition:{
             duration:3.5,
             ease:'linear',
             repeat:Infinity
        }
    }
}

export const CoinOpposite:Variants={
    initial:{},
    animate:{
        rotateY:360,
        transition:{
             duration:5.0, 
             ease:'linear',
             repeat:Infinity
        }
    }
}
export const RotateEth:Variants={
    initial:{},
    animate:{
        scale: 1,
        rotate:360,
        transition:{
            repeat:Infinity,
            ease:'linear',
            duration:5.5
        }
        
        }
    }

export const CounterCoin:Variants={
    initial:{},
    animate:{
        y:[10,0,10],
        transition:{
             duration:3.0,
             ease:'linear',
             repeat:Infinity
        }
    }
}