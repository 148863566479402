import React from "react";
import Header from "../Header";
import Accordon from "./Accordion";
import Footer from "../Footer/index";
import Container from "../Container/index";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

// import Helmet from "react-helmet";
// import { Helmet, HelmetProvider } from "react-helmet-async";
// import Container from "@material-ui/core/Container";
// import Footer from "../Footer";
// import HelloBar from '../HelloBar';
// import Faq from 'react-faq-component';
// import Logo from "../../assets/image/Logo/logo2.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Title: {
      fontFamily: "Nexa",
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: "48px",
      textAlign: "center",
      marginTop: "3.5rem",
      marginBottom: "3rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px !important",
        marginBottom: "3rem",
      },
    },
    Contain: {
      textAlign: "center",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      marginTop: "-2.5rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px !important",
      },
    },
  })
);
interface IsFaq {
  title: string;
  desc: string;
}
const FAQ = ({ title, desc }: IsFaq) => {
  const classes = useStyles();
  React.useEffect(() => {
    document.title = `UniFarm | ${title}`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", desc);
    document
      .querySelector('meta[name="og:title"]')
      .setAttribute("content", title);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", desc);
  }, [title]);

  return (
    <>
      {/* <HelmetProvider> */}
      {/* <Helmet>
        <title>{title}</title>
        <meta name="description" content="Nested component" />
        <meta name="og:title" content={title} />
        <meta content={desc} property="og:description" />
      </Helmet> */}
      <Container>
        <Header ClickHeader={() => console.log("click FAQ")} />
        <div className="Faq-Element"></div>
        <Accordon />
        <p className={classes.Title}>Still have a questions?</p>
        <p className={classes.Contain}>
          Write us at
          <b>
            <a href="mailto:contact@unifarm.co" className="text-EmailColor ">
              &nbsp;contact@unifarm.co
            </a>
          </b>
          <br />
          and we will get back to you shortly.
        </p>
        <div className="mt-28">
          <Footer
            Investor={() => console.log("click")}
            IpadClick={() => console.log("click")}
            RoadMapClick2={() => console.log("click")}
            RoadMapClickIpad={() => console.log("click")}
            WhyUnifarmClick2={() => console.log("click")}
            WhyUnifarmClickIpad={() => console.log("click")}
            isInnerPage={true}
          />
        </div>
      </Container>
      {/* <Container2>
        <Header ClickHeader={() => console.log("click FAQ")} />
        <div className="Faq-Element"></div>
        <Container maxWidth="md">
         
          <p className={classes.Title}>Still have a questions?</p>
          <p className={classes.Contain}>
            Write us at
            <b style={{ color: "#A80084" }}>
              <a href="mailto:contact@unifarm.co" style={{ color: "#A80084" }}>
                &nbsp;contact@unifarm.co
              </a>
            </b>
            <br />
            and we will get back to you shortly.
          </p>
        </Container>

        <div style={{ marginTop: "7rem" }}>
          <Footer
            Investor={() => console.log("click")}
            IpadClick={() => console.log("click")}
            RoadMapClick2={() => console.log("click")}
            RoadMapClickIpad={() => console.log("click")}
            WhyUnifarmClick2={() => console.log("click")}
            WhyUnifarmClickIpad={() => console.log("click")}
            isInnerPage={true}
          />
        </div> */}

      {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div>
            <a href="/">
              <img src={Logo} style={{ height: "auto", width: 215 }} />
            </a>
          </div>
          <a href="/">
            <span
              style={{
                fontSize: "15px",
                marginTop: "40px",
                marginBottom: "20px",
                color: "black",
              }}
            >
              Back To Home
            </span>
          </a>
        </div> */}
      {/* </Container2>  */}
      {/* </HelmetProvider> */}
    </>
  );
};
export default FAQ;
