import React from 'react'
import Telegram from '../../assets/svg/telegram.svg'

const FlotingIcon = () => {
    return (
        <div style={{display:'flex'}}>
            <div className="tg-message" >
                <div className="tg--text">Join the telegram community!</div>
            </div>
            <a href="https://telegram.me/unifarm" className="float">
                <img src={Telegram} />
            </a>
        </div>



    )
}
export default FlotingIcon