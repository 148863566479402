import React from "react";
import styled from "styled-components";
import logoImg from "../../assets/image/Logo/logo2.png";
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  box-shadow: 4px 4px 25px #e0e0e0;
  padding: 0 24px;
`;
const LogoImg = styled.img`
  height: 45px;
  object-fit: contain;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TabButton = styled.button<{ active: boolean }>`
  border-bottom: 2px solid transparent;

  ${(props) =>
    props.active &&
    `
    border-bottom: 2px solid #4527a0;
    `}
  padding: 24px;
`;
function Header() {
  const [activeTab, setActiveTab] = React.useState("publish");
  return (
    <Container>
      <LogoImg src={logoImg} alt="logo" />
      <ButtonsContainer>
        <TabButton
          active={activeTab === "preview"}
          onClick={() => setActiveTab("preview")}
        >
          Preview
        </TabButton>
        <TabButton
          active={activeTab === "publish"}
          onClick={() => setActiveTab("publish")}
        >
          Publish
        </TabButton>
      </ButtonsContainer>
    </Container>
  );
}

export default Header;
