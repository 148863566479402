import React from "react";

interface IsText {
  title: any;
}
const CopyRightText = (props: IsText) => {
  return (
    <div
      className="text-center"
      style={{ fontSize: "17px", marginBottom: "30px", marginTop: "30px" }}
    >
      {props.title}
    </div>
  );
};
export default CopyRightText;
