import React, { useEffect } from "react";
import Wrapper from "./SubComponent/Wrapper";
import Contain from "./SubComponent/Contain";
import AOS from "aos";

interface IsComponay {
  InvestorClick: () => void;
  isPage: boolean;
}

const Company = ({ InvestorClick, isPage }: IsComponay) => {
  useEffect(() => {
    AOS.init({
      duretion: 2000,
    });
  });

  return (
    <div data-aos="fade-left">
      <Wrapper>
        <b className="text-lg leading-footer sm:text-lg sm:mt-3 ">Company</b>
        {/* <Contain url="https://unifarm.co/faqs.php" title="FAQs" /> */}
        {isPage ? null : (
          <div onClick={InvestorClick} className="invester">
            Investor
          </div>
        )}
        <Contain url="http://unifarm.career.co/" title="Careers" />
        <Contain
          url="https://www.linkedin.com/company/unifarm-co/"
          title="Our Team"
        />
        <Contain
          url="https://fellowship-program.super.site/"
          title="Ambassador"
        />
        <a
          href="/team-verification"
          target="_blank"
          rel="noreferrer"
          className="text-base whitespace-nowrap leading-9 text-white sm:text-sm sm:leading-8"
        >
          Team Verification
        </a>
        <Contain url="https://now-us.typedream.app/" title="Contact Us" />
        {/* <a
          href="https://now-us.typedream.app/"
          target="_blank"
          rel="noreferrer"
          className="text-base whitespace-nowrap leading-9 text-white sm:text-sm sm:leading-8"
        >
          Contact Us
        </a> */}
      </Wrapper>
    </div>
  );
};

export default Company;
