import React from 'react'
import { makeStyles, Theme } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) => ({
    root:{
      display:'flex',
      alignItems:'flex-start',
      marginBottom:'1.5rem'
    },
     Textroot:{
        width: "363px", height: "146px",
        [theme.breakpoints.down('xs')]:{
              
        }
     },
     TextHead:{
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "26px"
     },
     TextDetail:{
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "20px"
     }
}))
interface IsWorkCard{
    url:string
    title:string
    content:string
}
const WorkCard=({url,title,content}:IsWorkCard)=>
{
    const classes=useStyles()
    return(
        <div className={classes.root}>
            <img src={url} width={65} style={{marginTop:'-0.7rem',marginRight:'1.5rem'}}/>
            <div className={classes.Textroot}>
                  <p className={classes.TextHead}>{title}</p>
                  <p className={classes.TextDetail}>{content}</p>
            </div>
        </div>
    )
}
export default WorkCard