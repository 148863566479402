import React from 'react'
import SectionTeam from '../TeamSectionTitle/index'
// import Tab from './Tab'
import Tab from './Tab2'
    
const Join=()=>
{
    return(
        <>
         <SectionTeam
                title='Join our team'
                description='Current Openings'
            />
            <div style={{display:'flex',justifyContent:'center'}}>
            <Tab/>
            </div>
            <br/>
            <br/>
            <br/>
        </>
    )
}
export default Join