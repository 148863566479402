import React from "react";
interface IsWrapper {
  children: React.ReactNode;
}
const ImageWrapper = (props: IsWrapper) => {
  return (
    <div className="flex justify-around items-center">{props.children}</div>
  );
};
export default ImageWrapper;
