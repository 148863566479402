import React from "react";
import { Carousel } from "3d-react-carousal";
// import Slider1 from "../../assets/UnifarmLandingPage/slider2.png";
import { useStyles } from "./LaunchpadStyle";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { useTheme } from "@material-ui/core/styles";
// import BattleImage from "../../assets/image/battleimage.png";
import BattleVerseBanner from "../../assets/image/battleversesoldout.png";
import DcdEcoBanner from "../../assets/image/dcdsoldout.png";
import GameyooBanner from "../../assets/image/gameyoosoldout.png";
import WizardBanner from "../../assets/image/wizardiasoldout.png";
import { useTheme, useMediaQuery } from "@material-ui/core";
import MobileSlider from "./Mobile/index";
// import Unilaunchlogo from "../../assets/image/unilaunchlogo.png";
import Rocket from "../../assets/image/rocketer.png";
// import LegionWebSlider from "../../assets/image/legionlivenow.png";
import LegionWebSlider from "../../assets/image/legionlivesold.png";
// import Sportzchain_upcomming from "../../assets/image/Sc_upcoming_web.png";
// import Sportzchain_live from "../../assets/image/ido_live_mob.png";
import Sportzchain_live from "../../assets/image/SoldOut_Slider_web.png";
// import LegionMobSlider from "../../assets/image/LegionMobSlider.png";
import { Link } from "react-router-dom";

const LaunchpadSlider = () => {
  // const IsSlider = true;
  const classes = useStyles();
  const Theme = useTheme();
  const mobile = useMediaQuery(Theme.breakpoints.down("xs"));
  let slides = [
    <Link key={1} to="/launch-SportzchainIDO" >
    <img src={Sportzchain_live} alt="1"  />
    </Link>,
   
    <img src={WizardBanner} alt="2" key={2} />,
    <img src={DcdEcoBanner} alt="3" key={3} />,
    <img src={GameyooBanner} alt="4" key={4} />,
    <img src={BattleVerseBanner} alt="5" key={5} />,
    <img src={LegionWebSlider} alt="6" key={6}/>
  ];
  const callback = function (index) {
    console.log("callback", index);
  };

  // const theme = useTheme();
  return (
    <>
      <div>
        <div className={classes.sliderHeader}>
          UniLaunch
          <img src={Rocket} width={40} height={40} />
        </div>
        {mobile ? (
          <MobileSlider />
        ) : (
          <div className={classes.SliderRoot}>
            <Carousel
              slides={slides}
              autoplay={false}
              interval={1000}
              onSlideChange={callback}
              
            />
          </div>
        )}
      </div>
    </>
  );
};
export default LaunchpadSlider;
