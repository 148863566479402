import React from 'react'
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "329px",
        height: "301px",
        left: "143px",
        top: "908px",
        background: "#FFFFFF",
        border: "1px solid #E0E0E0",
        boxSizing: "border-box",
        borderRadius: "15px",
        marginBottom:'2rem',

        "&:hover": {
            transform: 'scale(1.01)',
            boxShadow: "0px 10px 40px #E0E0E0"
        },
        [theme.breakpoints.down('xs')]: {

        }
    },
    cardDescriptionDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: "0px",
        marginLeft: '1.5rem',
       
        width: "298px",
        height: "146px",
        left: "159px",
        top: "1043px"
    },
    cardTitle: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        marginBottom: '0.5rem'
    },
    cardDescription: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px"
    },
    imgWidth:{
        width:'72px',
        marginLeft:'1.5rem',
        marginTop:'2rem'
    }
}))
interface IsCard{
    title:string
    description:string
    url:string
}
const Card = ({title,description,url}:IsCard) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <img src={url} className={classes.imgWidth}/>
            <div className={classes.cardDescriptionDiv}>
                <span className={classes.cardTitle}>{title}</span>
                <span className={classes.cardDescription}>{description}</span>
            </div>
        </div>
    )
}
export default Card