import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { SyncLoader } from "react-spinners";
import FAQ from "./component/Faq";
import Team from "./page/Team/index";
import Media from "./component/Media";
import Gameyoo from "./component/IDOs/Gameyoo";
import LandingPage from "./page/LandingPage/index";
import UnifarmLaunchpad from "./page/Unifarm-launchpad/index";
import Battleverse from "./component/IDOs/Battleverse";
import Wizardia from "./component/IDOs/Wizardia";
import LegioNetwork from "./component/IDOs/LegioNetwork";
import DcdEcoSystem from "./component/IDOs/DcdEcosystem";
import Sportzchain from "./component/IDOs/Sportzchain";
import GalleryPage from "./page/Gallery";
import GalleryBackend from "./page/GalleryBackend";
import Thunderfarms from "./page/thunderfarms";
import SetuLandingPage from "./page/SetuLandingPage";

function App() {
  const [IsLoading, setLodding] = React.useState(false);

  // const desc = "Decentralized farming pool of DEFI’s top projects";

  React.useEffect(() => {
    setTimeout(() => {
      setLodding(true);
    }, 1500);
    if (window.location.pathname === "/launchpad") {
      window.location.href = "launch";
    }
    // document
    //   .querySelector('meta[name="description"]')
    //   .setAttribute("content", desc);
  });

  return (
    <HelmetProvider>
      <Router>
        {IsLoading ? (
          <Switch>
            <Route path="/" exact>
              <LandingPage />
            </Route>
            <Route path="/Setu" exact>
              <SetuLandingPage />
            </Route>
            <Route path="/GalleryBackend" exact>
              <GalleryBackend />
            </Route>
            <Route path="/faq" exact>
              <FAQ
                title="Frequently Asked Questions"
                desc="Frequently asked questions by users on UniFarm"
              />
            </Route>

            <Route path="/launch-GameyooIDO" exact>
              <Gameyoo />
            </Route>
            <Route path="/gallery" exact>
              <GalleryPage />
            </Route>

            <Route path="/launch-BattleverseIDO" exact>
              <Battleverse />
            </Route>
            <Route path="/LegioNetwork" exact>
              <LegioNetwork />
            </Route>
            <Route path="/launch-WizardiIDO" exact>
              <Wizardia />
            </Route>
            <Route path="/launch-DcdEcosystem" exact>
              <DcdEcoSystem />
            </Route>
            <Route path="/launch-SportzchainIDO" exact>
              <Sportzchain
                title="Live with Sportzchain IDO"
                desc="World’s first Engage-To-Earn (E2E) platform for sports fans powered by $SPN offering Sports Tokens (Fan Token 2.0), Watch-To-Earn, Fan Commerce, Gamefi and more."
              />
            </Route>

            <Route path="/launch" exact>
              <UnifarmLaunchpad
                title="Launching hand-picked high-quality projects on the Blockchain"
                desc="UniLaunch allows you to raise funds for your web3 project"
              />
            </Route>
            <Route path="/team" exact>
              <Team
                title="We are a group of wierdos who are working together to achieve a common purpose of wealth creation"
                desc="We are a group of wierdos who are working together to achieve a common purpose of wealth creation"
              />
            </Route>
            <Route path="/team-verification" exact>
              <Media
                title="Team verification"
                desc="To prevent any frauds confirm official UniFarm contact details"
              />
            </Route>
            <Route path="/thunderfarms">
              <Thunderfarms />
            </Route>
          </Switch>
        ) : (
          <div
            className="loder"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <SyncLoader size={25} color="#cb218e" />
          </div>
        )}
      </Router>
    </HelmetProvider>
  );
}

export default App;
