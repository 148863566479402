import React from "react";
// import PieChart from "../../../assets/image/piechart.png";
// import Graph from "../../../assets/image/graph.png";
// import MobilePie from "../../../assets/image/Mobilepie.png";
// import MobileGraph from "../../../assets/image/MobileGraph.png";
// import GraphPC from "../../../../assets/image/GraphPC.png";
// import PiePC from "../../../../assets/image/PiePC.png";
import Container from "@material-ui/core/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { makeStyles } from "@material-ui/core/styles";
// import GraphTopPc from "../../../../assets/image/GraphTopPc.png";
// import Tokenomics from "../../../../assets/image/Tokenomics.png";
// import SaleCond from "../../../../assets/image/Salecond.png";
import GraphPC from "../../../../assets/image/launch/Chart/1new.png";
// import PiePC2 from "../../../../assets/image/launch/Chart/2.png";
import PiePC from "../../../../assets/image/launch/Chart/3.png";
import DataTable1 from "../../../../assets/image/launch/Chart/4.png";
import DataTable2 from "../../../../assets/image/launch/Chart/5.png";

const useStyles = makeStyles((theme) => ({
  Grid: {
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "20px",
    },
  },
  Main_Card: {
    width: "100%",
    height: "100%",
    background: "#ffffff",
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    padding: "20px",
  },
  Sub_Card: {
    width: "987px",
    height: "617px",
    background: "#ffffff",
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      marginTop: "20px",
    },
  },

  Sub_Card2 : {
    width: "557px",
    height: "408px",
    background: "#ffffff",
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      marginTop: "20px",
    },
  },
  Title_div: {
    display: "flex",
    flexDirection: "column",
  },
  Card_title: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#212121",
  },
  Card_text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
    marginTop: "10px",
  },
  Img_div: {
    marginTop: "30px",
  },
}));

const Schedule = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item lg={6} xs={12} className={classes.Grid}>
          <Card className={classes.Main_Card}>
            <div className={classes.Title_div}>
              <span className={classes.Card_title}>$DCD IDO Launch Page</span>
              <span className={classes.Card_text}>
                30% TGE, 8.50% every week afterwards
              </span>
            </div>
            <div className={classes.Img_div}>
              <img src={GraphPC} alt="BVC" />
            </div>
          </Card>
        </Grid>
        <Grid item lg={6} xs={12} className={classes.Grid}>
          <Card className={classes.Main_Card}>
            <div className={classes.Title_div}>
              <span className={classes.Card_title}>Tokenomics</span>
            </div>
            <div className={classes.Img_div}>
              <img src={PiePC} alt="LGX" />
            </div>
          </Card>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            marginTop: "30px",
            paddingLeft: "10px",
            paddingRight:"10px",
          }}
        >
          <Card className={classes.Sub_Card}>
            <div className={classes.Img_div}>
              <img src={DataTable1} alt="LGX" width={987} height={577} />
            </div>
          </Card>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            marginTop: "30px",
            paddingLeft: "10px",
            paddingRight:"10px",
          }}
        >
          <Card className={classes.Sub_Card2}>
            <div className={classes.Img_div}>
              <img src={DataTable2} alt="LGX" width={557} height={358} />
            </div>
          </Card>
        </div>
      </Grid>
    </Container>
  );
};

export default Schedule;
