import { Grid } from "@mui/material";
import React from "react";
import {
  HeroButton,
  HeroContent,
  HeroContentWrap,
  HeroTitle,
  MainWrapper,
} from "./Herosection.style";
import HeroImage from "../../../assets/Setuimage/heroImage.png";
import { useMobile } from "../MuiMediaQuery/MuiMediaQuery";
const HeroSection = () => {
  const mobile = useMobile();
  return (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <MainWrapper>
          <HeroContentWrap>
            {mobile ? (
              <HeroTitle>
                Bridge Token,
                <br /> Earn Rewards with <br />
                Liquidity
              </HeroTitle>
            ) : (
              <HeroTitle>
                Bridge Token,
                <br /> Earn Rewards <br />
                with Liquidity
              </HeroTitle>
            )}

            {mobile ? (
              <img src={HeroImage} width={563} style={{ zIndex: 1111 }} />
            ) : (
              <HeroContent>
                A cross-chain bridge to initiate communication
                <br />
                between two blockchains, allowing the transfer of
                <br /> value and information.
              </HeroContent>
            )}

            <a
              href="https://app.unifarm.co/setu"
              target="_blank"
              rel="noreferrer"
            >
              <HeroButton>Enter App</HeroButton>
            </a>
          </HeroContentWrap>
          {mobile ? null : <img src={HeroImage} width={563} />}
        </MainWrapper>
      </Grid>
    </Grid>
  );
};
export default HeroSection;
