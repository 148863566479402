import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import Sectiontitle from "../Sectiontitle/index";
import CaseStudyCard from "./SubComponent/CaseStudyCard";

// import Cs1 from "../../assets/image/CaseStudy/cs1.png";
// import Cs2 from "../../assets/image/CaseStudy/cs2.png";
// import Cs3 from "../../assets/image/CaseStudy/cs3.png";

const MobileSlider = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    GetBlog();
  }, []);
  const GetBlog = () => {
    fetch("https://blog.unifarm.co/wp-json/wp/v2/posts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((result) => setData((oldArray) => [...oldArray, ...result]));
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay:true
  };

  return (
    <div>
      <Sectiontitle title="Case Studies" />
      <Slider {...settings}>
        {data.map(
          (datas, index) =>
            index < 3 && (
              <>
                <div className="flex justify-around pt-5 pb-5">
                  <div >
                    <a key={index} href={datas.link}>

                      <CaseStudyCard
                        Url={datas.yoast_head_json.og_image[0].url}
                        Imagewidth={250}
                        Title={datas.title.rendered}
                        />
                    </a>
                  </div>
                </div>
              </>
            )
        )}
      </Slider>
    </div>
  );
};
export default MobileSlider;

{
  /* <div className="flex justify-around pt-5 pb-5">
          <CaseStudyCard
            Url={Cs2}
            Imagewidth={250}
            Title="User Earns 2552.669% APY with UniFarm Cohort 12"
          />
        </div>
        <div className="flex justify-around pt-5 pb-5">
          <CaseStudyCard
            Url={Cs3}
            Imagewidth={250}
            Title="User Earns 1229.60% APY with UniFarm Cohort 3"
          />
        </div> */
}
