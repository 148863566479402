import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import React from "react";
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import "./HelloBar.css";
import AnimateIcon from "./AnimatIcone";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import InfoIcon from "@material-ui/icons/Info";

interface IsHellobar {
  ArrowClick: () => void;
  innerArrowClick: () => void;
}
const HelloBar = ({ ArrowClick, innerArrowClick }: IsHellobar) => {
  // const [Message, setMessage] = useState("");
  const [Message, setMessage] = useState("");
  const [ButtonLink, setButtonLink] = useState();
  const [status, setStatus] = useState();
  const [label, setLabel] = useState();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  //   const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));

  useEffect(() => {
    // Authentication()
    const Token = localStorage.getItem("Token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + Token + "");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      request_code: "72758fa297ad3d3516",
      platform: "Uniform",
    });

    fetch("https://my.oropocket.com/restAPI/hello_bar.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setStatus(result.data.hello_bar);
        setMessage(result.data.hello_bar.message),
        // setMessage("Hurray!!! UniFarm has become Gasless now - Experience the Gasless Staking"),
          setButtonLink(result.data.hello_bar.button);
        setLabel(result.data.hello_bar.button_label);
        // setLabel("All Farms");
      })
      .catch((error) => console.log("error", error));
  }, []);


  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    secret_key: "607806ddf20e8483b289cb3c245ec52f",
    api_id: "92878ff5ac678faa2c997ad3ad35c147",
    request_code: "72758fa297ad3d3516",
  });


  // axios({
  //   method: "POST",
  //   url:"https://my.oropocket.com/restAPI/authentication.php",
  //   data: raw,
  //  headers:{"Content-Type": "application/x-www-form-urlencoded"} 
  // }).then((res)=>{
  //   console.log("success", res)
  // }).catch((err)=>{
  //   console.log("Error",err)
  // })

  fetch("https://my.oropocket.com/restAPI/authentication.php", {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((result) => localStorage.setItem("Token", result.token))
    .catch((error) => console.log("error", error));

  return (
    <>
      {status ? (
        <div className="hellobar">
          {status === null ? null : (
            <div className="hellobar-wrap">
              <input
                type="checkbox"
                className="hellobar-toggle"
                onClick={() => {
                  ArrowClick();
                  innerArrowClick();
                }}
              />
              <a href="#" className="hellobar-arrow"></a>
              {Mobile ? (
                <div className="hellobar-content">
                  <div className="hellobar_mobile">
                    {/* {Message ? Object.keys(Message).slice(0, 63) : ""} */}
                    {Message ? Message.slice(0, 74) : ""}..
                    &nbsp;&nbsp;
                    <Tippy theme="light" placement="bottom" content={Message}>
                      <InfoIcon
                        style={{
                          fontSize: "15px",
                          color: "#C4C4C4",
                        }}
                      />
                    </Tippy>
                  </div>
                  <div>
                    <a
                      href={ButtonLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        style={{
                          border: "1px solid #CB218E",
                          textTransform: "capitalize",
                          borderRadius: 10,
                        }}
                      >
                        <AnimateIcon />
                        &nbsp;{label}
                      </Button>
                    </a>
                  </div>
                </div>
              ) : (
                <div className="hellobar-content">
                  {Message} &nbsp;&nbsp;
                  <a
                    href={ButtonLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      style={{
                        border: "1px solid #CB218E",
                        textTransform: "capitalize",
                        borderRadius: 10,
                        // width:"10%",
                        // padding:'4px 8px'
                      }}
                    >
                      <AnimateIcon />
                      &nbsp;{label}
                    </Button>
                  </a>
                </div>
              )}
              {/* <div className="hellobar-content">
                {Message} &nbsp;&nbsp;
                <a href={ButtonLink} target="_blank" rel="noopener noreferrer">
                  <Button
                    style={{
                      border: "1px solid #CB218E",
                      textTransform: "capitalize",
                      borderRadius: 10,
                    }}
                  >
                    <AnimateIcon />
                    &nbsp;{label}
                  </Button>
                </a>
              </div> */}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};
export default HelloBar;
