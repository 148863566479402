import React from 'react'
import Card from "@mui/material/Card";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@material-ui/core/Divider';
import Button from "@mui/material/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const useStyles = makeStyles((theme) => ({
    CardRoot: {
        width: "431px", height: "310px", borderRadius: "20px", padding: 25,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: "100%"
        }
    },
    HeaderDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'

    },
    SpanText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px"
    },
    CloseIcone: {
        color: 'black'
    },
    Devider: {
        height: 1.4,
        background: '#212121'
    },
    DetailWrapper: {
        marginTop: '1.5rem',
        textAlign: 'center'
    },
    InfoDiv: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        textAlign: 'center',
        marginTop: '1.5rem'
    },
    ReportButton: {
        background: "#009F42",
        boxShadow: "0px 7px 18px -2px rgba(0, 200, 83, 0.5)",
        borderRadius: "10px",
        width: "380px",
        height: "50px",
        textTransform: "none",
        fontWeight: 500,
        fontSize: "15px",
        marginTop: '1.5rem',
        "&:hover": {
            background: "#009F42",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            background: "#009F42",
            boxShadow: "0px 7px 18px -2px rgba(0, 200, 83, 0.5)",
            borderRadius: "10px"
        }
    },
    TextField: {
        "&>.Mui-focused": {
            color: '#673AB7'
        },
        "&>.MuiInput-underline:after": {
            borderBottom: '2px solid #673AB7 !important'
        }

    }

}))
interface IsReport {
    CloseModal: () => void
}
const SucsessReport = ({ CloseModal }: IsReport) => {
    const classes = useStyles()
    return (
        <Card className={classes.CardRoot}>
            <div className={classes.HeaderDiv}>
                <span className={classes.SpanText}>Report</span>
                <IconButton className={classes.CloseIcone} onClick={CloseModal}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Divider className={classes.Devider} />
            <div className={classes.DetailWrapper}>
                <CheckCircleIcon style={{color:'#009F42',fontSize:50}}/>
                <div className={classes.InfoDiv}>
                    In unifarm we take every report seriously.
                    Your Report will be initiated in 24-48hrs
                </div>
                <Button
                    onClick={CloseModal}
                    variant="contained"
                    className={classes.ReportButton}
                >
                    Okay
                </Button>
            </div>

        </Card>
    )
}
export default SucsessReport