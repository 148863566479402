import styled from "styled-components";
export const NetwrokWrapper = styled.div`
  height: 340px;
  width: 100%;
  left: -1px;
  top: 1298px;

  /* page background/primary brand */

  background: #fbf8ff;
  /* dropshadow */

  box-shadow: 4px 4px 25px #e0e0e0;
  margin-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  @media (max-width: 430px) {
    height:auto;
  }
`;
export const NetwrokDiv = styled.div`
  width: 868px;
  height: 174px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 37px;
  @media (max-width: 430px) {
    width: 100%;
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    row-gap: 7px;
  }
`;
export const NetworkTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;

  color: #332233;
  @media (max-width: 430px) {
    font-size:23px;
    text-align:center;
  }
`;
export const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  column-gap: 55px;
  @media (max-width: 430px) {
    column-gap:0px;
  }
`;
export const NetwrokImage = styled.img`
  height: 90px;
  width: 90px;
  left: 0px;
  top: 0px;
  border-radius: 55.5px;
  padding: 15px;
  @media (max-width: 430px) {
    height: 60px;
    width: 60px;
  }
`;
