import { Grid } from "@mui/material";
import React from "react";

import SectionTitleCompo from "../SectionTitle";
import ProjectItems from "./ProjectItems";
import Pr1 from "../../../assets/Setuimage/pr1.png";
import Pr2 from "../../../assets/Setuimage/pr2.png";
import Pr3 from "../../../assets/Setuimage/pr3.png";
import { ProjectDiv, ProjectDiv1 } from "./project.style";

const ProjectSection = () => {
  return (
    <Grid container spacing={2}>
      <SectionTitleCompo
        title="For Projects"
        content={
          <>
            Free your tokens and utilize them to their fullest. Let
            <br /> your token holders experience the magic of SETU
          </>
        }
      />
      <ProjectDiv>
        <ProjectDiv1>
          <ProjectItems
            projectImage={Pr1}
            projectContent={
              <>
                The projects dont have to
                <br /> lock their tokens on multiple,
                <br /> blockchains.
              </>
            }
            projectTitle="No Need to Lock"
          />
          <ProjectItems
            projectImage={Pr2}
            projectContent={
              <>
                Projects can use the free
                <br /> liquidity for other purposes
                <br /> like airdrops, etops, etc.
              </>
            }
            projectTitle="Free-up Liquidity"
          />
        </ProjectDiv1>
        <ProjectDiv1>
          <ProjectItems
            projectImage={Pr1}
            projectContent={
              <>
                Project tokens can be locked
                <br /> by liquidity providers to earn
                <br /> rewards.
              </>
            }
            projectTitle="More Utility of Tokens"
          />
          <ProjectItems
            projectImage={Pr3}
            projectContent={
              <>
                SETU incentivizes users to <br />
                hold more project tokens
                <br /> and provide liquidity.
              </>
            }
            projectTitle="Increased Market Exposure"
          />
        </ProjectDiv1>
      </ProjectDiv>
      {/* <Grid item md={12} xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <ContactButton>Contact Unifarm</ContactButton>
      </Grid> */}
    </Grid>
  );
};
export default ProjectSection;
