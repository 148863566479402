import React from 'react'
import Mouse from '../../../assets/image/ExploreSection/Mouse.png'
import Pr from '../../../assets/image/ExploreSection/Pr.png'
import Scale from '../../../assets/image/ExploreSection/Scale.png'

import { MyCard } from '../Subcomponent/Card'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    projectClass:{
      width: '100%'
    }
  }));
const IpadExploreSection = () => {
  const classes=useStyles()
  
  const openApp = () => {
    window.open('https://app.unifarm.co/')

}
    return (
        <div className={"section-margin"+' '+classes.projectClass}>


            <div className='flex-col items-center justify-center w-auto'>

                <div className="flex items-center justify-center mb-3">
                <MyCard
                    Url={Pr}
                    Imagewidth={90}
                    margin={3}
                    Title="Minimum Guaranteed APY"
                    Detail="Earn a minimum APY of 36% on all cohorts. This gives the stakers surety of earning good yield on their tokens."
                />


                <MyCard
                    Url={Scale}
                    Imagewidth={50}
                    margin={3}
                    Title="One place to Farm"
                    Detail="UniFarm enables you to farm tokens in one place. Users no longer have to go on various platforms or exchanges to farm different tokens."
                />
                </div>
               <div className="flex items-center justify-center">
               <MyCard
                    Url={Mouse}
                    Imagewidth={90}
                    margin={3}
                    Title="Easy to Use"
                    Detail="UniFarm offers a simple user interface with a great user experience offering one-click stake and unstake facility on a completely decentralized platform."
                />
               </div>
            </div>
            <div className="flex justify-center mt-5 mb-5">
                <button className="Submit-btn" onClick={openApp}>Open App</button>
            </div>
        </div>
    )
}
export default IpadExploreSection