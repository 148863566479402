import React from "react";
import Logo from "../../../assets/image/Logo/logo2.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    marginTop: "90px",
    paddingBottom: "10px",
    [theme.breakpoints.down('xs')]: {
      marginTop: '80px',
    }
  },
  Img: {
    height: "auto",
    width: 215,
  },
  Text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
  },
}));

const BackHome = () => {
  const classes = useStyles();
  return (
    <div className={classes.Root}>
      <a href="/">
        <img src={Logo} className={classes.Img} />
      </a>
      <a href="/">
        <span className={classes.Text}>Back To Home</span>
      </a>
    </div>
  );
};

export default BackHome;
