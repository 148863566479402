import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQdata from "./FaqData.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: "2rem",
      "&>.MuiPaper-elevation1": {
        boxShadow: "14px 14px 40px #F7F7F7, inset 0px -1px 0px #E0E0E0",
      },
      "&>.MuiPaper-root .MuiAccordionSummary-root": {
        background: "#FBFBFB !important",
        height: 78,
        [theme.breakpoints.down("xs")]: {
          height: "auto",
        },
      },
      "&>.MuiPaper-root .MuiAccordionSummary-root .MuiCollapse-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner":
        {
          background: "#FFFFFF !important",
        },
    },
    heading: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
      },
      // fontWeight: theme.typography.fontWeightRegular,
    },
    Content: {
      fontFamily: "Inter !important",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
    },
    FaqHeading: {
      fontFamily: "Nexa",
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: "48px",
      textAlign: "center",
      marginTop: "3rem",
      [theme.breakpoints.down("xs")]: {
        marginTop: "5rem",
        position: "relative",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "6rem",
      },
    },
  })
);

export default function SimpleAccordion() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <h1 className={classes.FaqHeading}>FAQs</h1>
      <div className={classes.root}>
        {FAQdata.map((dt, index) => {
          return (
            <Accordion
              key={index}
              expanded={expanded === index.toString()}
              onChange={handleChange(index.toString())}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{dt.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.Content}>
                  {dt.description}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </>
  );
}
