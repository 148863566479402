import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { motion } from "framer-motion";
import React from "react";
import { HeaderWrapper } from "../../Animation/HeaderAnimation";
import Logo from "../../assets/image/Logo/logo2.png";
import MobileHeader from "./MobileHeader";
import About from "./sub-component/About";
import Resource from "./sub-component/Resource";
import Ufarm from "./sub-component/Ufarm";
import RocketUni from "../../assets/image/rocketUni.png";

interface IsMyheader {
  ClickHeader: () => void;
}
const Header = ({ ClickHeader }: IsMyheader) => {
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const LaunchApp = () => {
    window.open("https://app.unifarm.co/");
  };
  const ApplyBtn = () => {
    window.open("https://airtable.com/shrOYXo0btVCvILhw");
  };
  return (
    <>
      {Mobile ? (
        <MobileHeader clickHelloBar={ClickHeader} />
      ) : Ipad ? (
        <MobileHeader clickHelloBar={ClickHeader} />
      ) : (
        <motion.div
          variants={HeaderWrapper}
          initial="initial"
          animate="animate"
          className="flex items-center justify-between mt-7"
        >
          <div>
            <a href="/">
              <img src={Logo} width={210} height="auto" />
            </a>
          </div>

          <div className="flex items-center justify-between w-9/12">
            <Ufarm />
            {/* <a
              href="https://fellowship-program.super.site/"
              target="_blank"
              rel="noreferrer"
              className="ant-dropdown-link text-white hover:text-white text-base flex items-center"
            >
              Fellowship Program
             
            </a> */}
            {/* <a
              href="https://unifarm.notion.site/Crypto-Meme-Carnival-7268b26443f346c78d54f1a765eac251"
              target="_blank"
              rel="noreferrer"
              className="ant-dropdown-link text-white hover:text-white text-base flex items-center "
            >
              Meme Carnival
              
            </a> */}
            {window.location.pathname === "/launch" ? (
              <>
                <div className="flex justify-between items-center gap-2 mt-1 pb-1 border-b-2 border-borderColor"
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "space-between",
                  //   alignItems: "center",
                  //   gap: "10px",
                  //   marginTop: "3px",
                  //   paddingBottom: "3px",
                  //   borderBottom: "1px solid #cb218e",
                  // }}
                >
                  <img src={RocketUni} />
                  <span className="text-base font-normal "
                    // style={{ fontSize: "16px", fontWeight: 400, color: "#000" }}
                  >
                    UniLaunch
                  </span>
                </div>
              </>
            ) : (
              <a
                href="/launch"
                className="ant-dropdown-link text-white hover:text-white text-base flex items-center"
              >
                <div className="flex justify-between items-center gap-2"
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "space-between",
                  //   alignItems: "center",
                  //   gap: "10px",
                  // }}
                >
                  <img src={RocketUni} />
                  <span
                  className="text-base font-normal "
                    // style={{ fontSize: "16px", fontWeight: 400, color: "#000" }}
                  >
                    UniLaunch
                  </span>
                </div>
              </a>
            )}

            <About />
            <Resource />
            <div className="ml-2">
              <button
                className="bg-DropColor pt-1.5 pb-1.5 pl-3 mr-6 border-2 rounded-full pr-2 grounded-radiants "
                onClick={ApplyBtn}
              >
                Apply for cohort
              </button>
              <button className="Submit-btn" onClick={LaunchApp}>
                Launch App
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};
export default Header;
