// import React from "react";
// import AppBar from "@material-ui/core/AppBar";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import Typography from "@material-ui/core/Typography";
// import Box from "@material-ui/core/Box";
// import { useStyles } from "./LaunchpadStyle";
// import UpcommingCard from "./SubComponents/UpcommingCard";
// // import UpcoomingImage from '../../assets/UnifarmLandingPage/upcomming2.png'
// // import Ido from '../../assets/UnifarmLandingPage/ido.png'
// // import Label2 from '../../assets/UnifarmLandingPage/label2.png'
// // import Past2 from '../../assets/UnifarmLandingPage/past2.png'
// // import Dcd from "../../assets/image/dcd1.png";
// // import Gameyoo from "../../assets/image/gameyoo1.png";
// // import DcdEcoUp from "../../assets/image/DcdEcoUp.png";
// // import WizardTop from "../../assets/image/WizardTop.png";
// // import WizardLogo from "../../assets/image/WizardLogo.png";
// // import RocketBoost from "../../assets/image/RocketBoost.png";
// import { useMediaQuery, useTheme } from "@material-ui/core";
// // import Button from "@material-ui/core/Button";
// // import BannerRocket from "../../assets/image/webrocket2.png";
// // import MobileRocket from "../../assets/image/mobilerocket3.png";
// // import BannerforWeb from "../../assets/image/Bannerforweb.png";
// // import WebIdo from "../../assets/image/UniWebIdo.png";
// // import MobIdo from "../../assets/image/UniMobIdo.png";
// import LegionWebIdo from "../../assets/image/legionwebido.png";
// import LegionMobIdo from "../../assets/image/legionmobido.png";
// import LegionBg from "../../assets/image/legionbg.png";
// import LegionLogo from "../../assets/image/legionlogo.png";

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: any;
//   value: any;
//   style: any;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, style, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//       style={style}
//     >
//       {value === index && (
//         <Box>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index: any) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// export default function Simpl`xeTabs() {
//   const classes = useStyles();
//   const theme = useTheme();
//   const mobile = useMediaQuery(theme.breakpoints.down("xs"));
//   const [value, setValue] = React.useState(0);
//   const [toggle] = React.useState(true);

//   const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
//     setValue(newValue);
//   };

//   // const openTelegram = () => {
//   //   window.open("https://telegram.me/myunifarm");
//   // };

//   // const applyasProject = () => {
//   //   window.open("https://airtable.com/shrWrtMtxSS6ZJ4hB");
//   // };

//   const UpcommingData = [
//     {
//       cardImage: LegionBg,
//       liveDate: "18/04/2022",
//       idoImage: LegionLogo,
//       idoName: "Legion Network IDO",
//       idoContent: `The Worlds' First Blockchain Ecosystem that combines the best services in the industry in one Super App | Crypto | NFTs | GameFi`,
//       linkToPage: "/LegioNetwork",
//     },
//     // {
//     //   cardImage: DcdEcoUp,
//     //   liveDate: "17/03/2022",
//     //   idoImage: Dcd,
//     //   idoName: "DCD Ecosystem",
//     //   idoContent: `DCD Ecosystem is a GameFi Infrastructure Protocol, built to help gamers build games and integrate DeFi elements.Unique modules allow you to create a provably honest online game without the use of centralized servers. `,
//     //   linkToPage: "/launch-DCDEcosystem",
//     // },
//     // {
//     //   cardImage: WizardTop,
//     //   liveDate: "21/03/2022",
//     //   idoImage: WizardLogo,
//     //   idoName: "Wizardia",
//     //   idoContent: `Immerse yourself into the Play-to-earn magic of Wizardia.It is a Play-to-Earn online role-playing strategy game with unique NFTs at it’s core. Players get rewards from Wizardia by monetary investments.`,
//     //   linkToPage: "/launch-WizardiIDO",
//     // },
//   ];
//   const tabList = [
//     {
//       labelName: "Defi",
//     },
//     {
//       labelName: "Gaming",
//     },
//     {
//       labelName: "P2E",
//     },
//     {
//       labelName: "NFTs",
//     },
//   ];
//   return (
//     <>
//       {toggle ? (
//         <div className={classes.root}>
//           {" "}
//           <div className={classes.tabHeader}>Upcoming IDOs</div>
//           <AppBar position="static" className={classes.appbar}>
//             <Tabs
//               value={value}
//               className={classes.myTab}
//               classes={{ indicator: classes.indicator }}
//               onChange={handleChange}
//               aria-label="simple tabs example"
//               centered
//               variant={mobile ? "scrollable" : null}
//               scrollButtons={mobile ? "on" : null}
//             >
//               {tabList.map((tb, index) => {
//                 return (
//                   <Tab
//                     label={tb.labelName}
//                     key={index}
//                     {...a11yProps(index)}
//                     style={{ fontFamily: "Inter" }}
//                   />
//                 );
//               })}
//             </Tabs>
//           </AppBar>
//           <TabPanel value={value} index={0} style={{ marginTop: "1.5rem" }}>
//             <div className={classes.TabPanelRoot}>
//               {UpcommingData.map((data, index) => {
//                 return (
//                   <UpcommingCard
//                     cardImage={data.cardImage}
//                     liveDate={data.liveDate}
//                     idoImage={data.idoImage}
//                     idoName={data.idoName}
//                     idoContent={data.idoContent}
//                     key={index}
//                     linkToPage={data.linkToPage}
//                   />
//                 );
//               })}
//             </div>
//           </TabPanel>
//           <TabPanel value={value} index={1} style={{ marginTop: "1.5rem" }}>
//             <div className={classes.TabPanelRoot}>
//               {UpcommingData.map((data, index) => {
//                 return (
//                   <UpcommingCard
//                     cardImage={data.cardImage}
//                     liveDate={data.liveDate}
//                     idoImage={data.idoImage}
//                     idoName={data.idoName}
//                     idoContent={data.idoContent}
//                     key={index}
//                     linkToPage={data.linkToPage}
//                   />
//                 );
//               })}
//             </div>
//           </TabPanel>
//           <TabPanel value={value} index={2} style={{ marginTop: "1.5rem" }}>
//             <div className={classes.TabPanelRoot}>
//               {UpcommingData.map((data, index) => {
//                 return (
//                   <UpcommingCard
//                     cardImage={data.cardImage}
//                     liveDate={data.liveDate}
//                     idoImage={data.idoImage}
//                     idoName={data.idoName}
//                     idoContent={data.idoContent}
//                     key={index}
//                     linkToPage={data.linkToPage}
//                   />
//                 );
//               })}
//             </div>
//           </TabPanel>
//           <TabPanel value={value} index={3} style={{ marginTop: "1.5rem" }}>
//             <div className={classes.TabPanelRoot}>
//               {UpcommingData.map((data, index) => {
//                 return (
//                   <UpcommingCard
//                     cardImage={data.cardImage}
//                     liveDate={data.liveDate}
//                     idoImage={data.idoImage}
//                     idoName={data.idoName}
//                     idoContent={data.idoContent}
//                     key={index}
//                     linkToPage={data.linkToPage}
//                   />
//                 );
//               })}
//             </div>
//           </TabPanel>{" "}
//         </div>
//       ) : (
//         <div className={classes.root}>
//           {" "}
//           <div className={classes.tabHeader}>Upcoming IDOs</div>{" "}
//           <div className={classes.BannerForIdo}>
//             {mobile ? (
//               <a href="https://airtable.com/shrWrtMtxSS6ZJ4hB">
//                 <img src={LegionMobIdo} />
//               </a>
//             ) : (
//               <a href="https://airtable.com/shrWrtMtxSS6ZJ4hB">
//                 <img src={LegionWebIdo} />
//               </a>
//             )}
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MobileRocket from "../../assets/image/mobilerocket3.png";
import BannerforWeb from "../../assets/image/Bannerforweb.png";
// import SportzchainBanner from "../../assets/image/Sportzchain_upcomming_Banner.png";
// import SportzchainBanner from "../../assets/Banner/IDO_Soldout_mob.png";
// import SportzchainBannerWeb from "../../assets/Banner/IDO_Soldout.png";
// import { Link } from "react-router-dom";


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 30,
    marginBottom: -5,
    width: "100%",
    height: "405px",
    display: "flex",
    justifyContent: "center",
    left: "39px",
    top: "1661px",
    filter: "drop-shadow(0px 10px 10px #E0E0E0)",
    [theme.breakpoints.down(376)]: {
      // marginLeft: "7px",
      display: "flex",
      alignItems: "center",
      height: "100%",
      marginTop: 30,
    },
    [theme.breakpoints.between(376, 394)]: {
      marginTop: 50,
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    [theme.breakpoints.between(394, 415)]: {
      marginTop: 30,
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
  },
  Image: {
    position: "absolute",
    padding: 80,
    [theme.breakpoints.down("xs")]: {
      padding: 25,
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: 60,
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "391px",
    height: "86px",
    left: "0px",
    top: "137px",
    marginTop: "1rem",
  },
  Heading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "26px",
    marginTop: "2rem",
    marginBottom: "1rem",
    whiteSpace: "nowrap",
    color: "#332233",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
  },
  detail: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "20px",
    color: "#332233",
    whiteSpace: "nowrap",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  img2: {
    [theme.breakpoints.down("xs")]: {
      height: "200px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "300px",
    },
  },
  tabHeader: {
    textAlign: "center",
    marginBottom: "1rem",
    marginTop: "5rem",
    fontSize: "40px",
    fontWeight: 800,
  },
}));

const UpcommingTab = () => {
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  return (
    <>
      <div className={classes.tabHeader}>Upcoming IDO</div>
      {Mobile ? (
        <div className={classes.container}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://airtable.com/shrWrtMtxSS6ZJ4hB"
          >
          <img src={MobileRocket} />
           
          </a>

            {/* <Link to="/launch-SportzchainIDO"> */}
            {/* </Link> */}

        </div>
      ) : (
        <div className={classes.container}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://airtable.com/shrWrtMtxSS6ZJ4hB"
          >
            <img src={BannerforWeb} className={classes.img2} />
          </a>
          

        </div>
      )}
    </>
  );
};

export default UpcommingTab;
