import React, { useEffect } from "react";
import CounterCard from "./Sub-Component/CounterCard";
import ActiveStake from "../../assets/image/CounterSection/ActiveStaking.png";
import TotalStake from "../../assets/image/CounterSection/TotalStaking2.png";
import TotalUser from "../../assets/image/CounterSection/TotalUser.png";
import TotalPartners from "../../assets/image/CounterSection/TotalPatner.png";
import { FadeShow, Counter2 } from "../../Animation/Counter";
import { motion } from "framer-motion";
import axios from "axios";

const CounterSection = () => {
  const [AciveStaking, setAciveStaking] = React.useState();
  console.log(AciveStaking);
  var raw = JSON.stringify({
    secret_key: "607806ddf20e8483b289cb3c245ec52f",
    api_id: "92878ff5ac678faa2c997ad3ad35c147",
    request_code: "72758a29ad3d516",
  });

  axios({
    method: "POST",
    url: "https://my.oropocket.com/restAPI/authentication.php",
    data: raw,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  })
    .then((res) => {
      console.log("response", res.data.token);
      localStorage.setItem("Token", res.data.token);
    })
    .catch((err) => {
      console.log("err", err);
    });

  // var myHeaders = new Headers();
  // myHeaders.append("Authorization", "Bearer {{token}}");
  // myHeaders.append("Content-Type", "application/json");

  // fetch("https://my.oropocket.com/restAPI/authentication.php", {
  //   method: "POST",
  //   headers: myHeaders,
  //   body: raw,
  //   redirect: "follow",
  // })
  //   .then((response) => response.json())
  //   .then((result) => localStorage.setItem("Token", result.token));
  useEffect(() => {
    const Token = localStorage.getItem("Token");
    TvlValue(Token);
    // setAciveStaking(numFormatter(17883843.587937));
  }, []);

  const TvlValue = async (Token) => {
    const url = "https://my.oropocket.com/restAPI/tvl_value.php";
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", "Bearer "+Token+"");
    // myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      request_code: "72758a29ad3d516",
    });

    await axios({
      method: "POST",
      url: url,
      data: raw,
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        console.log(res.data.tvl_value, "res");
        setAciveStaking(numFormatter(res.data.tvl_value));
      })
      .catch((err) => {
        console.log(err, "err");
      });

    // fetch("https://my.oropocket.com/restAPI/tvl_value.php", {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // })
    //   .then(response => response.json())
    //   .then(result => setAciveStaking(numFormatter(result.tvl_value)))
    //   .catch(error => console.log('error', error));
  };

  const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K";
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num < 900) {
      return num;
    }
  };
  return (
    <motion.div
      className="flex  sm:flex-wrap justify-between w-full"
      variants={Counter2}
      initial="initial"
      animate="animate"
    >
      <motion.div variants={FadeShow()}>
        <CounterCard
          url={ActiveStake}
          title="Active Staking"
          // total={"$" + "17M" + "+"}
          total={"$" + AciveStaking + "+"}
          width={120}
        />
      </motion.div>
      <motion.div variants={FadeShow()}>
        <CounterCard
          url={TotalStake}
          title="Total Staking"
          total="$100M+"
          width={110}
        />
      </motion.div>
      <motion.div variants={FadeShow()}>
        <CounterCard
          url={TotalUser}
          title="Total Users"
          total="10k+"
          width={120}
        />
      </motion.div>
      <motion.div variants={FadeShow()}>
        <CounterCard
          url={TotalPartners}
          title="Total Partners"
          total="100+"
          width={120}
        />
      </motion.div>
    </motion.div>
  );
};
export default CounterSection;
