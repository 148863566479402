import React from 'react';
import { ImageDiv, NetworkTitle, NetwrokDiv, NetwrokImage, NetwrokWrapper } from './network.style';
import Eth from '../../../assets/Setuimage/eth.png'
import Ave from '../../../assets/Setuimage/ave.png'
import Bnb from '../../../assets/Setuimage/bnb.png'
import Optism from '../../../assets/Setuimage/optism.png'
import Poly from '../../../assets/Setuimage/polygon.png'
import Fantom from '../../../assets/Setuimage/fantom.png'

const NetworkSupport=()=>{
    return(
       <NetwrokWrapper>
        <NetwrokDiv>
            <NetworkTitle>Networks Already Supported</NetworkTitle>
            <ImageDiv>
            <NetwrokImage src={Eth}/>
            <NetwrokImage src={Ave}/>
            <NetwrokImage src={Bnb}/>
            <NetwrokImage src={Optism}/>
            <NetwrokImage src={Poly}/>
            <NetwrokImage src={Fantom}/>
            </ImageDiv>
        </NetwrokDiv>
       </NetwrokWrapper>
    )
}
export default NetworkSupport