import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SubParticipate from "./SubParticipate";
import Schedule from "./Schedule";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, style, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={style}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100%",
    marginTop: "90px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "80px"
    },
  },
  myTab: {
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root": {
      background: "#F7F7F7 !important",
      padding: "7px 8px",
      color: "black",
      textTransform: "capitalize",
      borderRadius: 30,
    },
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected":
    {
      background:
        "linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%) !important",
      padding: "7px 8px",
      color: "white",
      textTransform: "capitalize",
      borderRadius: 30,
    },
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorPrimary": {
      maxWidth: 165,
      minWidth: 0,
      padding: "7px 8px",
      textTransform: "capitalize",
    },
  },
  indicator: {
    background: "transparent",
  },
}));

const Participate = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className={classes.Root}>
      <Tabs
        value={value}
        className={classes.myTab}
        classes={{ indicator: classes.indicator }}
        onChange={handleChange}
        aria-label="simple tabs example"
        centered
      >
        <Tab
          label="How to Participate"
          {...a11yProps(0)}
          style={{ fontFamily: "Inter" }}
        />
        <Tab
          label="Release Schedule"
          {...a11yProps(1)}
          style={{ fontFamily: "Inter" }}
        />
      </Tabs>
      <TabPanel value={value} index={0} style={{ marginTop: "1.5rem" }}>
        <SubParticipate />
      </TabPanel>
      <TabPanel value={value} index={1} style={{ marginTop: "1.5rem" }}>
        <Schedule />
      </TabPanel>
    </div>
  );
};

export default Participate;
