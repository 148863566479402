import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Participate from './Participate'
import Shedule from './Shedule'

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style:any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index,style, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={style}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent !important",
    marginTop: '5rem'
  },
  myTab: {
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root": {
      background: '#F7F7F7 !important',
      padding: '7px 8px',
      color: 'black',
      textTransform: 'capitalize',
      borderRadius: 30
    },
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected": {
      background: 'linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%) !important',
      padding: '7px 8px',
      color: 'white',
      textTransform: 'capitalize',
      borderRadius: 30
    },
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorPrimary": {
      maxWidth: 165,
      minWidth: 0,
      padding: '7px 8px',

      textTransform: 'capitalize'
    }

  },
  appbar: {
    borderRadius: 35,
    boxShadow: 'none',

    background: 'transparent',

  },
  indicator: {
    background: 'transparent'
  }
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Tabs value={value} className={classes.myTab} classes={{ indicator: classes.indicator }} onChange={handleChange} aria-label="simple tabs example" centered>
          <Tab label="How to Participate" {...a11yProps(0)} style={{fontFamily: "Inter"}}/>
          {/* <Tab label="Release Schedule" {...a11yProps(1)} /> */}
          <Tab label="Release Schedule" {...a11yProps(1)} style={{fontFamily: "Inter"}}/>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} style={{marginTop:'1.5rem'}}>
        <Participate />
      </TabPanel>
      <TabPanel value={value} index={1} style={{marginTop:'1.5rem'}}>
        <Shedule />
      </TabPanel>

    </div>
  );
}
