import React from "react";
import {
  MainDiv,
  ProjectContentWrap,
  ProjectImage,
  ProjectItemsWrap,
  ProjectTitle,
  ProjetContent,
} from "./project.style";

interface isProps{
    projectImage:string;
    projectTitle:string;
    projectContent:any;
}
const ProjectItems = ({projectImage,projectContent,projectTitle}:isProps) => {
  return (
    <ProjectItemsWrap>
      <MainDiv>
        <ProjectImage src={projectImage} />
        <ProjectContentWrap>
          <ProjectTitle>{projectTitle}</ProjectTitle>
          <ProjetContent>
            {projectContent}
          </ProjetContent>
        </ProjectContentWrap>
      </MainDiv>
    </ProjectItemsWrap>
  );
};
export default ProjectItems;
