import React, { useEffect, useState } from 'react'
import { Card } from 'antd';
import MyImage from './ImageSection';
import {motion}  from 'framer-motion'
import AOS from 'aos'
import { makeStyles,Theme} from '@material-ui/core/styles';
const useStyles = makeStyles((theme:Theme) => ({
    CardClass:{
        borderRadius:8,boxShadow:'14px 14px 40px 0px rgba(247, 247, 247, 1)',marginLeft:'10px',marginRight:'15px',
        height:280,
        width:380,
        [theme.breakpoints.down('xs')]:{
            height:'200px',
            width:'95% !important',
            margin:'0px auto !important',
            marginBottom:'1.5rem !important'
        },
        [theme.breakpoints.down('sm')]:{
            height:'250px',
            width:'100% ',
        },
        [theme.breakpoints.down('md')]:{
            height:'300px',
            width:340,
        }
    },
    CardImage:{
        display:'flex',
        justifyContent:'center'
    },
    fontFamily:{
        fontFamily:'Inter !important'
    }
  }));
interface IsMyCard{
    Url:any;
    Imagewidth:number;
    Title:string;
    Detail:string;
    margin:number
}
export  const MyCard = ({Url,Imagewidth,Title,Detail,margin}:IsMyCard) => {
    const classes=useStyles()
   useEffect(()=>{
       AOS.init()
   },[])
    const [Hover,setHover]=useState(false)
    return (
        // className={Hover?"border-gradient border-gradient-purple":null}
        <motion.div animate={{scale:Hover?'1.03':'1'}} >
       <Card data-aos="zoom-in" className={"desktop-card-width text-center sm:mobile-card-width sm:mb-4"+' '+classes.CardClass}  onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
            <div className={classes.CardImage}>
            <MyImage Url={Url} width={Imagewidth}  margin={margin}/>
            </div>
            <b style={{fontSize:18}} className={'sm:text-lg'+' '+classes.fontFamily}>{Title}</b>
            <p className={'mt-2 sm:text-sm'+' '+classes.fontFamily}style={{fontSize:15}} >{Detail}</p>
        </Card>
        </motion.div>
        
    )
}
