import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export default function ScrollableTabsButtonForce() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ maxWidth: 480, bgcolor: 'background.paper' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        <Tab label="About Project" style={{textTransform:'capitalize',marginRight:'0.5rem'}}/>
       
        <Tab label="How to Participate" style={{textTransform:'capitalize',marginRight:'0.5rem'}}/>
        <Tab label="Releas shedule" style={{textTransform:'capitalize',marginRight:'0.5rem'}}/>
       
      </Tabs>
    </Box>
  );
}
