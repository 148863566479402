import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
    marginBottom: "5.4rem",
    background: "#FFFFFF",
  },
  mainRoot: {
    width: "1132px",
    height: "170px",
    left: "154px",
    top: "1938px",
    background: "#FFFFFF",
    border: "1px solid #F0EBF8",
    boxSizing: "border-box",
    borderRadius: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "92%",
      height: "100%",
    },
  },
  TermsHead: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    marginBottom: "0.8rem",
  },
  Ptag2: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    marginBottom: "0.5rem",
    color: "#332233",
  },
}));
const Terms = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.mainRoot}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "2rem",
            flexDirection: "column",
          }}
        >
          <span className={classes.TermsHead}>Terms and conditions</span>

          <div>
            <div style={{ display: "flex", marginLeft: 7 }}>
              <div>
                <FiberManualRecordIcon
                  style={{ fontSize: "11px", marginTop: 0 }}
                />
              </div>
              &nbsp;
              <span className={classes.Ptag2}>
                Getting whitelisted will make you eligible but does not
                guarantee you an allocation
              </span>
            </div>
            <div style={{ display: "flex", marginLeft: 7 }}>
              <div>
                <FiberManualRecordIcon
                  style={{ fontSize: "11px", marginTop: 0 }}
                />
              </div>
              &nbsp;
              <span className={classes.Ptag2}>
                We will follow FCFS for the token sale.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Terms;
