import React from "react";
import Header from "../../component/GalleryBackend/Header";
import styled from "styled-components";
import TimeLine from "../../component/GalleryBackend/TimeLine";
import ImageIcon from "@mui/icons-material/Image";
import UploadImageModal from "../../component/GalleryBackend/UploadImageModal";
const Wrapper = styled.div`
  max-width: 750px;
  margin: 0 auto;
  margin-top: 50px;
`;
const HeadingCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #4527a0;
`;
const Form = styled.form`
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  margin-top: 40px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Column = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const Input = styled.input`
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  flex: 0.7;
  padding: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: #212121;
  :focus {
    outline: none;
  }
`;
const Label = styled.label`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #4527a0;
  flex: 0.3;
`;
const TextField = styled.textarea`
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  flex: 0.7;
  padding: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: #212121;
  height: 120px;
  :focus {
    outline: none;
  }
`;

const ImageField = styled.div<{ image?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  flex: 0.7;
  height: 140px;
  padding: 10px;
  position: relative;
  ${(props) =>
    props.image &&
    `
    background-image: url(${props.image});
    background-size: cover;
    background-position: center;
  `}
`;
const ChooseImageButton = styled.button`
  width: 100%;
  height: 40px;
  background: #e0e0e0;
  border-radius: 0px 0px 10px 10px;
  cursor: pointer;
  position: absolute;
  left: 0;
  bottom: 0;
`;
const MainButton = styled.button`
  background: #673ab7;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  margin: 20px auto;
  padding: 15px 90px;
  cursor: pointer;
`;
const StyledImageIcon = styled(ImageIcon)`
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 30px;
`;
const authToken =
  "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5qOEozakNNNGhKUWdiWHRGOXJPTSJ9.eyJpc3MiOiJodHRwczovL2Rldi0ta3EtdjgxNC51cy5hdXRoMC5jb20vIiwic3ViIjoiWGk3ZDUxQXZhSUdRUmRzMmx5R0Rqa3VRS0VWUGk4cEFAY2xpZW50cyIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6MzAwMSIsImlhdCI6MTY2MzgyNDg4NiwiZXhwIjoxNjY2NDE2ODg2LCJhenAiOiJYaTdkNTFBdmFJR1FSZHMybHlHRGprdVFLRVZQaThwQSIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.s9Q1EknsgbPt9juiXwVGPk3nHHaFC45BNBgUUnaSXSFcBz7xACHR3f4WNT5UJ53nDkjyMhgucnYmjJF7vDaEFyyaht--8J8ylHSbPj-2wov1xt3dHN-zVKJRKbOIsL2UTjiwpLfCd4rTZi38LFJwZeKpDsHtXaOR_elAhQ5qxtnsqM-Uj8-TAp21lRLoufH05zID-FgJ7PD605xolX7YZPSOilL9rReNZ11-JlI3k2DZ6Nwp-NAovqAODvXI1cWj4nduc16bYskRhgeoajFRsMOfSq2amu2eUYYNJ4-KPhOuFwaNOADE6N0eiaBb7fCizMi32vVzh7YuJhc0VXqaaQ";
function GalleryBackend() {
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [images, setImages] = React.useState<Array<Blob | MediaSource>>([]);

  function fetchData() {
    fetch("http://143.244.133.3:3001/api/meetup/update/8", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      body: JSON.stringify({
        meetupHeading: title,
        bodyText: description,
        meetupPlace: "placeholder",
        meetupTitle: "Kathmandu",
        meetupDate: "dd/mm/yyyy",
        meetupBodyText: "Kathmandu",
        meetupImage: images,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <Header />
      <Wrapper>
        <HeadingCont>
          <Title>Gallery Page</Title>
          <TimeLine />
        </HeadingCont>
        <Form>
          <Column>
            <Label>Top Heading</Label>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Column>
          <Column>
            <Label>Body Text</Label>
            <TextField
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Column>
          <Column>
            <Label>Banner Image</Label>
            <ImageField
              image={images.length > 0 && URL.createObjectURL(images[0])}
            >
              {images.length === 0 && <StyledImageIcon />}

              <UploadImageModal images={images} setImages={setImages}>
                <ChooseImageButton>Choose Image</ChooseImageButton>
              </UploadImageModal>
            </ImageField>
          </Column>
        </Form>
        <MainButton onClick={() => fetchData()}>SAVE & NEXT</MainButton>
      </Wrapper>
    </div>
  );
}

export default GalleryBackend;
