import React from "react";
interface IsImageItem {
  url: any;
  
}
const ImageItem = ({ url }: IsImageItem) => {
  return (
    <img
      src={url}
      className="mb-5 sm:w-mobile-image-width  sm:h-mobile-image-height"
      style={{height:'fit-content',width:'150px'}}
    />
  );
};
export default ImageItem;
