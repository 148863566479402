import React from 'react'

interface IsContain{
    url:any;
    title:any
}
const Contain=(props:IsContain)=>{
    return(
        <a href={props.url} target="_blank" rel="noreferrer" className="text-base leading-9 text-white sm:text-sm sm:leading-8" >{props.title}</a>
    )
}
export default Contain 