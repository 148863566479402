import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { UFARMPRICE } from '../../ApiRoot/UfarmPrice';
import Ufarm from '../../assets/image/Logo/u-small-logo2.png';
import Dfyn from '../../assets/image/MenuImages/Dfyn.png';
import Pancake from '../../assets/image/MenuImages/pancake.png';
import Uniswap from '../../assets/image/MenuImages/uniswap.png';
import Pangolin from '../../assets/image/MenuImages/pangolin.png';
import Mountain from "../../assets/image/MenuImages/mountain.png";
import { Skeleton } from 'antd';
const useStyles = makeStyles(() => ({
    projectClass: {
        background: 'linear-gradient(96.31deg, #CB218E 9.42%, #673AB7 100%)', padding: '1px', borderRadius: 18
    },
    AccourdingTypo: {
        width: '100%'
    },
    DetailRoot: {
        fontSize: '20px', fontWeight: 700
    },
    imageWidth: {
        width: 30, height: 30
    },
    ImageContainer: {
        width: '100%'
    }
}));
const Accordion = withStyles({
    root: {

        boxShadow: 'none',
        borderRadius: 18,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'transperant',

        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [Price,setPrice]=React.useState<number>()
    const classes = useStyles()
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    React.useEffect(()=>{
        fetch(UFARMPRICE,{
          method:'get'
        })
        .then(res=>res.json())
        .then(res=>setPrice(res.unifarm.usd))
        
      },[])
    return (
        <div className={"mb-1" + ' ' + classes.projectClass}>
            <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={classes.AccourdingTypo}>
                    <div className={'flex justify-between' + ' ' + classes.AccourdingTypo}>
                        <div className="flex items-center">
                            <img src={Ufarm} width={30} className="mr-2" />
                            <span className={classes.DetailRoot}>
                            {Price? <>$ {Price.toFixed(5)}</>:  <Skeleton.Input style={{ width: 70,height:30,borderRadius:10 }} active={true} size={'default'} />}
                            </span>
                        </div>
                        <div>
                            {expanded === 'panel1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={"flex justify-between items-center" + ' ' + classes.ImageContainer}>
                        <span className="text-lg font-heigh">Trade On</span>
                        &nbsp;
                        <div className="flex ">
                           <a href="https://app.uniswap.org/#/swap?inputCurrency=0x40986a85b4cfcdb054a6cbfb1210194fee51af88&outputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"> <img src={Uniswap} className={"mr-3" + ' ' + classes.imageWidth} /></a>
                            <a href="https://pancakeswap.finance/swap#/swap?outputCurrency=0x0A356f512f6fCE740111ee04Ab1699017a908680"><img src={Pancake} className={"mr-3" + ' ' + classes.imageWidth} /></a>
                            <a href="https://exchange.dfyn.network/#/swap?inputCurrency=0xa7305ae84519ff8be02484cda45834c4e7d13dd6&outputCurrency=0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97"><img src={Dfyn} className={"mr-3" + ' ' + classes.imageWidth} /></a>
                            <a href="https://app.pangolin.exchange/#/swap?inputCurrency=0xd60effed653f3f1b69047f2d2dc4e808a548767b"><img src={Pangolin} className={"mr-3" + ' ' + classes.imageWidth} /></a>
                            <a href="https://www.mexc.com/exchange/UFARM_USDT"><img src={Mountain} className={"mr-3" + ' ' + classes.imageWidth} /></a>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
