import React, { useEffect } from 'react'
import Wrapper from './SubComponent/Wrapper'
import Contain from './SubComponent/Contain'
import AOS from 'aos'
const Trade=()=>{
    useEffect(()=>{
        AOS.init({
            duretion:2000
        })
    })
    return(
       <div data-aos="fade-right">
        <Wrapper>
        <b className='text-lg leading-footer sm:text-lg sm:mt-2 ' style={{marginLeft:'-5px'}}>Trade $UFARM</b>
       <Contain url="https://app.uniswap.org/#/swap?inputCurrency=0x40986a85b4cfcdb054a6cbfb1210194fee51af88&outputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2" title="Ethereum"/>
       <Contain url="https://exchange.dfyn.network/#/swap?inputCurrency=0xa7305ae84519ff8be02484cda45834c4e7d13dd6&outputCurrency=0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97" title="Polygon"/>
       <Contain url="https://app.pangolin.exchange/#/swap?inputCurrency=0xd60effed653f3f1b69047f2d2dc4e808a548767b" title="Avalanche"/>
       <Contain url="https://pancakeswap.finance/swap#/swap?outputCurrency=0x0A356f512f6fCE740111ee04Ab1699017a908680" title="Binance Smart Chain"/>
       <Contain url="https://blog.unifarm.co/how-to-earn-ufarm-while-playing-hypercasual-games-on-ragefan/" title="Play2Earn"/>
    </Wrapper>
       </div>
    )
}
export default Trade