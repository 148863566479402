// import { DateRangeSharp } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import Sectiontitle from "../Sectiontitle/index";
import CaseStudyCard from "./SubComponent/CaseStudyCard";

// import { Card } from "antd";
// import { makeStyles, Theme } from "@material-ui/core/styles";
import Cs1 from "../../assets/image/CaseStudy/cs1.png";
import Cs2 from "../../assets/image/CaseStudy/cs2.png";
import Cs3 from "../../assets/image/CaseStudy/cs3.png";

// const useStyles = makeStyles((theme: Theme) => ({
//   IpadCard: {
//     [theme.breakpoints.down("sm")]: {
//       width: "100% !important",
//       height: "300px !important",
//     },
//   },
// }));

const DashboardSlider = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    GetBlog();
  }, []);
  const GetBlog = () => {
    fetch("https://blog.unifarm.co/wp-json/wp/v2/posts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((result) => { console.log(result), setData((oldArray) => [...oldArray, ...result]) });
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay:true
  };

  return (
    <div style={{ marginTop: '6rem' }}>
      <Sectiontitle title="Case Studies" />
      {
        data.length > 0 ? <Slider {...settings}>
          {data.map(
            (datas, index) =>
              index < 3 && (
                <>
                  {console.log(datas.yoast_head_json.og_image[0].url)}
                  <div className="flex justify-around pt-5 pb-5">
                    <a href={datas.link} key={index} >
                      <CaseStudyCard
                        Url={datas.yoast_head_json.og_image[0].url}
                        Imagewidth={250}
                        Title={datas.title.rendered}
                      />
                    </a>
                  </div>
                </>
              )
          )

          }
        </Slider>
          :
          <Slider {...settings}>
            <div className="flex justify-around pt-5 pb-5">
              <a href="#"  >
                <CaseStudyCard
                  Url={Cs1}
                  Imagewidth={250}
                  Title={""}
                />
              </a>
              <a href="#">
                <CaseStudyCard
                  Url={Cs2}
                  Imagewidth={250}
                  Title="#"
                />
              </a>
              <a href="#" >
                <CaseStudyCard
                  Url={Cs3}
                  Imagewidth={250}
                  Title=""
                />
              </a>
            </div>
          </Slider>
      }


    </div>
  );
};
export default DashboardSlider;
{
  /* <div style={{ display: "flex" }}>
        {data.map(
          (datas, index) =>
            index < 3 && (
              <div key={index}>
                <Card
                  data-aos="zoom-in"
                  className={classes.IpadCard + " " + "Ant-card-new"}
                >
                  <img
                    src={data[0].yoast_head_json.og_image[0].url}
                    alt="hllo"
                  />
                  <div className="ml-5 mt-4">
                    <b className="common-fontFamily">
                      {data[0].title.rendered}
                    </b>
                  </div>
                </Card>
              </div>
            )
        )}
      </div> */
}

{
  /*
          <CaseStudyCard
            Url={Cs2}
            Imagewidth={250}
            Title="User Earns 2552.669% APY with UniFarm Cohort 12"
          />
          <CaseStudyCard
            Url={Cs3}
            Imagewidth={250}
            Title="User Earns 1229.60% APY with UniFarm Cohort 3"
          />
        </div>
        <div className="flex justify-around pt-5 pb-5">
          <CaseStudyCard
            Url={Cs1}
            Imagewidth={250}
            Title="User Earns 6716.505% APY with UniFarm Cohort 11"
          />
          <CaseStudyCard
            Url={Cs2}
            Imagewidth={250}
            Title="User Earns 2552.669% APY with UniFarm Cohort 12"
          />
          <CaseStudyCard
            Url={Cs3}
            Imagewidth={250}
            Title="User Earns 1229.60% APY with UniFarm Cohort 3"
          />
        </div>
      </Slider> */
}
