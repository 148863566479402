import React from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
// import Report from "../../../assets/Banner/Setu_Audit_Report.pdf";

const Resource = () => {
  const menu = (
    <Menu style={{ borderRadius: 15 }}>
      <Menu.Item key="0">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://blog.unifarm.co/"
          className="leading-9 text-base "
        >
          Blog
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.unifarm.co/"
          className="leading-9 text-base"
        >
          Whitepaper
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a
          target="_blank"
          rel="noreferrer"
          href="http://unifarm.career.co/"
          className="leading-9 text-base"
        >
          Careers
        </a>
      </Menu.Item>
      {/* <Menu.Item key="1" disabled>
            <a href="#" className='leading-9 text-base'>Knowledge Hub</a>
          </Menu.Item> */}
      <Menu.Item key="3">
        <Link to="/faq" className="leading-9 text-base">
          FAQs
        </Link>
      </Menu.Item>
      <Menu.Item key="4">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://github.com/Quillhash/QuillAudit_Reports/blob/master/Unifarm_Bridge_Smart_Contract_Audit_Report_QuillAudits.pdf"
          className="leading-9 text-base"
        >
          Setu Audit Report
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a
        className="ant-dropdown-link text-white hover:text-white flex items-center text-base"
        onClick={(e) => e.preventDefault()}
      >
        Resources &nbsp; <DownOutlined />
      </a>
    </Dropdown>
  );
};
export default Resource;
