import React from "react";
import Card from "@mui/material/Card";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Divider from "@material-ui/core/Divider";

import Button from "@mui/material/Button";
import SucsessReport from "./SucessReport";
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles((theme) => ({
  CardRoot: {
    width: "431px",
    height: "310px",
    borderRadius: "20px",
    padding: 25,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  HeaderDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  SpanText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
  },
  CloseIcone: {
    color: "black",
  },
  Devider: {
    height: 1.4,
    background: "#212121",
  },
  DetailWrapper: {
    marginTop: "1.5rem",
    textAlign: "center",
  },
  InfoDiv: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "1.5rem",
  },
  ReportButton: {
    background: "#673AB7;",
    boxShadow: "0px 7px 18px -2px rgba(103, 58, 183, 0.56)",
    borderRadius: "10px",
    marginTop: "1.5rem",
    width: "380px",
    height: "50px",
    textTransform: "none",
    fontWeight: 500,
    fontSize: "15px",
    "&:hover": {
      background: "#673AB7;",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      background: "#673AB7",
      boxShadow: "0px 7px 18px -2px rgba(103, 58, 183, 0.56)",
      borderRadius: "10px",
    },
  },
  TextField: {
    "&>.Mui-focused": {
      color: "#673AB7",
    },
    "&>.MuiInput-underline:after": {
      borderBottom: "2px solid #673AB7 !important",
    },
  },
}));

interface IsReport {
  CloseClick: () => void;
  ReportName: string;
}

const Report = ({ CloseClick, ReportName }: IsReport) => {
  const classes = useStyles();

  const [ReportSubmit, setSubmit] = React.useState(false);
  const [Isloading, setLoding] = React.useState(false);

  //   const Submit = () => {
  //     setLoding(true);
  //     setTimeout(() => {
  //       setLoding(false);
  //       setSubmit(true);
  //     }, 1500);
  //   };
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    secret_key: "607806ddf20e8483b289cb3c245ec52f",
    api_id: "92878ff5ac678faa2c997ad3ad35c147",
    request_code: "72758fa297ad3d3516",
  });

  fetch("https://my.oropocket.com/restAPI/authentication.php", {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((result) => localStorage.setItem("EmailToken", result.token))
    .catch((error) => console.log("error", error));

  const handleApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("EmailToken") + ""
    );
    myHeaders.append("Content-Type", "text/plain");

    var raw = `{"request_code":"72758a29ad3d3516","search_str":"${ReportName}","platform":"gmail"}`;

    fetch("https://my.oropocket.com/restAPI/report_contact.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        setLoding(true);
        setTimeout(() => {
          setLoding(false);
          setSubmit(true);
        }, 1500);
      });
  };

  return (
    <>
      {ReportSubmit ? (
        <SucsessReport CloseModal={CloseClick} />
      ) : (
        <Card className={classes.CardRoot}>
          <div className={classes.HeaderDiv}>
            <span className={classes.SpanText}>Report Account</span>
            <IconButton className={classes.CloseIcone} onClick={CloseClick}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider className={classes.Devider} />
          <div className={classes.DetailWrapper}>
            The&nbsp;<b>{ReportName}</b> you inquired is not an official team
            member of UniFarm.
            <div className={classes.InfoDiv}>
              Please submit the information about the profile or link you want
              to report.
            </div>
            <Button
              onClick={handleApi}
              variant="contained"
              className={classes.ReportButton}
            >
              {Isloading ? (
                <>
                  <ClipLoader size={20} color={"white"} /> &nbsp;&nbsp;Submit
                </>
              ) : (
                <>&nbsp;&nbsp;Submit</>
              )}
            </Button>
          </div>
        </Card>
      )}
    </>
  );
};
export default Report;
