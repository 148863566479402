import React from "react";
import Slider from "react-slick";
import ImageWrapper from "./Imagewrapper";
// import ImageItem from "./ImageItem";
import Mobilebanner_1 from "../../../assets/Banner/MobileBanner/Mobile_1.png"
import Mobilebanner_2 from "../../../assets/Banner/MobileBanner/Mobile_2.png"
import Mobilebanner_3 from "../../../assets/Banner/MobileBanner/Mobile_3.png"
import Mobilebanner_4 from "../../../assets/Banner/MobileBanner/Mobile_4.png"
import BannerItem from "./BannerItem";

// import APYswap from "../../../assets/Logos/Partner/APYswap.png";
// import bitcash from "../../../assets/Logos/Partner/bitcash.png";
// import Bluzelle from "../../../assets/Logos/Partner/Bluzelle.png";
// import busd_logo_black from "../../../assets/Logos/Partner/busd_logo_black.png";
// import CANNUMO from "../../../assets/Logos/Partner/CANNUMO.png";
// import Centaur from "../../../assets/Logos/Partner/Centaur.png";
// import coinswap from "../../../assets/Logos/Partner/coinswap.png";
// import Collateral from "../../../assets/Logos/Partner/Collateral.png";

// import Creator from "../../../assets/Logos/Partner/Creator.png";
// import De_Hive from "../../../assets/Logos/Partner/De_Hive.png";
// import Fortube from "../../../assets/Logos/Partner/Fortube.png";
// import Hedget from "../../../assets/Logos/Partner/Hedget.png";
// import heshfi from "../../../assets/Logos/Partner/heshfi.png";
// import ispolink from "../../../assets/Logos/Partner/ispolink.png";
// import kommunitas from "../../../assets/Logos/Partner/kommunitas.png";
// import matic from "../../../assets/Logos/Partner/matic.png";

const MobileSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const MobileBanner =[
    Mobilebanner_1,
    Mobilebanner_2,
    Mobilebanner_3,
    Mobilebanner_4,
  ]

  // const [Data, setData] = React.useState([]);

  // const listToMatrix = (list, elementsPerSubArray) => {
  //   var matrix = [],
  //     i,
  //     k;

  //   for (i = 0, k = -1; i < list.length; i++) {
  //     if (i % elementsPerSubArray === 0) {
  //       k++;
  //       matrix[k] = [];
  //     }

  //     matrix[k].push(list[i]);
  //   }

  //   return matrix;
  // };

  // React.useEffect(() => {
  //   const Token = localStorage.getItem("Token");
  //   if (Token) {
  //     var myHeaders = new Headers();
  //     myHeaders.append(`Authorization`, `Bearer ${Token}`);
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //       request_code: "72758fa29ad3d3516",
  //       platform: "unifarm",
  //     });
  //     fetch("https://my.oropocket.com/restAPI/partnership.php", {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     })
  //       .then((response) => response.json())
  //       .then((result) => {
  //         setData(result.data.partnership);
  //       })
  //       .catch((error) => console.log("error", error));
  //   }
  // }, []);

  // var myHeaders = new Headers();
  // myHeaders.append("Content-Type", "application/json");

  // var raw = JSON.stringify({
  //   secret_key: "607806ddf20e8483b289cb3c245ec52f",
  //   api_id: "92878ff5ac678faa2c997ad3ad35c147",
  //   request_code: "72758fa29ad3d3516",
  // });

  // fetch("https://my.oropocket.com/restAPI/authentication.php", {
  //   method: "POST",
  //   headers: myHeaders,
  //   body: raw,
  //   redirect: "follow",
  // })
  //   .then((response) => response.json())
  //   .then((result) => localStorage.setItem("Token", result.token))
  //   .catch((error) => console.log("error", error));

  return (
    <div>
      <Slider {...settings}>
        {
          MobileBanner.map((dt,index) => {
            return(
              <div className="flex flex-col justify-between"  key={index}
              style={{ width: "100px" }}>

                <ImageWrapper key={index}>
                  {/* <ImageItem style={{height:'fit-content',width:'100%'}} url={dt} key={index} /> */}
                   {/* <img src={dt} key={index} className="mb-5 sm:mobile-projectPartnetBanner-width  sm:h-mobile-image-height" />  */}
                    <BannerItem url={dt} key={index}  />
                </ImageWrapper>

              </div>
            )

          })
        }


        {/* {listToMatrix(Data, 4).map((dt, index) => {
          return (
            <div
              className="flex flex-col justify-between"
              key={index}
              style={{ width: "100px" }}
            >
              <ImageWrapper key={index}>
                {dt.slice(0, 2).map((dt2, index2) => {
                  return (
                    <ImageItem
                      url={
                        "https://my.oropocket.com/uploads/partnership/" +
                        dt2.logo
                      }
                      key={index2}
                    />
                  );
                })}
              </ImageWrapper>
              <ImageWrapper>
                {dt.slice(2, 4).map((dt2, index2) => {
                  return (
                    <ImageItem
                      url={
                        "https://my.oropocket.com/uploads/partnership/" +
                        dt2.logo
                      }
                      key={index2}
                    />
                  );
                })}
              </ImageWrapper>
            </div>
          );
        })} */}
      </Slider>
      {/* <Slider {...settings}>
        <div className="flex flex-col justify-between">
          <ImageWrapper>
            <ImageItem url={APYswap} />
            <ImageItem url={bitcash} />
          </ImageWrapper>
          <ImageWrapper>
            <ImageItem url={Bluzelle} />
            <ImageItem url={busd_logo_black} />
          </ImageWrapper>
        </div>
        <div className="flex flex-col justify-between">
          <ImageWrapper>
            <ImageItem url={CANNUMO} />
            <ImageItem url={Centaur} />
          </ImageWrapper>
          <ImageWrapper>
            <ImageItem url={coinswap} />
            <ImageItem url={Collateral} />
          </ImageWrapper>
        </div>
        <div className="flex flex-col justify-between">
          <ImageWrapper>
            <ImageItem url={Creator} />
            <ImageItem url={De_Hive} />
          </ImageWrapper>
          <ImageWrapper>
            <ImageItem url={Fortube} />
            <ImageItem url={Hedget} />
          </ImageWrapper>
        </div>
        <div className="flex flex-col justify-between">
          <ImageWrapper>
            <ImageItem url={heshfi} />
            <ImageItem url={ispolink} />
          </ImageWrapper>
          <ImageWrapper>
            <ImageItem url={kommunitas} />
            <ImageItem url={matic} />
          </ImageWrapper>
        </div>
      </Slider> */}
    </div>
  );
};
export default MobileSlider;
