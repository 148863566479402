import styled from "styled-components";
export const Element1Wrapper = styled.div`
  position: absolute;
  top: 48px;
  left: 0px;
  @media (max-width: 430px) {
    top: 149px;
    left: -24px;
  }
`;
export const Elament1Imge = styled.img`
  width: 312px;
  @media (max-width: 430px) {
    width:200px
  }
`;
export const Element2Wrapper = styled.div`
  position: absolute;
  top: 356px;
  right: 0;
  z-index: -111;
  @media (max-width: 430px) {
    position: absolute;
    top: 500px;
    right: 0;
    z-index: -111111111;
  }
`;
export const Elament2Imge = styled.img`
  width: 468px;
  @media (max-width: 430px) {
    width:160px;
  }
`;

export const Element3Wrapper = styled.div`
  position: absolute;
  top: 972px;
  left: -35px;
  z-index: -111;
  @media (max-width: 430px) {
    position: absolute;
    top: 910px;
    left: 0;
    z-index: -111;
  }
`;
export const Elament3Imge = styled.img`
  width: 300px;
  @media (max-width: 430px) {
    width: 136px;
  }
`;

export const Element4Wrapper = styled.div`
  position: absolute;
  top: 1382px;
  right: 0;
  z-index: -111;
  @media (max-width: 430px) {
    position: absolute;
    top: 1653px;
    right: 0;
    z-index: -111;
  }
`;
export const Elament4Imge = styled.img`
  width: 300px;
  @media (max-width: 430px) {
    width:175px;
  }
`;
