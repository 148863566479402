import React from "react";
import Card from "@mui/material/Card";
import M_img from "../../../assets/Element/M_img.png";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  CardRoot: {
    width: "400px",
    height: "390px",
    borderRadius: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  ReportButton: {
    background: "#009F42",
    boxShadow: "0px 7px 18px -2px rgba(0, 200, 83, 0.5)",
    borderRadius: "10px",
    width: "380px",
    height: "50px",
    textTransform: "none",
    fontWeight: 500,
    fontSize: "15px",
    "&:hover": {
      background: "#009F42",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      background: "#009F42",
      boxShadow: "0px 7px 18px -2px rgba(0, 200, 83, 0.5)",
      borderRadius: "10px",
    },
  },
  imageDiv: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
  ReportHeaing: {
    textAlign: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    marginTop: "7px",
  },
  ReportText: {
    textAlign: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  ButtonDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  },
  ContentDiv: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "CENTER",
      marginTop: "6PX",
    },
  },
  details_div: {
    textAlign: "center",
    marginTop: "10px",
  },
}));

interface isFound {
  Click: () => void;
  Name: string;
}

const Found = ({ Click, Name }: isFound) => {
  const classes = useStyles();
  return (
    <Card className={classes.CardRoot}>
      <div className={classes.imageDiv}>
        <div>
          <img src={M_img} alt="img" />
        </div>
        <div className={classes.details_div}>
          <b>{Name}</b>&nbsp;is an official team member of UniFarm.
        </div>
        <div className={classes.details_div}>
          Note: To protect your assets, do not interact with accounts from
          unofficial UniFarm channels or who pretend to be UniFarm team members.
        </div>
        {/* <div>
          <p className={classes.ReportHeaing}>
            <b>{Name}</b> is an official team member of UniFarm.
          </p>
        </div>
        <div className={classes.ContentDiv}>
          <span className={classes.ReportText}>
            Note: To protect your assets, do not interact with accounts from
            unofficial UniFarm channels or who pretend to be UniFarm team
            members.
          </span>
        </div> */}
        <div className={classes.ButtonDiv}>
          <Button
            onClick={Click}
            variant="contained"
            className={classes.ReportButton}
          >
            Okay
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default Found;
