import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  mobileSection: {
    marginTop: "1rem",
    marginBottom: "1rem",
    fontWeight: 900,
    fontFamily: "'Nexa', sans-serif",
    [theme.breakpoints.down("xs")]: {
      fontSize: "28px !important",
      marginBottom: "1rem",
    },
  },
}));

interface IsTitle {
  title: string;
}
const SectionTitle = (props: IsTitle) => {
  const classes = useStyles();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div >
      
      <div
        data-aos="fade-right"
        className={
          classes.mobileSection + " " + "text-4xl text-center font-heigh "
        }
      >
        {props.title}
      </div>
    </div>
  );
};
export default SectionTitle;
