import React from "react";
import {
  ItemContent,
  ItemImage,
  ItemTitle,
  UserContentWrap,
  UserItemWrap,
} from "./Users.style";

interface isProps{
    image:string;
    title:any;
    content:any;
}
const UserItem = ({image,title,content}:isProps) => {
  return (
    <UserItemWrap>
      <ItemImage src={image} />
      <UserContentWrap>
        <ItemTitle>{title}</ItemTitle>
        <ItemContent>
          {content}
        </ItemContent>
      </UserContentWrap>
    </UserItemWrap>
  );
};
export default UserItem;
