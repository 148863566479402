import { Container } from "@mui/material";
import React, { useRef } from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
// import Footer from "../../component/Setu/Footer";
// import Header from "../../component/Setu/Header/Header";
import HeroSection from "../../component/Setu/Herosection";
import NetworkSupport from "../../component/Setu/NetwrokSupport";
import ProjectSection from "../../component/Setu/Project";
import Users from "../../component/Setu/Users";
import {
  Elament1Imge,
  Elament2Imge,
  Elament3Imge,
  Elament4Imge,
  Element1Wrapper,
  Element2Wrapper,
  Element3Wrapper,
  Element4Wrapper,
} from "./Style";
import Element1 from "../../assets/Setuimage/element/element1.png";
import Element2 from "../../assets/Setuimage/element/element2.png";
import Element3 from "../../assets/Setuimage/element/element3.png";
import Element4 from "../../assets/Setuimage/element/element4.png";
import MobileElemen1 from "../../assets/Setuimage/mobile/element2.png";
import MobileElemen2 from "../../assets/Setuimage/mobile/element3.png";
import MobileElemen3 from "../../assets/Setuimage/mobile/element4.png";
import { useMobile } from "../../component/Setu/MuiMediaQuery/MuiMediaQuery";
const SetuLandingPage = () => {
  const mobile = useMobile();
  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      left: ref.current.offsetTop,
      behavior: "smooth",
    });
  // const scrollToRef2 = (ref) =>
  //   window.scrollTo({
  //     top: ref.current.offsetTop,
  //     left: ref.current.offsetTop,
  //     behavior: "smooth",
  //   });
  const scrollToRef3 = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      left: ref.current.offsetTop,
      behavior: "smooth",
    });
  const scrollToRef4 = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      left: ref.current.offsetTop,
      behavior: "smooth",
    });
  const myRef = useRef(null);
  // const myRef2 = useRef(null);
  const myRef3 = useRef(null);
  const myRef4 = useRef(null);

  const executeScroll = () => {
    scrollToRef(myRef);
  };
  // const executeScroll2 = () => scrollToRef2(myRef2);
  const executeScroll3 = () => scrollToRef3(myRef3);
  const executeScroll4 = () => scrollToRef4(myRef4);
  return (
    <>
      <Container maxWidth="xl" style={{ position: "relative" }}>
        <Header ClickHeader={() => console.log("click main")} />

        <Container
          maxWidth="lg"
          style={{ marginTop: "3rem", paddingRight: mobile ? "0rem" : null }}
        >
          <Element1Wrapper>
            <Elament1Imge src={Element1} />
          </Element1Wrapper>
          <HeroSection />
          <Element2Wrapper>
            <Elament2Imge src={mobile ? MobileElemen1 : Element2} />
          </Element2Wrapper>
          <Element3Wrapper>
            <Elament3Imge src={mobile ? MobileElemen2 : Element3} />
          </Element3Wrapper>
          <Users />
        </Container>
      </Container>
      <NetworkSupport />
      <Element4Wrapper>
        <Elament4Imge src={mobile ? MobileElemen3 : Element4} />
      </Element4Wrapper>
      <Container maxWidth="xl">
        <Container
          maxWidth="lg"
          style={{ marginBottom: "3rem", paddingRight: mobile ? "0rem" : null }}
        >
          <ProjectSection />
        </Container>
        <Footer
          Investor={executeScroll}
          IpadClick={executeScroll}
          RoadMapClick2={executeScroll3}
          RoadMapClickIpad={executeScroll3}
          WhyUnifarmClick2={executeScroll4}
          WhyUnifarmClickIpad={executeScroll4}
          isInnerPage={false}
        />
      </Container>
    </>
  );
};
export default SetuLandingPage;
