import React, { useEffect } from "react";
import Sectiontitle from "../Sectiontitle/index";
import MySlider from "./SubComponent/Slider";
import AOS from "aos";
const Mention = () => {
  useEffect(() => {
    AOS.init({
      duration: 1800,
    });
  }, []);
  return (
    <div style={{marginTop:"3rem"}}>
      <Sectiontitle title="Mentions" />
      <div data-aos="fade-right">
        <MySlider />
      </div>
    </div>
  );
};
export default Mention;
