import React from 'react'
import Mouse from '../../assets/image/ExploreSection/Mouse.png'
import Pr from '../../assets/image/ExploreSection/Pr.png'
import Scale from '../../assets/image/ExploreSection/Scale.png'

import { MyCard } from './Subcomponent/Card'
import { makeStyles } from '@material-ui/core/styles';
// import { useTheme } from '@material-ui/core'
// import { useMediaQuery } from '@material-ui/core'
// import IpadExploreSection from './Ipad/User'
import FlexContainer from '../Flexcontainer'
// import MobileUser from './Mobile/User'
const useStyles = makeStyles(() => ({
    projectClass: {
        width: '100%',
        "&>.slick-slider .slick-list": {
            paddingTop: '0.5rem !important',
            paddingBottom: '1rem !important'
        },
        "&>.slick-slider slick-dots": {
            paddingBottom: '0.5rem !important'
        }
    }
}));
const ExploreSection = () => {
    const classes = useStyles()
    // const theme = useTheme()
    // const Ipad = useMediaQuery(theme.breakpoints.between('sm', 'md'))
    // const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
    const openApp = () => {
        window.open('https://app.unifarm.co/')

    }
    return (
        <div className={"section-margin" + ' ' + classes.projectClass}>
           
                        <FlexContainer>
                            <MyCard
                                Url={Pr}
                                margin={3}
                                Imagewidth={90}
                                Title="Minimum Guaranteed APY"
                                Detail="Earn a minimum APY of 36% on all cohorts. This gives the stakers surety of earning good yield on their tokens."
                            />


                            <MyCard
                                Url={Scale}
                                margin={3}
                                Imagewidth={50}
                                Title="One place to Farm"
                                Detail="UniFarm enables you to farm tokens in one place. Users no longer have to go on various platforms or exchanges to farm different tokens."
                            />
                            <MyCard
                                Url={Mouse}
                                margin={4}
                                Imagewidth={90}
                                Title="Easy to Use"
                                Detail="UniFarm offers a simple user interface with a great user experience offering one-click stake and unstake facility on a completely decentralized platform."
                            />
                        </FlexContainer>
          <div className="flex justify-center mt-5 mb-5">
                <button className="Submit-btn" onClick={openApp}>Open App</button>
            </div>
        </div>
    )
}
export default ExploreSection