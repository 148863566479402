import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LGX from "../../../assets/Ido/LGX.png";
import Tokenomics from "../../../assets/Ido/Tokenomics.png";

const useStyles = makeStyles((theme) => ({
  Grid: {
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "20px",
    },
  },
  Main_Card: {
    width: "100%",
    height: "100%",
    background: "#ffffff",
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    padding: "20px",
  },
  Title_div: {
    display: "flex",
    flexDirection: "column",
  },
  Card_title: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#212121",
  },
  Card_text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#332233",
    marginTop: "10px",
  },
  Img_div: {
    marginTop: "30px",
  },
}));

const Schedule = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item lg={6} xs={12} className={classes.Grid}>
          <Card className={classes.Main_Card}>
            <div className={classes.Title_div}>
              <span className={classes.Card_title}>$LGX IDO Token Unlock</span>
              <span className={classes.Card_text}>
                10% on TGE month, 15% till 6 months
              </span>
            </div>
            <div className={classes.Img_div}>
              <img src={LGX} alt="LGX" />
            </div>
          </Card>
        </Grid>
        <Grid item lg={6} xs={12} className={classes.Grid}>
          <Card className={classes.Main_Card}>
            <div className={classes.Title_div}>
              <span className={classes.Card_title}>$LGX IDO Token Unlock</span>
            </div>
            <div className={classes.Img_div}>
              <img src={Tokenomics} alt="LGX" />
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Schedule;
