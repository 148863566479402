import React, { useEffect } from "react";
import Sectiontitle from "../Sectiontitle/index";
import MySlider from "./SubComponent/Slider";
import AOS from "aos";

const Investor = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div  >
      <Sectiontitle title="Investors" />
      <div data-aos="fade-right">
        <MySlider />
      </div>
    </div>
  );
};
export default Investor;
