import React from 'react'
import SectionTeam from '../TeamSectionTitle/index'
import CEOData from './Data'
const CEO=()=>
{
    return(
        <>
          <SectionTeam
             title="Hey, there!"
             description='A word from our CEO'
          />
          <CEOData/>
        </>
    )
}
export default CEO