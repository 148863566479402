import React from 'react'
import TeamSction from '../TeamSectionTitle/index'
import Card from './Card'
import { makeStyles, Theme } from "@material-ui/core/styles";
import one from '../../../assets/image/team/value/1.png'
import two from '../../../assets/image/team/value/2.png'
import three from '../../../assets/image/team/value/3.png'
import four from '../../../assets/image/team/value/4.png'
import five from '../../../assets/image/team/value/5.png'
import six from '../../../assets/image/team/value/6.png'
const useStyles = makeStyles((theme: Theme) => ({
    CardRoot: {
        display: 'flex',
        flexFlow: 'wrap',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('xs')]: {

        }
    }
}))
const Values = () => {
    const classes = useStyles()
    return (
        <>
            <TeamSction 
            title='Values'
            description='How you do anything is how you do everything.'
            />
            <div className={classes.CardRoot}>
                <Card
                    title="Think actively"
                    description='Question processes. Give thoughtful responses. Find the best way instead of going by how it’s always been done.'
                   url={one}
                />
                <Card
                    title="Be super helpful"
                    description='Go above and beyond to solve problems. Exercise empathy. Deliver magic to customers and coworkers.'
                    url={two}
                />
                <Card
                    title="Act with humility"
                    description='Treat everyone with respect. Leave egos at the door.'
                    url={three}
                />
                 <Card
                    title="Appreciate quality"
                    description='Take pride in crafting a lasting product. Work with the best equipment. Invest in great things that improve your life.'
                    url={four}
                />
                 <Card
                    title="Maximize efficiency"
                    description='Minimize unnecessary meetings and management. Work together with others to get the job finished.'
                    url={five}
                />
                 <Card
                    title="Focus on product"
                    description='Ask yourself how the product can be better today than it was yesterday.'
                    url={six}
                />
               
            </div>
        </>
    )
}
export default Values