import React, { useLayoutEffect } from "react";
import Hero_Img2 from "../../../assets/image/legionpcbanner.png";
// import Mobile_hero from "../../../assets/Ido/Mobile_hero.png";
import Mobile_hero2 from "../../../assets/image/legionmobbanner.png";
// import Mobile_root from "../../../assets/Ido/Mobile_root.png";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../Header";
import Container from "@material-ui/core/Container";
import About from "./About";
import AccordionSale from "./AccordionSale";
import Participate from "./Participate";
import Terms from "../Gameyoo/Terms";
import BackHome from "./BackHome";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100vh",
    position: "absolute",
    backgroundImage: `url(${Hero_Img2})`,
    backgroundSize: "cover",
  },
  MobileRoot: {
    width: "100%",
    height: "100vh",
    position: "absolute",
    background: "#494949",
    // backgroundImage: `url(${Mobile_hero})`,
    // backgroundSize: "contain",
    // backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
      marginTop: "50px",
    },
  },
  Mobile_img: {
    width: "100%",
    height: "500px",
    marginTop: "75px",
  },
  Main_div: {
    width: "100%",
    height: "100vh",
    position: "relative",
    top: 0,
    left: 0,
    paddingTop: "1px",
    [theme.breakpoints.down("xs")]: {
      height: "80vh",
    },
  },
  Title_div: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    paddingTop: "330px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "80px",
    },
  },
  Title: {
    fontSize: "48px",
    fontWeight: 900,
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "58px",
    color: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
      lineHeight: "36px",
    },
  },
  Date: {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#ffffff",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      marginTop: "30px",
    },
  },
}));

const LegioNetwork = () => {
  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      {Mobile ? (
        <div className={classes.MobileRoot}>
          <img src={Mobile_hero2} className={classes.Mobile_img} />
          {/* <img src={Mobile_root} style={{ marginTop: "80px", height: "400px", opacity: 0.2 }} /> */}
        </div>
      ) : (
        <div className={classes.Root}></div>
      )}
      <div className={classes.Main_div}>
        <Container maxWidth="lg">
          <Header ClickHeader={() => null} />
          <div className={classes.Title_div}>
            <span className={classes.Title}>Legion Network IDO</span>
            <span className={classes.Date}>Date: 18/04/2022</span>
          </div>
        </Container>
      </div>
      <About />
      <AccordionSale />
      <Participate />
      <Terms />
      <BackHome />
    </div>
  );
};

export default LegioNetwork;
