import React from 'react'
interface IsContainer{
    children:React.ReactNode
}
const FlexContainer=(props:IsContainer)=>{
    return(
        <div className="flex justify-between " >
          {props.children}
        </div>
    )
}
export default FlexContainer