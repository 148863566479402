import { Variants } from "framer-motion";
export const HeaderWrapper:Variants={
    initial:{
        y:-1000
    },
    animate:{
        y:0,
        transition:{
             delay:0.2,
             duration:0.2,
             type:'spring'
        }
    }
}