import React from 'react'
import Mouse from '../../../assets/image/ExploreSection/Mouse.png'
import Pr from '../../../assets/image/ExploreSection/Pr.png'
import Scale from '../../../assets/image/ExploreSection/Scale.png'
import { MyCard } from '../Subcomponent/Card'
import { makeStyles } from '@material-ui/core/styles';

import Slider from "react-slick";
const useStyles = makeStyles(() => ({
    projectClass: {
        width: '100%',
        "&>.slick-slider .slick-list": {
            paddingTop: '0.5rem !important',
            paddingBottom: '1rem !important'
        },
        "&>.slick-slider .slick-dots": {
            bottom:"1rem !important"
        }
    }
}));
const MobileUser = () => {
    const classes = useStyles()


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const openApp = () => {
        window.open('https://app.unifarm.co/')
    }
    return (
        <div className={"section-margin" + ' ' + classes.projectClass}>
            <Slider {...settings} >
                <div>
                    <MyCard
                        Url={Pr}
                        Imagewidth={90}
                        margin={3}
                        Title="Minimum Guaranteed APY"
                        Detail="Earn a minimum APY of 36% on all cohorts. This gives the stakers surety of earning good yield on their tokens."
                    />
                </div>
                <div>
                    <MyCard
                        Url={Scale}
                        Imagewidth={50}
                        margin={3}
                        Title="One place to Farm"
                        Detail="UniFarm enables you to farm tokens in one place. Users no longer have to go on various platforms or exchanges to farm different tokens."
                    />
                </div>
                <div>
                    <MyCard
                        Url={Mouse}
                        Imagewidth={90}
                        margin={3}
                        Title="Easy to Use"
                        Detail="UniFarm offers a simple user interface with a great user experience offering one-click stake and unstake facility on a completely decentralized platform."
                    />
                </div>


            </Slider>
            <div className="flex justify-center mt-1 mb-6">
                <button className="Submit-btn" onClick={openApp}>Open App</button>
            </div>


        </div>
    )
}
export default MobileUser