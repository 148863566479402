import { Grid } from "@mui/material";
import React from "react";
import { SectionContent, SectionTitle, SectionTitleWrap } from "../Users/Users.style";
interface isProps{
    title:string;
    content:any;
}
export const SectionTitleCompo = ({title,content}:isProps) => {
  return (
    <Grid item md={12}>
      <SectionTitleWrap>
        <SectionTitle>{title}</SectionTitle>
        <SectionContent>
         {content}
        </SectionContent>
      </SectionTitleWrap>
    </Grid>
  );
};
export default SectionTitleCompo
