import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Slider from "react-slick";
// import ImageItem from "./ImageItem";
import ImageWrapper from "./Imagewrapper";
import ProjectPartner from '../../../assets/Banner/WebBanner/ProjectPartner_1.png';
import ProjectPartner2 from '../../../assets/Banner/WebBanner/Pp_2.png';
import ProjectPartner3 from '../../../assets/Banner/WebBanner/pp_3.png';
import ProjectPartner4 from '../../../assets/Banner/WebBanner/pp_4.png';

import IpadBanner1 from "../../../assets/Banner/IpadBanner/Ipad_1.png"
import IpadBanner2 from "../../../assets/Banner/IpadBanner/Ipad_2.png"
import IpadBanner3 from "../../../assets/Banner/IpadBanner/Ipad_3.png"
import IpadBanner4 from "../../../assets/Banner/IpadBanner/Ipad_4.png"
import BannerItem from "./BannerItem";

// import MobileSlider from "./MobileSlider";

// import APYswap from "../../../assets/Logos/Partner/APYswap.png";
// import bitcash from "../../../assets/Logos/Partner/bitcash.png";
// import Bluzelle from "../../../assets/Logos/Partner/Bluzelle.png";
// import busd_logo_black from "../../../assets/Logos/Partner/busd_logo_black.png";
// import CANNUMO from "../../../assets/Logos/Partner/CANNUMO.png";
// import Centaur from "../../../assets/Logos/Partner/Centaur.png";
// import coinswap from "../../../assets/Logos/Partner/coinswap.png";
// import Collateral from "../../../assets/Logos/Partner/Collateral.png";

// import Creator from "../../../assets/Logos/Partner/Creator.png";
// import De_Hive from "../../../assets/Logos/Partner/De_Hive.png";
// import Fortube from "../../../assets/Logos/Partner/Fortube.png";
// import Hedget from "../../../assets/Logos/Partner/Hedget.png";
// import heshfi from "../../../assets/Logos/Partner/heshfi.png";
// import ispolink from "../../../assets/Logos/Partner/ispolink.png";
// import kommunitas from "../../../assets/Logos/Partner/kommunitas.png";
// import matic from "../../../assets/Logos/Partner/matic.png";

const MySlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const theme = useTheme();
  // const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  // const [Data, setData] = React.useState([]);

  const bannerData = [
    ProjectPartner,
    ProjectPartner2,
    ProjectPartner3,
    ProjectPartner4
  ]

  const IpadBanner = [
    IpadBanner2,
    IpadBanner1,
    IpadBanner3,
    IpadBanner4,

  ]

  // const listToMatrix = (list, elementsPerSubArray) => {
  //   var matrix = [],
  //     i,
  //     k;

  //   for (i = 0, k = -1; i < list.length; i++) {
  //     if (i % elementsPerSubArray === 0) {
  //       k++;
  //       matrix[k] = [];
  //     }

  //     matrix[k].push(list[i]);
  //   }

  //   return matrix;
  // };

  // React.useEffect(() => {
  //   const Token = localStorage.getItem("Token");
  //   if (Token) {
  //     var myHeaders = new Headers();
  //     myHeaders.append(`Authorization`, `Bearer ${Token}`);
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //       request_code: "72758fa29ad3d3516",
  //       platform: "unifarm",
  //     });
  //     fetch("https://my.oropocket.com/restAPI/partnership.php", {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     })
  //       .then((response) => response.json())
  //       .then((result) => {
  //         setData(result.data.partnership);
  //       })
  //       .catch((error) => console.log("error", error));
  //   }
  // }, []);

  // var myHeaders = new Headers();
  // myHeaders.append("Content-Type", "application/json");

  // var raw = JSON.stringify({
  //   secret_key: "607806ddf20e8483b289cb3c245ec52f",
  //   api_id: "92878ff5ac678faa2c997ad3ad35c147",
  //   request_code: "72758fa29ad3d3516",
  // });

  // fetch("https://my.oropocket.com/restAPI/authentication.php", {
  //   method: "POST",
  //   headers: myHeaders,
  //   body: raw,
  //   redirect: "follow",
  // })
  //   .then((response) => response.json())
  //   .then((result) => localStorage.setItem("Token", result.token))
  //   .catch((error) => console.log("error", error));

  return (
    <div>
      {Ipad ? (
        <Slider {...settings}>
            {IpadBanner.map((dt,index)=>{
              return(

              <div className="flex flex-col justify-between" key= {index}
              style= {{width:"100px"}}   >
                

                <ImageWrapper key={index}>
                {/* <img src={dt} key={index}  style={{height:'fit-content',width:'100%'}}/> */}
                  {/* // <ImageItem key={index} url={dt} style={{height:'fit-content',width:'100%'}} /> */}
                  <BannerItem key={index} url={dt} />
                 
                  </ImageWrapper> 

              </div>
              )

              })
            }

          {/* {listToMatrix(Data, 6).map((dt, index) => {
            return (
              <div
                className="flex flex-col justify-between"
                key={index}
                style={{ width: "100px" }}
              > */}
                {/* <ImageWrapper key={index}>
                  {IpadBanner.map((dt2, index2) => {
                    return (
                      <ImageItem
                        url={dt2}
                        key={index2}
                      />
                    );
                  })}
                </ImageWrapper> */}
                {/* <ImageWrapper>
                  {dt.slice(3, 6).map((dt2, index2) => {
                    return (
                      <ImageItem
                        url={
                          "https://my.oropocket.com/uploads/partnership/" +
                          dt2.logo
                        }
                        key={index2}
                      />
                    );
                  })}
                </ImageWrapper> */}
              {/* </div> */}
            {/* ); */}
          {/* })} */}
        </Slider>
      ) : (
        <>
          <Slider {...settings}>
            {bannerData.map((dt, index) => {
              return (
                <div
                  className="flex flex-col justify-between"
                  key={index}
                  style={{ width: "100px" }}
                >
                  
                  <ImageWrapper key={index}>
                    <BannerItem key={index} url={dt} />
                      {/* <img src={dt} key={index}  style={{height:'fit-content',width:'100%'}}  /> */}
                  {/* <ImageItem key={index} url={dt} style={{height:'fit-content',width:'100%'}}  /> */}

                    {/* {dt.slice(0, 4).map((dt2, index2) => {
                      return (
                        <ImageItem
                          url={
                            "https://my.oropocket.com/uploads/partnership/" +
                            dt2.logo
                          }
                          key={index2}
                        />
                      );
                    })} */}
                  </ImageWrapper>
                  {/* <ImageWrapper>
                    {dt.slice(4, 8).map((dt2, index2) => {
                      return (
                        <ImageItem
                          url={
                            "https://my.oropocket.com/uploads/partnership/" +
                            dt2.logo
                          }
                          key={index2}
                        />
                      );
                    })}
                  </ImageWrapper> */}
                </div>
              );
            })}
          </Slider>
        </>
      )}
    </div>
  );
};
export default MySlider;
