import React from "react";
import Slider from "react-slick";
// import ImageItem from "../../ProjectPatner/SubComponent/ImageItem";
import ImageWrapper from "../../ProjectPatner/SubComponent/Imagewrapper";
import MobVentureBanner from "../../../assets/InvestBanner/MobileBanner/mob_venture.png";
import MobInfluncerBanner from "../../../assets/InvestBanner/MobileBanner/mob_influnce.png";
import BannerItem from "../../ProjectPatner/SubComponent/BannerItem";

// import Au211 from "../../../assets/Logos/investors/Au211.png";
// import Alphabit from "../../../assets/Logos/investors/Alphabit.png";
// import AcuityCapital from "../../../assets/Logos/investors/Acuity Capital.png";
// import AcheronCapital from "../../../assets/Logos/investors/Acheron Capital.png";

// import Truinflunence from "../../../assets/Logos/investors/Truinflunence.png";
// import CHRONOSVENTURES from "../../../assets/Logos/investors/CHRONOS VENTURES.png";
// import Faculty_capital from "../../../assets/Logos/investors/faculty_capital.png";
// import Group1 from "../../../assets/Logos/investors/Group 1.png";

// import Group from "../../../assets/Logos/investors/Group.png";
// import MoonwhaleVentures from "../../../assets/Logos/investors/Moonwhale Ventures.png";
// import MorningStarsVentures from "../../../assets/Logos/investors/MorningStars Ventures.png";
// import NGCCapital from "../../../assets/Logos/investors/NGC Capital.png";

// import Okex from "../../../assets/Logos/investors/okex.png";
// import PlaycentCapital from "../../../assets/Logos/investors/playcent Capital.png";
// import RarestoneCapital from "../../../assets/Logos/investors/RarestoneCapital.png";
// import Spark from "../../../assets/Logos/investors/spark.png";

// import cat from "../../../assets/image/CohortSlider/cat.png";
// import Tcoin from "../../../assets/image/CohortSlider/tcoin.png";
// import der from "../../../assets/image/CohortSlider/der.png";
// import quick from "../../../assets/image/CohortSlider/quick.png";
// import unifarm from "../../../assets/image/CohortSlider/UniFarmNew.png";
// import picipo from "../../../assets/image/CohortSlider/picipo.png";
// import polka from "../../../assets/image/CohortSlider/polka.png";
// import kom from "../../../assets/image/CohortSlider/kom.png";

const MobileSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider {...settings}>
        <div className="flex flex-col justify-between">
          <ImageWrapper>
            <BannerItem url={MobInfluncerBanner} />
          </ImageWrapper>

          {/* <ImageWrapper>
            <ImageItem url={AcheronCapital}  />
            <ImageItem url={AcuityCapital}  />
          </ImageWrapper>
          <ImageWrapper>
            <ImageItem url={Alphabit}  />
            <ImageItem url={Au211} />
          </ImageWrapper> */}
        </div>
        <div className="flex flex-col justify-between">
          <ImageWrapper>
            <BannerItem url={MobVentureBanner} />
          </ImageWrapper>

          {/* <ImageWrapper>
            <ImageItem url={Truinflunence}  />
            <ImageItem url={CHRONOSVENTURES} />
          </ImageWrapper>
          <ImageWrapper>
            <ImageItem  url={Faculty_capital} />
            <ImageItem  url={Group1} />
          </ImageWrapper> */}
        </div>

        {/* <div className="flex flex-col justify-between">
          <ImageWrapper>
            <ImageItem  url={Group} />
            <ImageItem  url={MoonwhaleVentures} />
          </ImageWrapper>
          <ImageWrapper>
            <ImageItem  url={MorningStarsVentures} />
            <ImageItem  url={NGCCapital} />
          </ImageWrapper>
        </div>
        <div className="flex flex-col justify-between">
          <ImageWrapper>
            <ImageItem  url={Okex} />
            <ImageItem  url={PlaycentCapital} />
          </ImageWrapper>
          <ImageWrapper>
            <ImageItem  url={RarestoneCapital} />
            <ImageItem  url={Spark} />
          </ImageWrapper>
        </div> */}
      </Slider>
    </div>
  );
};
export default MobileSlider;
