import { Grid } from "@mui/material";
import React from "react";
import UserItem from "./UserItem";

import User1 from "../../../assets/Setuimage/user1.png";
import User2 from "../../../assets/Setuimage/user2.png";
import User3 from "../../../assets/Setuimage/user3.png";
import User4 from "../../../assets/Setuimage/user5.png";
import SectionTitleCompo from "../SectionTitle";
import { useMobile } from "../MuiMediaQuery/MuiMediaQuery";
const Users = () => {
  const mobile = useMobile();
  return (
    <Grid container spacing={2}>
      <SectionTitleCompo
        title="For Users"
        content={
          mobile ? (
            <>
              Get more channels to earn passively
              <br /> and eliminate hassles, because your
              <br /> day belongs to you.
            </>
          ) : (
            <>
              Get more channels to earn passively and eliminate <br />
              hassles, because your day belongs to you.
            </>
          )
        }
      />

      <Grid container spacing={8} columnSpacing={8}>
        <Grid item md={6} xs={12}>
          <UserItem
            image={User1}
            title="Transfer Tokens"
            content={
              <>
                SETU allows the transfer of
                <br /> tokens from one chain to another.
              </>
            }
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <UserItem
            image={User3}
            title="Unlock Liquidity Anytime"
            content={
              <>
                Users can unlock their liquidity and <br />
                claim their confirmed rewards anytime.
              </>
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <UserItem
            image={User2}
            title={
              mobile ? (
                <>Add Liquidity, Earn Rewards</>
              ) : (
                <>
                  Add Liquidity,
                  <br /> Earn Rewards
                </>
              )
            }
            content={
              <>
                Users can add liquidity and earn
                <br /> rewards with every transaction.
              </>
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <UserItem
            image={User4}
            title="Protect Your Principle"
            content={
              <>
                No market exposure means no <br />
                impermanent losses, so the principle
                <br /> remains the same.
              </>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Users;
