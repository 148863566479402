import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const Dot = styled.div`
  width: 24px;
  height: 24px;
  background: #673ab7;
  border-radius: 64px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Line = styled.div`
  width: 70px;
  height: 2px;
  background: #bdbdbd;
`;
const Text = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #000000;
`;

export default function TimeLine() {
  return (
    <Container>
      <Dot>1</Dot>
      <Text>Top</Text>
      <Line />
      <Dot>2</Dot>
      <Text>Mid</Text>
    </Container>
  );
}
