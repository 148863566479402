import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import Slider from "react-slick";
import InflunceBanner from "../../../assets/InvestBanner/WebBanner/web_influnce.png";
import ventureBanner from "../../../assets/InvestBanner/WebBanner/web_ventures.png";
import IpadInflunceBanner from "../../../assets/InvestBanner/IpadBanner/ipad_influnce.png"
import IpadVentureBanner from "../../../assets/InvestBanner/IpadBanner/ipad_venture.png"



// import AcheronCapital from "../../../assets/Logos/investors/Acheron Capital.png";
// import AcuityCapital from "../../../assets/Logos/investors/Acuity Capital.png";
// import Alphabit from "../../../assets/Logos/investors/Alphabit.png";
// import Au211 from "../../../assets/Logos/investors/Au211.png";

// import Truinflunence from "../../../assets/Logos/investors/Truinflunence.png";
// import CHRONOSVENTURES from "../../../assets/Logos/investors/CHRONOS VENTURES.png";
// import Faculty_capital from "../../../assets/Logos/investors/faculty_capital.png";
// import Group1 from "../../../assets/Logos/investors/Group 1.png";

// import Group from "../../../assets/Logos/investors/Group.png";
// import MoonwhaleVentures from "../../../assets/Logos/investors/Moonwhale Ventures.png";
// import MorningStarsVentures from "../../../assets/Logos/investors/MorningStars Ventures.png";
// import NGCCapital from "../../../assets/Logos/investors/NGC Capital.png";

// import Okex from "../../../assets/Logos/investors/okex.png";
// import PlaycentCapital from "../../../assets/Logos/investors/playcent Capital.png";
// import RarestoneCapital from "../../../assets/Logos/investors/RarestoneCapital.png";
// import Spark from "../../../assets/Logos/investors/spark.png";

import ImageWrapper from "../../ProjectPatner/SubComponent/Imagewrapper";
// import ImageItem from "../../ProjectPatner/SubComponent/ImageItem";
import MobileSlider from "./MobileSlider";
import BannerItem from "../../ProjectPatner/SubComponent/BannerItem";

const MySlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <div>
      {Mobile ? (
        <MobileSlider />
      ) : Ipad ? (
        <Slider {...settings}>
          <div className="flex flex-col justify-between">
            <ImageWrapper>
              <BannerItem url={IpadInflunceBanner}/>

              {/* <ImageItem url={AcheronCapital}  />
              <ImageItem url={AcuityCapital}   />
              <ImageItem url={Alphabit}  /> */}
            </ImageWrapper>
            {/* <ImageItem url={Au211} /> */}
            {/* <ImageWrapper>
              <ImageItem url={Truinflunence}  />
              <ImageItem url={CHRONOSVENTURES} />
              <ImageItem url={Faculty_capital}  />
            </ImageWrapper> */}
            {/* <ImageItem url={Group1} /> */}
          </div>
          <div className="flex flex-col justify-between">
            <ImageWrapper>
              <BannerItem  url={IpadVentureBanner}/>

              {/* <ImageItem  url={Group} />
              <ImageItem  url={MoonwhaleVentures} />
              <ImageItem  url={MorningStarsVentures} /> */}
            </ImageWrapper>
            {/* <ImageItem url={NGCCapital} /> */}
            {/* <ImageWrapper>
              <ImageItem  url={Okex} />
              <ImageItem  url={PlaycentCapital} />
              <ImageItem  url={RarestoneCapital} />
            </ImageWrapper> */}
            {/* <ImageItem url={Spark} /> */}
          </div>
        </Slider>
      ) : (
        <Slider {...settings}>
          <div className="flex flex-col justify-between">
            <ImageWrapper>
              <BannerItem url={InflunceBanner} />

            {/* <ImageItem url={InflunceBanner}/>  */}
             {/* <ImageItem url={InflunceBanner}/>  */}
              {/* <ImageItem  url={AcheronCapital} />
              <ImageItem  url={AcuityCapital} />
              <ImageItem  url={Alphabit} />
              <ImageItem  url={Au211} /> */}
            </ImageWrapper>
            {/* <ImageWrapper>
              <ImageItem  url={Truinflunence} />
              <ImageItem  url={CHRONOSVENTURES} />
              <ImageItem  url={Faculty_capital} />
              <ImageItem  url={Group1} />
            </ImageWrapper> */}
          </div>
          <div className="flex flex-col justify-between">
            <ImageWrapper>

            <BannerItem url={ventureBanner} />
              {/* <ImageItem   url={Group} />
              <ImageItem  url={MoonwhaleVentures} />
              <ImageItem  url={MorningStarsVentures} />
              <ImageItem  url={NGCCapital} /> */}
            </ImageWrapper>
            {/* <ImageWrapper>  
              <ImageItem   url={Okex} />
              <ImageItem  url={PlaycentCapital} />
              <ImageItem  url={RarestoneCapital} />
              <ImageItem  url={Spark} />
            </ImageWrapper> */}
          </div>
        </Slider>
      )}
    </div>
  );
};
export default MySlider;
