import React from 'react'
import Investor from '../../../assets/image/ExploreSection/investor.png'
import Presure from '../../../assets/image/ExploreSection/presure.png'
import Token from '../../../assets/image/ExploreSection/token.png'
import { MyCard } from '../Subcomponent/Card'
import { makeStyles } from '@material-ui/core/styles';

import Slider from "react-slick";
const useStyles = makeStyles(() => ({
    projectClass: {
        width: '100%',
        "&>.slick-slider .slick-list": {
            paddingTop: '0.5rem !important',
            paddingBottom: '1rem !important'
        },
        "&>.slick-slider .slick-dots": {
            bottom:"1rem !important"
        }
    }
}));
interface IsProject{
    ContactClickMobile:()=>void
}
const MobileProjetc = ({ContactClickMobile}:IsProject) => {
    const classes = useStyles()


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
  
    return (
        <div className={"section-margin" + ' ' + classes.projectClass}>
            <Slider {...settings} >
                <div>
                    <MyCard
                      margin={3}
                        Url={Token}
                        Imagewidth={90}
                        Title="Creating Utility for the Token"
                        Detail="Once a project is onboarded on UniFarm, their token holders can stake the tokens and get many other good project tokens at high APY. "
                    />
                </div>
                <div>
                    <MyCard
                        Url={Investor}
                        Imagewidth={90}
                        margin={3}
                        Title="Access to New Set of Investors"
                        Detail="Our cross-marketing activities ensures each project gets a new set of prospects and gives projects opportunities to build synergies with other projects "
                    />
                </div>
                <div>
                    <MyCard
                        Url={Presure}
                        Imagewidth={90}
                        margin={3}
                        Title="Easing the Sell Pressure"
                        Detail="UniFarm can help projects set up targeted farms to ease the sell pressure and incentivizing investors to become long term holders"
                    />
                </div>


            </Slider>
            <div className="flex justify-center mt-1 mb-6">
                <button className="Submit-btn" onClick={ContactClickMobile}>Contact UniFarm</button>
            </div>

        </div>
    )
}
export default MobileProjetc