import React from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const About = () => {
  const menu = (
    <Menu style={{ width: 180, borderRadius: 15 }}>
      {/* <Menu.Item key="0" disabled>
            <a href="https://www.antgroup.com" className='leading-9 text-base' >Vision</a>
          </Menu.Item>
          <Menu.Item key="1" disabled>
            <a href="https://www.aliyun.com" className='leading-8 text-base'>Mission</a>
          </Menu.Item> */}
      <Menu.Item key="2">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://careers.unifarm.co/#72efd83d0f8f4d2682522c1d9402db01"
          className="leading-9 text-base"
        >
          Team
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://fellowship-program.super.site/"
          className="leading-9 text-base"
        >
          Fellowship Program
        </a>
      </Menu.Item>
      <Menu.Item key="4">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://uni.farm/buildinpublic"
          className="leading-9 text-base"
        >
          Build in Public
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a
        className="ant-dropdown-link text-white hover:text-white text-base flex items-center"
        onClick={(e) => e.preventDefault()}
      >
        About &nbsp;
        <DownOutlined />
      </a>
    </Dropdown>
  );
};
export default About;
