import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
// import RoadMapSvg from '../../../assets/SvgComponent/Roadmap'
// import RoadMapSvg1 from '../../../assets/SvgComponent/RoadMap2'
import mp1 from '../../../assets/image/RoadMap/Part1.png'
import mp2 from '../../../assets/image/RoadMap/Part2.png'
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
    Image1: {
        // marginTop: '9rem'
        marginTop:'-0.5rem'

    },
    Image2: {
        // marginTop: '-1.2rem',


    }
}));
const IpadSlider = () => {
    const classes = useStyles()
    const [selectedCarouselIndex, setSelectedCarouselIndex] = useState(1);
    const carouselRef = useRef(null);
    useEffect(() => {
        if (carouselRef.current?.slickGoTo)
          carouselRef.current.slickGoTo(selectedCarouselIndex);
          console.log(setSelectedCarouselIndex(1))
      }, [selectedCarouselIndex]);
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        initialSlide: selectedCarouselIndex
    };
    return (
        <div >
            <Slider {...settings} ref={carouselRef}>
                <div className="flex flex-col justify-between" >
                    <img src={mp1} className={classes.Image1} />
                </div>
                <div className="flex flex-col justify-between">
                    <img src={mp2} className={classes.Image2} />
                </div>

            </Slider>
        </div>
    )
}
export default IpadSlider