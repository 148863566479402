import React from "react";
// import Link from "react-router-dom"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
// import Devider from "./Devider";
// import BannerImage from "../../assets/Banner/banner.png";
// import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import BitmartWeb from "../../assets/image/launch/Web.png";
// import BitmartMobile from "../../assets/image/launch/mobile.png";
// import DcdPcBanner from "../../assets/image/DCDPcBanner.png";
// import DcdMobileBanner from "../../assets/image/DcdMobileBanner.png";
// import BannerRocket from "../../assets/image/webrocket2.png";
// import MobileRocket from "../../assets/image/mobilerocket3.png";
// import BannerforWeb from "../../assets/image/Bannerforweb.png";
// import WebIdo from "../../assets/image/WebIdo.png";
// import MobIdo from "../../assets/image/MobIdo.png";
// import LegionWebIdo from "../../assets/image/legionwebido.png";
// import LegionMobIdo from "../../assets/image/legionmobido.png";
// import LegionWebNewIdo from "../../assets/image/legionnetworknewbanner.png";
// import WebBannerLegion from "../../assets/image/WebbannerLegion.png";
// import MobBannerLegion from "../../assets/image/MobbannerLegion.png";
// import LegionBanner from "../../assets/image/goinglivetoday.png";
// import LegionMobile from "../../assets/image/goingmoblive.png";
// import LegionLive from "../../assets/image/livenow.png";
// import LegionLiveMob from "../../assets/image/livenowmob.png";
// import MobileRocket from "../../assets/image/mobilerocket3.png";
// import BannerforWeb from "../../assets/image/Bannerforweb.png";
// import bullshit from "../../assets/Banner/ToBeAnnounced_web.png";
// import bullshit_mobile from "../../assets/Banner/ToBeAnnounced_mob.png";

import ThunderWeb from "../../assets/Banner/WagmiFarm.png";
import ThunderMobile from "../../assets/Banner/WagmiFarm_mob.png";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 80,
    marginBottom: -5,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    left: "39px",
    gap: "40px",
    flexDirection: "column",
    top: "1661px",
    filter: "drop-shadow(0px 10px 10px #E0E0E0)",
    [theme.breakpoints.down(376)]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      marginTop: 60,
    },
    [theme.breakpoints.between(376, 394)]: {
      marginTop: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    [theme.breakpoints.between(394, 415)]: {
      marginTop: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
  },
  Image: {
    position: "absolute",
    padding: 80,
    [theme.breakpoints.down("xs")]: {
      padding: 25,
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: 60,
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "391px",
    height: "86px",
    left: "0px",
    top: "137px",
    marginTop: "1rem",
  },
  Heading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "26px",
    marginTop: "2rem",
    marginBottom: "1rem",
    whiteSpace: "nowrap",
    color: "#332233",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
  },
  detail: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "20px",
    color: "#332233",
    whiteSpace: "nowrap",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  img2: {
    borderRadius: "15px",
    [theme.breakpoints.down("xs")]: {
      height: "200px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "300px",
    },
  },
}));

const Banner = () => {
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  return (
    <>
      {Mobile ? (
        <div className={classes.container}>
          <a
            target="_blank"
            rel="noreferrer"
            // href="https://fellowship-program.super.site"
            href="https://app.unifarm.co/launch"
            style={{ width: "100%" }}
          >
            {/* <Link to="https://app.unifarm.co/launch">  */}
            <img
              src={ThunderMobile}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
              }}
            />
            {/* </Link> */}
          </a>
        </div>
      ) : (
        <div className={classes.container}>
          <a
            target="_blank"
            rel="noreferrer"
            // href="https://fellowship-program.super.site"
            href="https://app.unifarm.co"
          >
            {/* <Link to="https://app.unifarm.co"> */}

            <img src={ThunderWeb} className={classes.img2} />
            {/* </Link>  */}
          </a>
        </div>
      )}
    </>
    // <>
    //   {Mobile ? (
    //     <div className={classes.container}>
    //       <a target="_blank" rel="noreferrer" href="/LegioNetwork">
    //         <img src={LegionLiveMob} />
    //       </a>
    //     </div>
    //   ) : (
    //     <div className={classes.container}>
    //       <a target="_blank" rel="noreferrer" href="/LegioNetwork">
    //         <img src={LegionLive} className={classes.img2} />
    //       </a>
    //     </div>
    //   )}
    // </>
  );
};

export default Banner;
