import { Button } from "@mui/material";
import styled from "styled-components";
export const HeroContentWrap = styled.div`
text-align-left;
display: flex;
    flex-direction: column;
    row-gap: 22px;
    @media (max-width: 430px) {
      align-items: center;
    }
`;
export const HeroTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;

  color: #332233;
  @media(max-width:430px){
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 51px;
    text-align: center;
    color: #332233;
  }
`;
export const HeroContent = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #332233;
`;
export const HeroButton = styled(Button)`
  width: 150px !important;
  height: 55px !important;

  background: linear-gradient(93.92deg, #cb218e 1.76%, #663399 84%) !important;
  box-shadow: 0px 6px 24px rgba(203, 33, 142, 0.3) !important;
  border-radius: 40px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;

  color: #ffffff !important;
`;
export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
