import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Twitter from "../../../assets/Ido/Twitter.png";
// import Browser from "../../../assets/Ido/Browser.png";
import Discord from "../../../assets/Ido/Discord.png";
import Link from "../../../assets/Ido/Link.png";
import Medium from "../../../assets/Ido/Medium.png";
import Telegram from "../../../assets/Ido/Telegram.png";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "90px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "80px",
    },
    [theme.breakpoints.down(376)]: {
      marginTop: "60%",
    },
  },
  Main_div: {
    width: "690px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  Title: {
    fontSize: "34px",
    fontWeight: 900,
    lineHeight: "44px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30 px",
    },
  },
  Text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#000000",
    marginTop: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  Text2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  Button_div: {
    display: "flex",
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  Learn_button: {
    width: "300px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    borderRadius: "30px",
    border: "1px solid",
    borderColor: "#CB218E",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    marginRight: "15px",
    color: "#332233",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
      marginRight: "0px",
    },
  },
  Check_button: {
    width: "300px",
    height: "45px",
    background: "linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%)",
    boxShadow: "0px 6px 24px rgba(203, 33, 142, 0.3)",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    border: "1px solid",
    borderColor: "#CB218E",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "white",
    marginLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
      marginLeft: "0px",
      marginTop: "30px",
    },
  },
  Icons: {
    width: "25px",
    height: "25px",
    marginRight: "10px",
    marginLeft: "10px",
  },
}));

const About = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <div className={classes.Root}>
        <div className={classes.Main_div}>
          <span className={classes.Title}>About The Project</span>
          <span className={classes.Text}>
            A Bridge for Traditional and Blockchain Gaming. GameYoo seeks to
            rebalance the industry by bringing more traditional gamers into the
            GameFi fold. We strongly believe that in the web 3.0 era, the true
            ownership of digital assets, tokenized gaming currency and
            blockchain-based gaming characters will revolutionize the gaming
            industry. All players should have the opportunity to create more
            value out of their achievements in games. Thier mission is to lead
            more gamers to participate in blockchain games and enjoy them, and
            to provide a full range of services to more game developers.
          </span>

          <div className={classes.Button_div}>
            <a
              href="https://www.gameyoo.com/"
              target="_blank"
              rel="noreferrer"
            >
              <button className={classes.Learn_button}>
                Learn More About Gameyoo
              </button>
            </a>
            <a
              href="https://app.unifarm.co/launch/56"
              target="_blank"
              rel="noreferrer"
            >
              <button className={classes.Check_button}>
                Check your eligibility
              </button>
            </a>
          </div>
          <div style={{ display: "flex", marginTop: "50px" }}>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/GameYooOfficial">
              <img src={Twitter} alt="img" className={classes.Icons} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://t.me/gameyoo_official">
              <img src={Telegram} alt="img" className={classes.Icons} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://discord.com/invite/Cy29XAz58V">
              <img src={Discord} alt="img" className={classes.Icons} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://gameyoo.medium.com/">
              <img src={Medium} alt="img" className={classes.Icons} />
            </a>
            <a target="_blank" rel="noreferrer" href="http://linktr.ee/gameyoo">
              <img src={Link} alt="img" className={classes.Icons} />
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default About;
