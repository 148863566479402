import React from "react";
import { useStyles } from "../LaunchpadStyle";
import { flipInX } from "react-animations";
import Radium, { StyleRoot } from "radium";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link } from "react-router-dom";

interface Isprops {
  cardImage: string;
  idoImage: string;
  idoName: string;
  idoContent: string;
  linkToPage: string;
  tags: string[];
  bullets: string[];
}

const PastCard = ({
  cardImage,
  idoImage,
  idoName,
  idoContent,
  linkToPage,
  tags,
  bullets,
}: Isprops) => {
  const styles = {
    flipInX: {
      animation: "x 2s",
      animationName: Radium.keyframes(flipInX, "bounce"),
    },
  };

  const classes = useStyles();
  const [animation, setAnimation] = React.useState(false);
  const [Isanimation, setIsanimation] = React.useState(true);

  return (
    <StyleRoot>
      <div
        className={classes.PastCardRoot + " " + `${Isanimation ? 'rainbow' : 'rainbow2'}`}
        onMouseEnter={() => { setAnimation(true), setIsanimation(false) }}
        onMouseLeave={() => { setAnimation(false), setIsanimation(true) }}
      >
        <Link to={linkToPage}>
          <div>
            <img src={cardImage} className={classes.PastImage} />
          </div>
          <div className={classes.pastLabel}>
            <img src={idoImage} className={classes.labelImage} />
          </div>
          <div className={classes.SolodOutLable}>
            <span>Sold Out</span>
          </div>
          <div
            className={classes.PastContentDiv}
            style={animation ? styles.flipInX : null}
          >
            {animation ? (
              <>
                <span className={classes.PastHeading}>{idoName}</span>
                {bullets.length === 0 ? (
                  <div>
                    <span className={classes.PastContent}>{idoContent}</span>

                  </div>
                ) : (
                  bullets.map((data, index) => {
                    return (
                      <div className={classes.HoverDiv} key={index}>
                        <FiberManualRecordIcon
                          style={{ fontSize: 12, color: "black" }}
                        />
                        &nbsp;
                        <span className={classes.hoverSpan}>{data}</span>
                      </div>
                    );
                  })
                )}
              </>
            ) : (
              <>
                <span className={classes.PastHeading}>{idoName}</span>
                <br />
                <span className={classes.PastContent}>{idoContent}</span>

              </>
            )}
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            {tags.map((data, index) => {
              return (
                <div className={classes.PastButtons} key={index}>
                  <span>{data}</span>
                </div>
              );
            })}
          </div>
        </Link>
      </div>
    </StyleRoot>
  );
};
export default PastCard;
