// import M2_img from "../../assets/Element/M2_img.png";
import Dialog from "@material-ui/core/Dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Circle from "../../assets/Element/Circle.png";
import img from "../../assets/Element/element1.svg";
import vector from "../../assets/Element/vector.png";
// import Logo from "../../assets/image/Logo/logo2.png";
import Container from "../../component/Container";
import Found from "./SubComponent/Found";
import Header from "../../component/Header/index";
import NotFound from "./SubComponent/NotFound";
// import Divider from "@material-ui/core/Divider";

interface isMedia {
  title: string;
  desc: string;
}

const Media = ({ title, desc }: isMedia) => {
  React.useEffect(() => {
    document.title = `UniFarm | ${title}`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", desc);
    document
      .querySelector('meta[name="og:title"]')
      .setAttribute("content", title);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", desc);
  }, [title]);

  const [isHeight, setisHeight] = useState(true);
  const useStyles = makeStyles((theme) => ({
    Root: {
      width: "100%",
      height: "100vh",
      overflow: "hidden",
    },
    Mobile_Root: {
      width: "100%",
      overflow: "hidden",
    },
    Hero_div: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "90px",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        alignItems: "center",
        height: isHeight ? "50vh" : "58vh",
      },
    },

    Title_div: {
      width: "100%",
      position: "relative",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      marginTop: "180px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        marginTop: "-24rem",
      },
    },

    Logo_div: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    Mobile_Logo_div: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    Divider: {
      width: "3px",
      height: "55px",
      borderRadius: "30px",
      background: "linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%)",
      marginLeft: "20px",
      marginRight: "20px",
    },

    Title_text: {
      fontSize: "35px",
      fontWeight: 900,
      [theme.breakpoints.down(425)]: {
        fontSize: "25px",
      },
    },

    Para_div: {
      display: "flex",
      position: "relative",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "18px",
      fontWeight: 500,
      paddingLeft: "280px",
      paddingRight: "280px",
      marginTop: "40px",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
      [theme.breakpoints.between("sm", "md")]: {
        padding: 0,
      },
    },
    TextFiled: {
      width: "500px",
      borderRadius: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        borderRadius: "10px",
      },
    },
    Input_div: {
      display: "flex",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0px",
        marginBottom: "150px",
      },
    },

    Error_div: {
      marginLeft: "-290px",
      marginTop: "5px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "-48%",
      },
    },
    Mobile_Error_div: {
      marginLeft: "-100px",
      marginTop: "5px",
    },
    placeholder: {
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "20px",
      [theme.breakpoints.down("xs")]: {
        borderRadius: "10px",
      },
    },
    image_circle: {
      width: "600px",
      position: "absolute",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        position: "relative",
      },
    },
    Image1Class: {
      width: "400px",
      position: "fixed",
      top: -50,
      left: -60,
    },
    Image2Class: {
      width: "100%",
      height: "340px",
      marginTop: "-90px",
      bottom: 0,
      // position: "absolute",
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  console.log(Mobile);

  const [field, setField] = useState("");
  const [fielderr, setFielderr] = useState(false);
  const [col, setCol] = useState(false);
  const [btn, setBtn] = useState(false);
  const [open, setOpen] = useState(false);

  const [Isfound, setFound] = useState();

  const handleChange = (e) => {
    setField(e.target.value);
    setFielderr(false);
    setCol(true);
  };
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    secret_key: "607806ddf20e8483b289cb3c245ec52f",
    api_id: "92878ff5ac678faa2c997ad3ad35c147",
    request_code: "72758fa297ad3d3516",
  });

  fetch("https://my.oropocket.com/restAPI/authentication.php", {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((result) => localStorage.setItem("MediaToken", result.token))
    .catch((error) => console.log("error", error));

  const handleApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("MediaToken") + ""
    );
    myHeaders.append("Content-Type", "text/plain");

    var raw = `{"request_code":"72758a29ad3d3516","search_str":"${field}"}`;

    fetch("https://my.oropocket.com/restAPI/verify_contact.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setFound(result.error);
      });
  };

  return (
    <>
      <>
        <div className={classes.Root}>
          {/* <MediaCard /> */}
          <div>
            {Mobile ? null : (
              <img src={img} alt="img" className={classes.Image1Class} />
            )}
          </div>
          <Container>
            <Header ClickHeader={() => setisHeight(!isHeight)} />
            <div className={classes.Hero_div}>
              <img src={Circle} alt="img" className={classes.image_circle} />
            </div>
            <div className={classes.Title_div}>
              {/* {Mobile ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Divider
                    style={{
                      height: 3,
                      width: 180,
                      marginTop: "5px",
                      background:
                        "linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%)",
                    }}
                  />
                </div>
              ) : (
                <div className={classes.Divider}></div>
              )} */}

              <div className={classes.Title_text}>
                Official Team Verification
              </div>
            </div>
            <div className={classes.Para_div}>
              <p>
                {/* To prevent any frauds in the name of UniFarm, you can confirm an
                official UniFarm contact or domain by entering the email,
                WeChat, Telegram, Skype, Twitter, or website address in the
                input field below. */}
                To prevent any frauds in the name of UniFarm, you can confirm an
                official UniFarm contact or domain by entering the
                email(name@unifarm.co), WeChat(unifarm), Telegram(@unifarm),
                Skype(liv:.cid.312dunifarm), Twitter(@unifarm_), or website
                address(unifarm.co) in the input field below.
              </p>
            </div>
            <div className={classes.Input_div}>
              <TextField
                hiddenLabel
                placeholder={
                  Mobile
                    ? "Please enter the domain"
                    : "Please enter the contact or domain"
                }
                variant="outlined"
                name="field"
                onChange={handleChange}
                className={classes.TextFiled}
                InputProps={{
                  classes: {
                    root: classes.placeholder,
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      classes={{ positionStart: "0px" }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (field === "") {
                            setFielderr(true);
                          } else {
                            handleApi();
                            setCol(true);
                            setBtn(true);
                            setTimeout(
                              () => (setBtn(false), setOpen(true)),
                              1000
                            );
                          }
                        }}
                        style={{
                          background: col ? "#673AB7" : "#EFEFEF",
                          width: "130px",
                          height: "44px",
                          borderRadius: "10px",
                          color: col ? "white" : "#616161",
                          textTransform: "none",
                          fontWeight: 600,
                        }}
                      >
                        {btn ? (
                          <>
                            <ClipLoader size={20} color={"white"} />
                            &nbsp;&nbsp;Checking
                          </>
                        ) : (
                          "Check"
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <div className={classes.Error_div}>
                {fielderr ? (
                  <>
                    <ReportGmailerrorredIcon style={{ color: "red" }} />
                    &nbsp;
                    <span style={{ color: "red", fontWeight: 500 }}>
                      Please fill out the blank
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Container>
          <div>
            {Mobile ? null : (
              <img src={vector} alt="img" className={classes.Image2Class} />
            )}
          </div>
        </div>
      </>

      {/* web  success*/}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            borderRadius: "10px",
          },
        }}
      >
        {Isfound ? (
          <NotFound Name={field} Click={() => setOpen(false)} />
        ) : (
          <Found Name={field} Click={() => setOpen(false)} />
        )}
      </Dialog>
    </>
  );
};

export default Media;
