import React from 'react'
import SectionTeam from '../TeamSectionTitle/index'
import WorkCard from './WorkCard'
import Two from '../../../assets/image/team/2.png'
import { makeStyles, Theme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) => ({
    root:{
        display: "flex",
        flexFlow: "wrap",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('xs')]:{
            
        }
    }
}))
const Work = () => {
    const classes=useStyles()
    return (
        <>
            <SectionTeam
                title='How we work'
                description='We believe that with a global team comes a whole world of creativity.'
            />
            <div className={classes.root}>
                <WorkCard
                  url={Two}
                  title="Open communication"
                  content='At our weekly all-hands video calls, we share project updates and learn new skills from each other on topics ranging from online marketing to making sauerkraut.'
                />
                 <WorkCard
                  url={Two}
                  title="Open communication"
                  content='At our weekly all-hands video calls, we share project updates and learn new skills from each other on topics ranging from online marketing to making sauerkraut.'
                />
                 <WorkCard
                  url={Two}
                  title="Open communication"
                  content='At our weekly all-hands video calls, we share project updates and learn new skills from each other on topics ranging from online marketing to making sauerkraut.'
                />
                 <WorkCard
                  url={Two}
                  title="Open communication"
                  content='At our weekly all-hands video calls, we share project updates and learn new skills from each other on topics ranging from online marketing to making sauerkraut.'
                />
            </div>
        </>
    )
}
export default Work