import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccordionData from "./AccordionData.json";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "90px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "80px"
    },
  },
  Main_div: {
    width: "670px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  accordion: {
    width: "670px",
    // height: "72px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "normal",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginBottom: "10px",
    background: "#FFFFFF",
    border: "1px solid #C94277",
    boxSizing: "border-box",
    boxShadow: "4px 4px 25px #E0E0E0",
    borderRadius: "13px !important",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  Title: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#000000",
    marginLeft: "10px",
  },
  Sub_title: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#616161",
  },
  sub_text: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#212121",
    marginLeft: "8px",
  },
  Details: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  Details_text: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Calendar_Icon: {
    width: "18px",
    height: "18px",
  },
  Learn_button: {
    width: "40%",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    borderRadius: "30px",
    border: "1px solid",
    borderColor: "#CB218E",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    background: "linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%)",
    color: "#ffffff",
    "&:hover": {
      color: "#ffffff",
    },
    [theme.breakpoints.down("xs")]: {
      width: "280px",
      marginRight: "0px",
    },
  },
  Participate_button: {
    width: "40%",
    height: "45px",
    background: "linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%)",
    boxShadow: "0px 6px 24px rgba(203, 33, 142, 0.3)",
    borderRadius: "40px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#ffffff",
    scrollBehavior: "smooth",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      color: "#ffffff",
    },
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  button_div: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  ExpandMoreIcon: {
    paddingRight: "10px",
    width: "30px",
    height: "30px"
  },
  Button_link: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const AccordionSale = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div className={classes.Root}>
        <div className={classes.Main_div}>
          {AccordionData.map((data, index) => {
            return (
              <>
                <Accordion
                  key={index}
                  defaultExpanded={index === 0 ? true : false}
                  className={classes.accordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={classes.ExpandMoreIcon} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.Title}>
                      {data.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.Details}>
                      <span className={classes.Sub_title}>Start Date</span>
                      <div className={classes.Details_text}>
                        <CalendarTodayIcon className={classes.Calendar_Icon} />
                        <span className={classes.sub_text}>{data.start}</span>
                      </div>
                    </div>
                    <div className={classes.Details}>
                      <span className={classes.Sub_title}>End Date</span>
                      <div className={classes.Details_text}>
                        <CalendarTodayIcon className={classes.Calendar_Icon} />
                        <span className={classes.sub_text}>{data.end}</span>
                      </div>
                    </div>
                    <div className={classes.Details}>
                      <span className={classes.Sub_title}>Price</span>
                      <div className={classes.Details_text}>
                        <CalendarTodayIcon className={classes.Calendar_Icon} />
                        <span className={classes.sub_text}>${data.price}</span>
                      </div>
                    </div>
                    <div className={classes.Details}>
                      <span className={classes.Sub_title}>Max Allocation</span>
                      <div className={classes.Details_text}>
                        <CalendarTodayIcon className={classes.Calendar_Icon} />
                        <span className={classes.sub_text}>${data.max}</span>
                      </div>
                    </div>
                    <div className={classes.button_div}>
                      {data.link ? (
                        <a
                          href={data.link}
                          className={classes.Participate_button}
                        >
                          {data.text}
                        </a>
                      ) : (
                        <>
                          <a
                            href="https://app.unifarm.co/launch/56"
                            target="_blank"
                            rel="noreferrer"
                            className={classes.Button_link}>
                            <button
                              // onClick={() => {
                              //   data.link && window.open(data.link);
                              // }}
                              // disabled={data.link}
                              className={classes.Learn_button}
                            >
                              {data.text}
                            </button>
                          </a>
                        </>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default AccordionSale;
