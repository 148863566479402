import React, { useEffect, useState } from 'react'
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Ulogo from '../../../assets/image/Logo/u-small-logo2.png'
import logo from '../../../assets/image/MenuImages/logo.png'
import Uniswap from '../../../assets/image/MenuImages/uniswap.png'
import Pancake from '../../../assets/image/MenuImages/pancake.png'
import Dfyn from '../../../assets/image/MenuImages/Dfyn.png'
import Pangolin from '../../../assets/image/MenuImages/pangolin.png'
// import Mexc from '../../../assets/image/MenuImages/mexc.png'
import Mountain from "../../../assets/image/MenuImages/mountain.png";
import { UFARMPRICE } from '../../../ApiRoot/UfarmPrice';
import { Skeleton } from 'antd';
const Ufarm=()=>{
  const [Price,setPrice]=useState<number>()
  
  useEffect(()=>{
    fetch(UFARMPRICE,{
      method:'get'
    })
    .then(res=>res.json())
    .then(res=>setPrice(res.unifarm.usd))
    
  },[])
    const menu = (
        <Menu style={{width:230,borderRadius:15}} className='Trade-menu'>
          <Menu.Item key="0" className='pt-5 '>
           <div className='flex justify-center mr-2'>
           <a href="#">
              <img src={logo} width={150}/>
            </a>
           </div>
          </Menu.Item>
          <Menu.Item key="1" className='pt-5'>
           <div className='flex flex-col items-center'>
             <b className='text-xl font-heigh'>Unifarm Price</b>
             {
               Price?
               <span className='text-base mt-1'>${Price}</span>
               :
               <Skeleton.Input style={{ width: 150,height:20,borderRadius:10 }} active={true} size={'default'} />
             }
            
           </div>
          </Menu.Item>
           <Menu.Item key="3" className='pt-5 pb-5'>
           <div className='text-xl font-heigh text-center pb-3'>Trade On</div>
             <div className='flex justify-center mt-2'>
             <a target="_blank" rel="noreferrer" href="https://app.pangolin.exchange/#/swap?inputCurrency=0xd60effed653f3f1b69047f2d2dc4e808a548767b" className='mr-5'><img src={Pangolin} style={{width:35,height:35}}/></a>
              <a target="_blank" rel="noreferrer" href="https://www.mexc.com/exchange/UFARM_USDT"><img src={Mountain} style={{width:40,height:40}}/></a>
              </div>
              
             <div className='flex justify-around'>
            
              <a target="_blank" rel="noreferrer" href="https://app.uniswap.org/#/swap?inputCurrency=0x40986a85b4cfcdb054a6cbfb1210194fee51af88&outputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"><img src={Uniswap} style={{width:40,height:40}}/></a>
             <a target="_blank" rel="noreferrer" href="https://pancakeswap.finance/swap#/swap?outputCurrency=0x0A356f512f6fCE740111ee04Ab1699017a908680"><img src={Pancake} style={{width:40,height:40}}/></a>
             <a target="_blank" rel="noreferrer" href="https://exchange.dfyn.network/#/swap?inputCurrency=0xa7305ae84519ff8be02484cda45834c4e7d13dd6&outputCurrency=0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97"><img src={Dfyn} style={{width:40,height:40}}/></a>
             </div>
           </Menu.Item>
        </Menu>
      );
    return(
        <>
        <Dropdown overlay={menu} trigger={['click']} className="bg-DropColor pt-1.5 pb-1.5 pl-3 border-2 rounded-full pr-2 grounded-radiants" >
          <a className="ant-dropdown-link text-white hover:text-white flex items-center text-base" onClick={e => e.preventDefault()}>
        {Price? <><img src={Ulogo} width="20"/> &nbsp;$ {Price.toFixed(5)}</>:  <Skeleton.Input style={{ width: 70,height:30,borderRadius:10 }} active={true} size={'default'} />} &nbsp; <DownOutlined />
        </a>
      </Dropdown>
        </>
    )
}
export default Ufarm