import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, Theme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center'
    },
    AvtarDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '2rem'
    },
    AvtarStyle: {
        height: '200px', width: '200px', background: '#9D3FE7', marginTop: '1.2rem', marginBottom: '1.2rem',
        [theme.breakpoints.down('xs')]: {

        }
    },
    Title: {
        fontFamily: "Nexa",
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "48px",
        lineHeight: "58px"
    },
    Desc: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "26px"
    },
    disWidth: {
        width: '50%'
    }
}))
const CEOData = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.AvtarDiv}>
                <Avatar className={classes.AvtarStyle} />
                <span className={classes.Title}>Mohit Madan</span>
                <span className={classes.Desc}>CEO & Co-founder</span>
            </div>
            <div className={classes.disWidth}>
                <p>I wanted to say a few words on our mission, lets see if that resonates with you!</p>
                <p>We help Defi projects create value for their users by giving them an option to stake tokens.</p>
                <p>What good can someone do by just keeping a crypto asset in the wallet?</p>
                <br />
                <p>Unlike hodling, we start with a basic concept of shared returns (shared in terms of multiple tokens). We built the simplest possible tool to stake tokens with shared diversified returns accessible to users.
                    We work remotely, perpetually challenge how we collaborate and we put great care and passion in what we do.</p>
                <p>Were looking for the brightest folks to join us:</p>
                <p><b>You who deeply care about what you do, how you work,
                    thrive for simplicity, love Unifarms mission and
                    care about good design.‍</b></p>
                <p>If that resonates and you think you can bring us as much as we can bring you, we re excited to hear from you!</p>
            </div>
        </div>
    )
}
export default CEOData