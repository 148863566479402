import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import Slider from "react-slick";

import Businessline from "../../../assets/Logos/mentions/businessline.png";
import Bw from "../../../assets/Logos/mentions/bw.png";
import Coinspeaker from "../../../assets/Logos/mentions/coinspeaker.png";
import Conjournal from "../../../assets/Logos/mentions/conjournal Logo.png";

import Cryptoinforman from "../../../assets/Logos/mentions/cryptoinforman.png";
import CryptoNewsZ from "../../../assets/Logos/mentions/cryptoNewsZ 1.png";
import FinacialExpress from "../../../assets/Logos/mentions/finacial Express.png";
import Indianweb2 from "../../../assets/Logos/mentions/indianweb2 Logo.png";

import News from "../../../assets/Logos/mentions/news 18.png";
import Newsbtc from "../../../assets/Logos/mentions/newsbtc.png";
import Techpanda from "../../../assets/Logos/mentions/tech panda.png";
import Cointelegraph from "../../../assets/Logos/mentions/the cointelegraph.png";

import Daily from "../../../assets/Logos/mentions/the daily hodl.png";
import Economic from "../../../assets/Logos/mentions/the economic times.png";
import Yahoofinance from "../../../assets/Logos/mentions/yahoo finance.png";
import Yourstory from "../../../assets/Logos/mentions/yourstory.png";

import ImageWrapper from "../../ProjectPatner/SubComponent/Imagewrapper";
import ImageItem from "../../ProjectPatner/SubComponent/ImageItem";
import MobileSlider from "./MobileSlider";

const MySlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <div>
      {Mobile ? (
        <MobileSlider />
      ) : Ipad ? (
        <Slider {...settings}>
          <div className="flex flex-col justify-between">
            <ImageWrapper>
            <a href="https://www.thehindubusinessline.com/money-and-banking/nfts-gaining-traction-in-india-as-celebrities-lead-the-way/article37121017.ece">
                <ImageItem    url={Businessline}  />
              </a>
              <a href="http://www.businessworld.in/article/Experts-Hopeful-of-Ethereum-Futures-ETF-on-NYSE-by-Q1-2022/05-11-2021-411053/">
                <ImageItem   url={Bw} />
              </a>
              <a href="https://www.coinspeaker.com/unifarm-simplifying-decentralized-farming/">
                <ImageItem  url={Coinspeaker} />
              </a>
            </ImageWrapper>
            {/* <ImageItem  url={Conjournal} /> */}
            <ImageWrapper>
            <a href="https://cryptoinforman.com/unifarm-simplifying-decentralized-agriculture/">
                <ImageItem  url={Cryptoinforman} />
              </a>
              <a href="https://www.cryptonewsz.com/singularitydao-in-pact-with-unifarm/">
                <ImageItem   url={CryptoNewsZ} />
              </a>
              <a href="https://www.financialexpress.com/market/meme-coin-shiba-inu-pips-dogecoin-to-become-worlds-8th-biggest-crypto-jumps-155-in-seven-days/2358692/">
                <ImageItem  url={FinacialExpress} />
              </a>
            </ImageWrapper>
            {/* <ImageItem  url={Indianweb2} /> */}
          </div>
          <div className="flex flex-col justify-between">
            <ImageWrapper>
            <a href="https://www.news18.com/news/business/bitcoin-ether-solana-price-drop-today-cryptos-crash-as-new-covid-19-variant-emerges-4491884.html"><ImageItem  url={News} /></a>
              <a href="https://www.newsbtc.com/news/company/unifarm-launches-group-farming-with-defi-giants/"><ImageItem  url={Newsbtc} /></a>
              <a href="https://thetechpanda.com/crypto-activity-pouring-wealth-into-the-ecosystem-innovating-to-ease-new-investors/34702/"><ImageItem  url={Techpanda} /></a>
            </ImageWrapper>
            {/* <ImageItem url={Cointelegraph} /> */}
            <ImageWrapper>
            <a href="https://dailyhodl.com/2021/08/11/spaceswap-and-unifarm-unite-for-upcoming-cohort-19-starting-august-10-2021-at-1330-utc/"><ImageItem  url={Daily} /></a>
              <a href="https://economictimes.indiatimes.com/cryptotv-by-coinswitch-kuber-tarusha-mittal-coo-co-founder-unifarm/videoshow/87374651.cms"><ImageItem  url={Economic} /></a>
              <a href="https://finance.yahoo.com/news/team-brought-people-oropocket-unifarm-012900081.html?guccounter=1&guce_referrer=aHR0cHM6Ly9kdWNrZHVja2dvLmNvbS8&guce_referrer_sig=AQAAAEKBbZIIiMgvzGFNSq6ZnTFiPxvCWdPPbQuSSZnDnud4mrFizO7E7QOw7vjzhchQDx6STSnjrKqYoYYuJrJceAaWq2EFtArj0nXEVMfCj7Xdnv4A-5EnJ5WN1UwCO7pKDDMRCDRbsJaqRPP67ZUW4ErmfE0rcdZi2XBkXVts76K3"><ImageItem   url={Yahoofinance} /></a>
            </ImageWrapper>
            {/* <ImageItem url={Yourstory} /> */}
          </div>
        </Slider>
      ) : (
        <Slider {...settings}>
          <div className="flex flex-col justify-between">
            <ImageWrapper>
              <a href="https://www.thehindubusinessline.com/money-and-banking/nfts-gaining-traction-in-india-as-celebrities-lead-the-way/article37121017.ece">
                <ImageItem  url={Businessline} />
              </a>
              <a href="http://www.businessworld.in/article/Experts-Hopeful-of-Ethereum-Futures-ETF-on-NYSE-by-Q1-2022/05-11-2021-411053/">
                <ImageItem  url={Bw} />
              </a>
              <a href="https://www.coinspeaker.com/unifarm-simplifying-decentralized-farming/">
                <ImageItem  url={Coinspeaker} />
              </a>
              <a href="https://coinjournal.net/news/ufarm-token-surges-by-80-where-to-buy-unifarm/">
                <ImageItem  url={Conjournal} />
              </a>

            </ImageWrapper>
            <ImageWrapper>
              <a href="https://cryptoinforman.com/unifarm-simplifying-decentralized-agriculture/">
                <ImageItem  url={Cryptoinforman} />
              </a>
              <a href="https://www.cryptonewsz.com/singularitydao-in-pact-with-unifarm/">
                <ImageItem  url={CryptoNewsZ} />
              </a>
              <a href="https://www.financialexpress.com/market/meme-coin-shiba-inu-pips-dogecoin-to-become-worlds-8th-biggest-crypto-jumps-155-in-seven-days/2358692/">
                <ImageItem  url={FinacialExpress} />
              </a>

              <a href="https://www.indianweb2.com/2021/11/unifarm-listed-on-mexc-global-exchange.html">
                <ImageItem  url={Indianweb2} />
              </a>

            </ImageWrapper>
          </div>
          <div className="flex flex-col justify-between">
            <ImageWrapper>
              <a href="https://www.news18.com/news/business/bitcoin-ether-solana-price-drop-today-cryptos-crash-as-new-covid-19-variant-emerges-4491884.html"><ImageItem  url={News} /></a>
              <a href="https://www.newsbtc.com/news/company/unifarm-launches-group-farming-with-defi-giants/"><ImageItem url={Newsbtc}   /></a>
              <a href="https://thetechpanda.com/crypto-activity-pouring-wealth-into-the-ecosystem-innovating-to-ease-new-investors/34702/"><ImageItem  url={Techpanda} /></a>
              <a href="https://cointelegraph.com/news/defi-projects-face-a-painful-dilemma-right-now-as-they-seek-the-holy-grail"><ImageItem  url={Cointelegraph} /></a>
            </ImageWrapper>
            <ImageWrapper>
              <a href="https://dailyhodl.com/2021/08/11/spaceswap-and-unifarm-unite-for-upcoming-cohort-19-starting-august-10-2021-at-1330-utc/"><ImageItem  url={Daily} /></a>
              <a href="https://economictimes.indiatimes.com/cryptotv-by-coinswitch-kuber-tarusha-mittal-coo-co-founder-unifarm/videoshow/87374651.cms"><ImageItem  url={Economic} /></a>
              <a href="https://finance.yahoo.com/news/team-brought-people-oropocket-unifarm-012900081.html?guccounter=1&guce_referrer=aHR0cHM6Ly9kdWNrZHVja2dvLmNvbS8&guce_referrer_sig=AQAAAEKBbZIIiMgvzGFNSq6ZnTFiPxvCWdPPbQuSSZnDnud4mrFizO7E7QOw7vjzhchQDx6STSnjrKqYoYYuJrJceAaWq2EFtArj0nXEVMfCj7Xdnv4A-5EnJ5WN1UwCO7pKDDMRCDRbsJaqRPP67ZUW4ErmfE0rcdZi2XBkXVts76K3"><ImageItem   url={Yahoofinance} /></a>
              <a href="https://yourstory.com/2021/09/blockchain-startup-oropocket-digital-gold-silver/amp"><ImageItem  url={Yourstory} /></a>
            </ImageWrapper>
          </div>
        </Slider>
      )}
    </div>
  );
};
export default MySlider;
