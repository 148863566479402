import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Investor from '../../assets/image/ExploreSection/investor.png'
import Presure from '../../assets/image/ExploreSection/presure.png'
import Token from '../../assets/image/ExploreSection/token.png'
import FlexContainer from '../Flexcontainer'

// import FlexContainer from '../Flexcontainer'
import { MyCard } from './Subcomponent/Card'
const useStyles = makeStyles(() => ({
    projectClass: {
        width: '100%',
        "&>.slick-slider .slick-list": {
            paddingTop: '0.5rem !important',
            paddingBottom: '1rem !important'
        },
        "&>.slick-slider .slick-dots": {
            paddingBottom: '0.5rem !important'
        }
    }
}));
interface IsProject{
    ContactClick:()=>void
}
const Project = ({ContactClick}:IsProject) => {

    const classes = useStyles()
return (
        <div className={"section-margin" + ' ' + classes.projectClass}>

            <FlexContainer>
                <MyCard
                    Url={Token}
                    margin={7}
                    Imagewidth={90}
                    Title="Creating Utility for the Token"
                    Detail="Once a project is onboarded on UniFarm, their token holders can stake the tokens and get many other good project tokens at high APY. "
                />
                <MyCard
                    Url={Investor}
                    margin={3}
                    Imagewidth={90}
                    Title="Access to New Set of Investors"
                    Detail="Our cross-marketing activities ensures each project gets a new set of prospects and gives projects opportunities to build synergies with other projects. "
                />
                <MyCard
                    Url={Presure}
                    margin={3}
                    Imagewidth={90}
                    Title="Easing the Sell Pressure"
                    Detail="UniFarm can help projects set up targeted farms to ease the sell pressure and incentivizing investors to become long term holders."
                />
            </FlexContainer>




            <div className="flex justify-center mt-5 mb-5">
                <button className="Submit-btn" onClick={ContactClick}>Contact UniFarm</button>
            </div>
        </div>
    )
}
export default Project