import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom'

import DCD from "../../../assets/image/launch/Mobile/DCD.png";
import wizardia from "../../../assets/image/wizardiamobsoldout.png";
import gameyoo from "../../../assets/image/gameyoomobileso.png";
import battleverse from "../../../assets/image/battleversemobileso.png";
// import LegionMobSlider from "../../../assets/image/LegionMobSlider3.png";
// import LegionNetwork from "../../../assets/image/legionmoblivenow.png";
import LegionNetwork from "../../../assets/image/legionmoblivesold.png";
// import Sportzchain_Mobile from "../../../assets/image/Sc_upcoming_mob.png"
import Sportzchain_live from "../../../assets/image/Soldout_Slider_mob.png"

const useStyles = makeStyles(() => ({
  projectClass: {
    width: "100%",

    "&>.slick-slider .slick-list": {
      paddingTop: "0.5rem !important",
      paddingBottom: "1rem !important",
    },
    "&>.slick-slider .slick-dots": {
      bottom: "1rem !important",
    },
  },
}));
const UniFarmMobileSlider = () => {
  const classes = useStyles();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={classes.projectClass}>
      <Slider {...settings}>
        <div>
        <Link  to="/launch-SportzchainIDO" >
       {/* <img src={Sportzchain_upcomming} alt="1"  /> */}
          <img src={Sportzchain_live}/>
          {/* <img src={wizardia}/> */}
          
    </Link>
          
        </div>
        <div>
          <img src={wizardia} />
        </div>
        <div>
          <img src={DCD} />
        </div>
        <div>
          <img src={gameyoo} />
        </div>
        <div>
          <img src={battleverse} />
        </div>
        <div>
        <img src={LegionNetwork} />
        </div>
      </Slider>
    </div>
  );
};
export default UniFarmMobileSlider;
