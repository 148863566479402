import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import DashbordSlider from "./DashBoardSlider";
import MobileSlider from "./MobileSlider";
import Ipadslider from "./IpadSlider";

const CaseStudies = () => {
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <div>
      {Mobile ? <MobileSlider /> : Ipad ? <Ipadslider /> : <DashbordSlider />}
    </div>
  );
};
export default CaseStudies;
