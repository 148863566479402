import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import Twitter from "../../../assets/Ido/Twitter.png";
// import Browser from "../../../assets/Ido/Browser.png";
// import Discord from "../../../assets/Ido/Discord.png";
// import Link from "../../../assets/Ido/Link.png";
import Medium from "../../../assets/Ido/Medium.png";
import Telegram from "../../../assets/Ido/Telegram.png";
import Twitter from "../../../assets/Ido/Twitter.png";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "90px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "80px",
    },
    [theme.breakpoints.down(376)]: {
      marginTop: "60%",
    },
  },
  Main_div: {
    width: "690px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  Title: {
    fontSize: "34px",
    fontWeight: 900,
    lineHeight: "44px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30 px",
    },
  },
  Text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#000000",
    marginTop: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  Text2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  Button_div: {
    display: "flex",
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  Learn_button: {
    width: "300px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    borderRadius: "30px",
    border: "1px solid",
    borderColor: "#CB218E",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    marginRight: "15px",
    color: "#332233",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
      marginRight: "0px",
    },
  },
  Check_button: {
    width: "300px",
    height: "45px",
    background: "linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%)",
    boxShadow: "0px 6px 24px rgba(203, 33, 142, 0.3)",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    border: "1px solid",
    borderColor: "#CB218E",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "white",
    marginLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
      marginLeft: "0px",
      marginTop: "30px",
    },
  },
  Icons: {
    width: "25px",
    height: "25px",
    marginRight: "10px",
    marginLeft: "10px",
  },
}));

const About = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <div className={classes.Root}>
        <div className={classes.Main_div}>
          <span className={classes.Title}>About The Project</span>
          <span className={classes.Text}>
            DCD Ecosystem is a GameFi Infrastructure Protocol, built to help
            gamers build games and integrate DeFi elements. Unique modules allow
            you to create a provably honest online game without the use of
            centralized servers. To increase the gamification level and increase
            profitability, NFT tokens will be implemented inside the game in the
            form of unique avatars and in-game items.The purchase will take
            place only for the DCD token on the intra-platform exchange.
          </span>

          <div className={classes.Button_div}>
            <a href="https://dcdp.io/" target="_blank" rel="noreferrer">
              <button className={classes.Learn_button}>
                Learn More About DCD Ecosystem
              </button>
            </a>
            <a
              href="https://app.unifarm.co/launch/56"
              target="_blank"
              rel="noreferrer"
            >
              <button className={classes.Check_button}>
                Check your eligibility
              </button>
            </a>
          </div>
          <div style={{ display: "flex", marginTop: "50px" }}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/Dcd_ecosystem"
            >
              <img src={Twitter} alt="img" className={classes.Icons} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://t.me/DCDecosystem"
            >
              <img src={Telegram} alt="img" className={classes.Icons} />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://medium.com/@dcd_ecosystem"
            >
              <img src={Medium} alt="img" className={classes.Icons} />
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default About;
