import React from 'react'
import Governance from '../../../assets/image/ExploreSection/governance.png'
import Offer from '../../../assets/image/ExploreSection/offer.png'
import Staking from '../../../assets/image/ExploreSection/staking.png'

import { MyCard } from '../Subcomponent/Card'
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import Uniswap from '../../../assets/image/MenuImages/uniswap.png';
import Dfyn from '../../../assets/image/MenuImages/Dfyn.png';
import Mountain from "../../../assets/image/MenuImages/mountain.png";
import Pangolin from '../../../assets/image/MenuImages/pangolin.png';
import Pancake from '../../../assets/image/MenuImages/pancake.png';
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, width: 391 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: -210,
            top: -2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const useStyles = makeStyles(() => ({
    projectClass:{
      width: '100%'
    },
    buttoner: {
      width: "117px",
      height: "92px",
      marginRight: "5px",
      marginTop: "5px",
      borderRadius:"5px",
      "&:hover":{
         backgroundColor:"#F7F7F7"
      }
    },
  }));
const IpadUfarm = () => {

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes=useStyles()
  
const openApp = () => {
  window.open('https://app.uniswap.org/#/swap')
}

const openApp2 = () => {
  window.open('https://exchange.dfyn.network/#/swap?inputCurrency=0xa7305ae84519ff8be02484cda45834c4e7d13dd6&outputCurrency=0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97');
}

const openApp3 = () => {
  window.open('https://www.mexc.com/exchange/UFARM_USDT');
}

const openApp4 = () => {
 window.open('https://app.pangolin.exchange/#/swap?inputCurrency=0xd60effed653f3f1b69047f2d2dc4e808a548767b');
}

const openApp5 = () => {
 window.open('https://pancakeswap.finance/swap#/swap?outputCurrency=0x0A356f512f6fCE740111ee04Ab1699017a908680');
}
  return (
        <div className={"section-margin"+' '+classes.projectClass}>
          <div className='flex-col items-center justify-center w-auto'>
              <div className="flex items-center justify-center mb-3">
                <MyCard
                margin={3}
                        Url={Governance}
                        Imagewidth={90}
                        Title="Governance"
                        Detail="$UFARM is the governance token for UniFarm platform. All protocol-level changes shall be governed by voting using $UFARM tokens."
                    />
                    <MyCard
                     margin={3}
                        Url={Staking}
                        Imagewidth={90}
                        Title="Staking"
                        Detail="$UFARM token will be part of all major cohorts, enabling token holders to stake $UFARM tokens and earn a multiple high-quality project tokens at a good APY."
                    />
                </div>
               <div className="flex items-center justify-center">
               <MyCard
                margin={3}
                        Url={Offer}
                        Imagewidth={90}
                        Title="Premium Offerings"
                        Detail="UniFarm is collaborating with several platforms, launchpads, and service providers. All these features would be accessible to $UFARM token holders as premium offerings."
                    />
               </div>
            </div>
            <div className="flex justify-center mt-5 mb-5">
                <button className="Submit-btn" onClick={handleClick}>Buy $UFARM</button>
                <BootstrapDialog id="basic-menu" open={open} onClose={handleClose}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Buy $UFARM
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <button className={classes.buttoner} onClick={openApp}><img src={Uniswap} style={{width:40,height:40,marginLeft:38,marginTop:5}}/><div style={{marginTop:"15px"}}>Uniswap</div></button>
            <button className={classes.buttoner} onClick={openApp2}><img src={Dfyn} style={{width:40,height:40,marginLeft:38,marginTop:5}}/><div style={{marginTop:"15px"}}>Dfyn</div></button>
            <button className={classes.buttoner} onClick={openApp3}><img src={Mountain} style={{width:40,height:40,marginLeft:38,marginTop:5}}/><div style={{marginTop:"15px"}}>MEXC</div></button>

            <div>
              <button className={classes.buttoner} onClick={openApp4}><img src={Pangolin} style={{width:40,height:40,marginLeft:38,marginTop:5}}/><div style={{marginTop:"15px"}}>Pangolin</div></button>
              <button className={classes.buttoner} onClick={openApp5}><img src={Pancake} style={{width:40,height:40,marginLeft:38,marginTop:5}}/><div style={{marginTop:"15px"}}>Pancakeswap</div></button>
            </div>
          </DialogContent>
        </BootstrapDialog>
            </div>
        </div>
    )
}
export default IpadUfarm