import React from 'react'
import { makeStyles, Theme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) => ({

    textRoot:{
        textAlign:'center',
        marginTop:'3.5rem',
        marginBottom:'3.5rem',
        [theme.breakpoints.down('xs')]:{

        }
    },
    TextHeading:{
        fontFamily: "Nexa",
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "48px"
    },
    Description:{
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px"
    }
}))
interface isTeamSectionTitle{
    title:string
    description:string
}
const TeamSectionTitle=({title,description}:isTeamSectionTitle)=>
{
    const classes=useStyles()
    return(
      <div className={classes.textRoot}>
         <span className={classes.TextHeading}>{title}</span>
         <p className={classes.Description}>{description}</p>
      </div>
    )
}
export default TeamSectionTitle