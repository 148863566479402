import React from "react";
import Container from "../../component/Container";
import Header from "../../component/Header";
import styled from "styled-components";
import "./index.scss";
import puneMeetupBannerImg from "../../assets/image/PuneMeetupBanner.png";
import image1 from "../../assets/gallery/1.png";
import image2 from "../../assets/gallery/2.png";
import image3 from "../../assets/gallery/3.png";
import image4 from "../../assets/gallery/4.png";
import image5 from "../../assets/gallery/5.png";
import image6 from "../../assets/gallery/6.png";
import image7 from "../../assets/gallery/7.png";
import image8 from "../../assets/gallery/8.png";
import image9 from "../../assets/gallery/9.png";
import image10 from "../../assets/gallery/10.png";
import image11 from "../../assets/gallery/11.png";
import image12 from "../../assets/gallery/12.png";
import image13 from "../../assets/gallery/13.png";
import image14 from "../../assets/gallery/14.png";
import image15 from "../../assets/gallery/15.png";
import vectorImg from "../../assets/gallery/vector.svg";
import backImg from "../../assets/gallery/back.png";

import twitterImg from "../../assets/gallery/Twitter.png";
import telegramImg from "../../assets/gallery/Telegram.png";
import facebookImg from "../../assets/gallery/Facebook.png";
import instagramImg from "../../assets/image/SocialMediaIcon/Instagram1.png";
import linkedinImg from "../../assets/gallery/LinkedIN.png";
import youtubeImg from "../../assets/gallery/Youtube.png";
import redditImg from "../../assets/gallery/Reddit.png";
import discordImg from "../../assets/gallery/Discord.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  max-width: 90%;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding-top: 0px;
    width: 100%;
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #332233;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
const SubTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin: 36px 0;
  color: #332233;
  max-width: 65%;
  @media (max-width: 768px) {
    margin: 20px 0px;
    width: 100%;
    max-width: 90%;
  }
`;
const Banner = styled.div`
  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin-bottom: 55px;
  }
`;

const BannerImg = styled.img`
  object-fit: contain;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 26px 0px;
`;
const BackGroundImg = styled.img`
  position: absolute;
  left: 0px;
  z-index: -1;
`;
const TabButton = styled.button<{ nonActive?: boolean }>`
  background: linear-gradient(93.92deg, #cb218e 1.76%, #663399 84%);
  box-shadow: 0px 6px 24px rgba(203, 33, 142, 0.3);
  border-radius: ${(props) => (props.nonActive ? "unset" : "40px")};
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding: 13px 22px;
  color: ${(props) => (props.nonActive ? "#332233" : "#fff")};
  background: ${(props) => props.nonActive && "transparent"};
  border: ${(props) => props.nonActive && "none"};
  box-shadow: ${(props) => props.nonActive && "none"};
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
`;
const NotifyWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 65px;
    max-width: 90%;
  }
`;

const GalleryTextCont = styled.div`
  position: absolute;
  @media (max-width: 768px) {
    position: inherit;
  }
`;
const GalleryTitle = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #332233;
  max-width: 40%;
  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;
const GallerySubTitle = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #332233;
  max-width: 40%;
  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;
const EmailInputContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 34, 51, 0.2);
  border-radius: 50px;
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;
const Input = styled.input`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgba(51, 34, 51);
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    max-width: 60%;
  }
`;

const SocialsCont = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  @media (max-width: 768px) {
    max-width: 90%;
    margin: 60px auto;
  }
`;
const SocialsTitle = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #332233;
`;
const Socials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const Social = styled.a`
  @media (max-width: 768px) {
    width: 10%;
  }
`;
const SocialImg = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

function GalleryPage() {
  const [closed, setClosed] = React.useState(true);
  return (
    <Container>
      <BackGroundImg src={backImg} />

      <Header ClickHeader={() => console.log("click main")} />

      <Wrapper>
        <Title>Unifarm Meetup</Title>
        <SubTitle>
          After successfully conducting the first chapter of our Web3 meet-ups
          in Delhi, the capital city of India, UniFarm organized the second
          chapter of its Web3 meet-ups in Pune, a city in the central part of
          India.
        </SubTitle>

        <Banner>
          <BannerImg src={puneMeetupBannerImg} />
        </Banner>
        <Title>Recent Meetup</Title>
        <Buttons>
          <TabButton>Pune Chapter</TabButton>
          <TabButton nonActive>Delhi Chapter</TabButton>
          <BackGroundImg src={vectorImg} />
        </Buttons>

        <div className="gallery" style={{ height: closed ? "550px" : "100%" }}>
          <GalleryTextCont>
            <GalleryTitle>
              The art of passive income (Pune Chapter)
            </GalleryTitle>
            <GallerySubTitle>August 10, 2022</GallerySubTitle>
            <GallerySubTitle>
              We are proud to mention here that the ripples of our Web3 event
              were felt till Mumbai, another city in India that sits 154
              kilometers away from Pune. As a result, a number of project
              founders in Web3 space took the time to travel to Pune and witness
              the art of passive income with us.
            </GallerySubTitle>
          </GalleryTextCont>

          <div className="gallery__column 1">
            <figure className="gallery__thumb empty"></figure>

            <figure className="gallery__thumb">
              <img src={image3} alt="Gallery 3" className="gallery__image" />
            </figure>

            <figure className="gallery__thumb">
              <img src={image6} alt="Gallery 6" className="gallery__image" />
            </figure>
            <figure className="gallery__thumb">
              <img src={image13} alt="Gallery 13" className="gallery__image" />
            </figure>
          </div>

          <div className="gallery__column 2">
            <figure className="gallery__thumb empty"></figure>

            <figure className="gallery__thumb">
              <img src={image4} alt="Gallery 4" className="gallery__image" />
            </figure>

            <figure className="gallery__thumb">
              <img src={image7} alt="Gallery 7" className="gallery__image" />
            </figure>
            <figure className="gallery__thumb">
              <img src={image12} alt="Gallery 12" className="gallery__image" />
            </figure>
          </div>

          <div className="gallery__column 3">
            <figure className="gallery__thumb">
              <img src={image1} alt="Gallery 1" className="gallery__image" />
            </figure>

            <figure className="gallery__thumb">
              <img src={image5} alt="Gallery 5" className="gallery__image" />
            </figure>

            <figure className="gallery__thumb">
              <img src={image9} alt="Gallery 9" className="gallery__image" />
            </figure>
            <figure className="gallery__thumb">
              <img src={image10} alt="Gallery 10" className="gallery__image" />
            </figure>
            <figure className="gallery__thumb">
              <img src={image14} alt="Gallery 14" className="gallery__image" />
            </figure>
            <figure className="gallery__thumb">
              <img src={image15} alt="Gallery 15" className="gallery__image" />
            </figure>
          </div>

          <div className="gallery__column 4">
            <figure className="gallery__thumb">
              <img src={image2} alt="Gallery 2" className="gallery__image" />
            </figure>

            <figure className="gallery__thumb">
              <img src={image8} alt="Gallery 8" className="gallery__image" />
            </figure>
            <figure className="gallery__thumb">
              <img src={image11} alt="Gallery 11" className="gallery__image" />
            </figure>
          </div>
        </div>
        <TabButton
          onClick={() => setClosed(!closed)}
          style={{ marginTop: "55px" }}
        >
          {closed ? "View More" : "Close"}
        </TabButton>
        <NotifyWrapper>
          <BackGroundImg src={vectorImg} />
          <Title>Notify for upcoming meetup</Title>
          <SubTitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </SubTitle>
          <EmailInputContainer>
            <Input placeholder="Enter your Email Address" />
            <TabButton>Notify Me</TabButton>
          </EmailInputContainer>
        </NotifyWrapper>

        <SocialsCont>
          <SocialsTitle>Social</SocialsTitle>
          <Socials>
            <Social
              href="https://twitter.com/unifarm_"
              target="_blank"
              rel="noopener"
            >
              <SocialImg src={twitterImg} alt=" twitter " />
            </Social>
            <Social
              href="https://telegram.me/myunifarm"
              target="_blank"
              rel="noopener"
            >
              <SocialImg src={telegramImg} alt=" telegram " />
            </Social>
            <Social
              href="https://www.facebook.com/unifarmofficial/"
              target="_blank"
              rel="noopener"
            >
              <SocialImg src={facebookImg} alt=" facebook " />
            </Social>
            <Social
              href="https://www.youtube.com/channel/UCqoPp0xDua3FKyVnF68EGcw"
              target="_blank"
              rel="noopener"
            >
              <SocialImg src={youtubeImg} alt=" yputube " />
            </Social>
            <Social
              href="https://www.linkedin.com/company/unifarm-co/"
              target="_blank"
              rel="noopener"
            >
              <SocialImg src={linkedinImg} alt=" linkedin " />
            </Social>
            <Social
              href="https://www.reddit.com/r/UniFarm_/"
              target="_blank"
              rel="noopener"
            >
              <SocialImg src={redditImg} alt=" reddit " />
            </Social>
            <Social
              href="https://www.instagram.com/unifarm_official/"
              target="_blank"
              rel="noopener"
            >
              <SocialImg src={instagramImg} alt=" insta " />
            </Social>
            <Social
              href="https://discord.com/invite/J5GQAEf7PJ"
              target="_blank"
              rel="noopener"
            >
              <SocialImg src={discordImg} alt=" discord " />
            </Social>
          </Socials>
        </SocialsCont>
      </Wrapper>
    </Container>
  );
}
export default GalleryPage;
