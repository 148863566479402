import React, { useEffect } from "react";
import About from "./About";
import Company from "./Company";
import Trade from "./Trade";
import Logo from "../../assets/image/Logo/logo2.png";
import CopyRightText from "./SubComponent/CopyrightText";
import Social from "./Social";
import { makeStyles } from "@material-ui/core/styles";
import AOS from "aos";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IpadFooter from "./IpadFotter";

const useStyles = makeStyles((theme)=>({
  AddressText: {
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },

}))



interface Isfooter {
  Investor: () => void;
  IpadClick: () => void;
  RoadMapClick2: () => void;
  RoadMapClickIpad: () => void;
  WhyUnifarmClickIpad: () => void;
  WhyUnifarmClick2: () => void;
  isInnerPage:boolean;  
}
const Footer = ({
  Investor,
  IpadClick,
  RoadMapClick2,
  RoadMapClickIpad,
  WhyUnifarmClickIpad,
  WhyUnifarmClick2,
  isInnerPage
}: Isfooter) => {
  const theme = useTheme();
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  useEffect(() => {
    AOS.init({
      duretion: 2000,
    });
  });

  const classes = useStyles();

  return (
    <div>
      {Ipad ? (
        <IpadFooter
          IpadInvestor={IpadClick}
          IpadRoadMap={RoadMapClickIpad}
          IpadWhyUnifarmClick={WhyUnifarmClickIpad}
          isInnerIpadPage={isInnerPage}
        />
      ) : (
        <>
          <div className="flex sm:flex-col">
            <div
              className="sm:flex sm: flex-col items-center justify-center mobile-footer"
              style={{ marginTop: "-0.7rem" }}
            >
              <a href="/">
                <img
                  src={Logo}
                  className="h-auto w-52"
                  // style={{ height: "auto", width: 215 }}
                  data-aos="fade-right"
                />
              </a>
              <div className="sm:text-center mt-3">
                 <p className={classes.AddressText} >Registered Address :</p>
                 <b>Sharc Technologies Ltd</b>
                 <p className={classes.AddressText}>Craigmuir Chambers, Road Town, Tortola, VG 1110, British Virgin Islands</p> 
              </div>
            </div>
             <div>
              </div> 

            <div className="flex">
              <Trade />

              <About
                RoadMapClick={RoadMapClick2}
                WhyUnifarmClick={WhyUnifarmClick2}
                isPage={isInnerPage}
              />
            </div>
            <div className="flex">
              <Company InvestorClick={Investor} isPage={isInnerPage}/>
              <Social />
            </div>
          </div>
          <CopyRightText
            title="Copyright©2022 Powered by UniFarm"
          />
        </>
      )}
    </div>
  );
};
export default Footer;
