import React from "react";

interface Isimage1 {
  Url: any;
  width: number;
  margin: number;
}

const CardImage = ({ Url, width, margin }: Isimage1) => {
  return (
    <img
      src={Url}
      width={width}
      className={"mb-" + margin + ""}
      style={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
    />
  );
};
export default CardImage;
