import React, { useRef } from "react";
import Container from "../../component/Container";
import Header from "../../component/Header";
import Herosection from "../../component/HeroSection";
import CounterSection from "../../component/Countersection";
// import CohortSection from "../../component/Cohortsection/index";
import MyTab from "../../component/Exploresection/Subcomponent/Tab";
import ProjectPatner from "../../component/ProjectPatner/index";
import RoadMap from "../../component/RoadMap";
import Investor from "../../component/Investor";
import CaseStudies from "../../component/CaseStudies";
import Mention from "../../component/Mentions";
import Suscribe from "../../component/Subscribe";
import Footer from "../../component/Footer";
import FlotingIcon from "../../component/FloatingIcon";
import Banner from "../../component/Banner/index";
import HelloBar from "../../component/HelloBar";
import { useTheme, useMediaQuery } from "@material-ui/core";

const LandingPage = () => {
  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      left: ref.current.offsetTop,
      behavior: "smooth",
    });
  const scrollToRef2 = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      left: ref.current.offsetTop,
      behavior: "smooth",
    });
  const scrollToRef3 = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      left: ref.current.offsetTop,
      behavior: "smooth",
    });
  const scrollToRef4 = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      left: ref.current.offsetTop,
      behavior: "smooth",
    });
  const myRef = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);
  const myRef4 = useRef(null);
  const executeScroll = () => {
    scrollToRef(myRef);
  };
  const executeScroll2 = () => scrollToRef2(myRef2);
  const executeScroll3 = () => scrollToRef3(myRef3);
  const executeScroll4 = () => scrollToRef4(myRef4);
  const Theme = useTheme();
  const Mobile = useMediaQuery(Theme.breakpoints.down("xs"));

  const desc =
    "Stake one token and earn multiple token as rewards with high APY and low risk";
  const title = "One Farm to Rule Them All";

  React.useEffect(() => {
    document.title = `UniFarm | ${title}`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", desc);
    document
      .querySelector('meta[name="og:title"]')
      .setAttribute("content", title);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", desc);
  }, [title]);

  return (
    <>
      {Mobile ? null : (
        <HelloBar
          ArrowClick={() => console.log("Click")}
          innerArrowClick={() => console.log("Click")}
        />
      )}

      <Container>
        <Header ClickHeader={() => console.log("click main")} />
        <div className="bg-svg">
          <Herosection />
          <CounterSection />
          {/* <CohortSection /> */}
          <Banner />
        </div>
        <div
          className="bg-svg2 "
          style={{ marginTop: "30px", marginBottom: "30px" }}
        >
          <div ref={myRef4}>
            <br />
            <MyTab
              ProjectClick={executeScroll2}
              IpadProjectClick={executeScroll2}
              MobileProjectClick={executeScroll2}
            />
          </div>
          <ProjectPatner />
        </div>
        <div className="bg-svg3">
          <div ref={myRef3}>
            <br />
            <RoadMap />
          </div>

          <div ref={myRef}>
            <br />
            <br />
            <Investor />
          </div>
        </div>

        <div
          className="bg-svg4"
          style={{ marginTop: "60px", marginBottom: "30px" }}
        >
          <CaseStudies />
          <Mention />
        </div>
        <div className="bg-svg5 ">
          <div ref={myRef2} className="mt-8 mb-8">
            <Suscribe />
          </div>
          <div>
            <Footer
              Investor={executeScroll}
              IpadClick={executeScroll}
              RoadMapClick2={executeScroll3}
              RoadMapClickIpad={executeScroll3}
              WhyUnifarmClick2={executeScroll4}
              WhyUnifarmClickIpad={executeScroll4}
              isInnerPage={false}
            />
          </div>
        </div>

        <FlotingIcon />
      </Container>
    </>
  );
};
export default LandingPage;
