import React from "react";
// import HelloBar from "../../component/HelloBar";
import { useTheme, useMediaQuery } from "@material-ui/core";
import Container from "../../component/Container";
import Header from "../../component/Header";
import LaunchpadSlider from "../../component/Unifarm-launchpad/LaunchPadSlider";
import UpcommingTabList from "../../component/Unifarm-launchpad/UpcommingTabList";
import PastIdos from "../../component/Unifarm-launchpad/PastIdos";
import Footer from "../../component/Footer";
// import { makeStyles } from "@material-ui/core/styles";
// import Rocket from "../../assets/image/rocket.png";
// import RocketBoost from "../../assets/image/RocketBoost.png";


interface IsLaunchpad {
  title: string;
  desc: string;
}

const UnifarmLaunchpad = ({ title, desc }: IsLaunchpad) => {
  const Theme = useTheme();
  const Mobile = useMediaQuery(Theme.breakpoints.down("xs"));
  const [isMargin, setIsmargin] = React.useState(true);

  React.useEffect(() => {
    document.title = `UniFarm | ${title}`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", desc);
    document
      .querySelector('meta[name="og:title"]')
      .setAttribute("content", title);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", desc);
  }, [title]);

  return (
    <>
      <Container>
        <Header ClickHeader={() => setIsmargin(!isMargin)} />
        {Mobile ? (
          <div style={{ marginTop: isMargin ? "-4rem" : "5rem" }}>
            <LaunchpadSlider />
          </div>
        ) : (
          <LaunchpadSlider />
        )}

        <UpcommingTabList />
        <PastIdos />
        <div style={{ marginTop: "8rem" }}>
          <Footer
            Investor={() => console.log("click")}
            IpadClick={() => console.log("click")}
            RoadMapClick2={() => console.log("click")}
            RoadMapClickIpad={() => console.log("click")}
            WhyUnifarmClick2={() => console.log("click")}
            WhyUnifarmClickIpad={() => console.log("click")}
            isInnerPage={true}
          />
        </div>
      </Container>
    </>
  );
};
export default UnifarmLaunchpad;
