import React from 'react'
import Investor from '../../../assets/image/ExploreSection/investor.png'
import Presure from '../../../assets/image/ExploreSection/presure.png'
import Token from '../../../assets/image/ExploreSection/token.png'

import { MyCard } from '../Subcomponent/Card'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    projectClass:{
      width: '100%'
    }
  }));
  interface IsProject{
    ContactClickIpad:()=>void
}
const IpadProject = ({ContactClickIpad}:IsProject) => {
  const classes=useStyles()

  
    return (
        <div className={"section-margin"+' '+classes.projectClass}>


            <div className='flex-col items-center justify-center w-auto'>

                <div className="flex items-center justify-center mb-3">
                <MyCard
                        Url={Token}
                        Imagewidth={90}
                        margin={3}
                        Title="Creating Utility for the Token"
                        Detail="Once a project is onboarded on UniFarm, their token holders can stake the tokens and get many other good project tokens at high APY. "
                    />
                    <MyCard
                        Url={Investor}
                        Imagewidth={90}
                        margin={3}
                        Title="Access to New Set of Investors"
                        Detail="Our cross-marketing activities ensures each project gets a new set of prospects and gives projects opportunities to build synergies with other projects "
                    />
                </div>
               <div className="flex items-center justify-center">
               <MyCard
                        Url={Presure}
                        Imagewidth={90}
                        margin={3}
                        Title="Easing the Sell Pressure"
                        Detail="UniFarm can help projects set up targeted farms to ease the sell pressure and incentivizing investors to become long term holders"
                    />
               </div>
            </div>
            <div className="flex justify-center mt-5 mb-5">
                <button className="Submit-btn" onClick={ContactClickIpad}>Contact UniFarm</button>
            </div>
        </div>
    )
}
export default IpadProject