import React from "react";
import HeroSection from "../../component/Team/HeroSection";
import Container from "../../component/Container";
import Header from "../../component/Header";
import Values from "../../component/Team/Values";
import Profile from "../../component/Team/Profile/index";
import CEO from "../../component/Team/CEO";
import Work from "../../component/Team/Work/index";
import Join from "../../component/Team/Join";

interface IsTeam {
  title: string;
  desc: string;
}

const Team = ({ title, desc }: IsTeam) => {
  React.useEffect(() => {
    document.title = `UniFarm | ${title}`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", desc);
    document
      .querySelector('meta[name="og:title"]')
      .setAttribute("content", title);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", desc);
  }, [title]);
  return (
    <Container>
      <Header ClickHeader={() => console.log("click main")} />
      <HeroSection />
      <Values />
      <Profile />
      <CEO />
      <Work />
      <Join />
    </Container>
  );
};
export default Team;
