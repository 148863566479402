
import AOS from 'aos'
import React, { useEffect } from 'react'
import Logo from '../../assets/image/Logo/logo2.png'
import About from './About'
import Company from './Company'
import Social from './Social'
import CopyRightText from './SubComponent/CopyrightText'
import Trade from './Trade'
import { makeStyles } from "@material-ui/core/styles";
// import { useTheme } from "@material-ui/core/styles";





interface IsIpad{
    IpadInvestor:()=>void
    IpadRoadMap:()=>void
    IpadWhyUnifarmClick:()=>void
    isInnerIpadPage:boolean
}
const IpadFooter = ({IpadInvestor,IpadRoadMap,IpadWhyUnifarmClick,isInnerIpadPage}:IsIpad) => {
  useEffect(() => {
        AOS.init({
            duretion: 2000
        })
    })

const useStyles = makeStyles(()=>({
    AddressText: {
      fontSize: "16px",      
    },
  
  }))

    const classes =  useStyles();
    return (
        <div>
            <div className='flex ' style={{flexDirection:'column'}}>
                <div className="xs:flex xs: flex-col items-center justify-center" style={{display:'flex',justifyContent:'center',marginBottom:'1rem'}}>
                    <img src={Logo} style={{ height: 60, width: 220 }} data-aos="fade-right" />
                </div>
                <div style={{ width:"100%", display:"flex",flexDirection:'column',justifyContent:'center',alignItems: 'center'}}>

                <div className=" text-center">
                 <p className={classes.AddressText} >Registered Address :</p>
                 <b>Sharc Technologies Ltd</b>
                 <p className={classes.AddressText}>Craigmuir Chambers, Road Town, Tortola, VG 1110, British Virgin Islands</p> 
              </div>
                </div>
              
              <div className='flex justify-between'>
              {/* <div >
                   <a href="/"><img src={Logo} style={{ height: 40, width: 130 }} data-aos="fade-right" /></a> 
                </div> */}
                <Trade />
                <About RoadMapClick={IpadRoadMap} WhyUnifarmClick={IpadWhyUnifarmClick} isPage={isInnerIpadPage}/>
                <Company InvestorClick={IpadInvestor} isPage={isInnerIpadPage}/>
                <Social />
              </div>
            </div>

            
            <CopyRightText title="Copyright©2021 Powered by Oropocket" />
        </div>


    )
}
export default IpadFooter