import AOS from 'aos'
import React, { useEffect } from 'react'
import Sectiontitle from '../Sectiontitle/index'
import Slider from './SubComponent/slider'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import IpadeSlider from './SubComponent/IpadSlider'
import MobileSlider from './SubComponent/MobileSlider'
const RoadMap=()=>
{
    const theme=useTheme()
    const mobile=useMediaQuery(theme.breakpoints.down('xs'))
    const ipad=useMediaQuery(theme.breakpoints.between('sm','md')) 
    useEffect(()=>{
        AOS.init()
    },[])
    return(
        <>
        <Sectiontitle title='Roadmap'/>
       <div data-aos="fade-right">
           {
               ipad?
             <IpadeSlider/>
               :mobile?
               <MobileSlider/>
               :
               <Slider/>

           }
     
       </div>
       
        </>
    )
}
export default RoadMap