import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import Logo from "../../assets/image/Logo/logo2.png";
import CloseIcon from "@material-ui/icons/Close";
import { Drawer } from "antd";
import MobileMenu from "./sub-component/MobileMenu";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import HelloBar from "../HelloBar/index";
const useStyles = makeStyles(() => ({
  root: {
    background: "white",
    boxShadow: "none",
    width: "100%",
    marginTop: "-1px",
    zIndex: 11,
    padding: "10px 0px",
  },
  toolRoot: {
    width: "100%",
  },
  MenuIcon: {
    color: "black",
  },
  IpadRoot: {
    display: "flex",
    justifyContent: "left",
    width: "100%",
    paddingTop: "5px",
  },
  MobileRoot: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginLeft: "-30px",
    paddingTop: "5px",
  },
  DrawerRoot: {
    width: "100%",
  },
}));
interface IsHeader {
  clickHelloBar: () => void;
}
const MobileHeader = ({ clickHelloBar }: IsHeader) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [DydemicMargin, setmargin] = useState(true);
  const theme = useTheme();
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const DrawerOpen = () => {
    setOpen(!open);
  };
  const DrawerClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ marginBottom: DydemicMargin ? "16rem" : "5rem" }}>
      <AppBar position="fixed" className={classes.root}>
        <HelloBar
          ArrowClick={() => setmargin(!DydemicMargin)}
          innerArrowClick={clickHelloBar}
        />
        <Toolbar className={classes.toolRoot}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={DrawerOpen}
          >
            <MenuIcon className={classes.MenuIcon} />
          </IconButton>
          {Ipad ? (
            <div className={classes.IpadRoot}>
              <a href="/">
                <img src={Logo} width={180} height="auto" />
              </a>
            </div>
          ) : (
            <div className={classes.MobileRoot}>
              <a href="/">
                <img src={Logo} width={180} height="auto" />
              </a>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        title={
          <div
            className={
              "flex items-center justify-between" + " " + classes.DrawerRoot
            }
          >
            <img src={Logo} width={180} />
            <IconButton onClick={DrawerClose}>
              <CloseIcon />
            </IconButton>
          </div>
        }
        placement="left"
        onClose={DrawerClose}
        visible={open}
      >
        <MobileMenu />
      </Drawer>
    </div>
  );
};
export default MobileHeader;
