import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Explorel from '../index'
import SectionTitle from '../../Sectiontitle/index'
import Project from '../Project'
import Ufarm from '../Ufarm';

import { useMediaQuery } from '@material-ui/core'
import IpadExploreSection from '../Ipad/User';
import MobileUser from '../Mobile/User';
import IpadProject from '../Ipad/Project';
import MobileProjetc from '../Mobile/Project';
import IpadUfarm from '../Ipad/Ufarm';
import MobileUfarm from '../Mobile/Ufarm';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    
    
    
  },
  myTab:{
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected":{
    background:'linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%) !important',
    maxWidth:100,
    minWidth:0,
    padding:'7px 8px',
   color:'white',
   textTransform:'capitalize',
    borderRadius:30
    },
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorPrimary":{
      maxWidth:100,
      minWidth:0,
      padding:'7px 8px',
 
      textTransform:'capitalize'
    }
   
  },
  appbar:{
    borderRadius:35,
    boxShadow:'none',
    width:291,
    height:49,
    zIndex:1,
    marginBottom:'1.5rem'
  },
  indicator:{ 
    background:'transparent'
  },
  fontFamily:{
    fontFamily:'Inter !important'
}

}));
interface IsExplore{
  ProjectClick:()=>void
  IpadProjectClick:()=>void
  MobileProjectClick:()=>void
}
const ExploreTab=({ProjectClick,IpadProjectClick,MobileProjectClick}:IsExplore)=> {
  const classes = useStyles();
  const theme = useTheme();
  const Ipad = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <SectionTitle title="Explore UniFarm" />
      <div className="flex justify-center ">
      <AppBar position="static" color="default" className={classes.appbar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.myTab}
          classes={{indicator:classes.indicator}}
          centered
        >
          <Tab label="Users" {...a11yProps(0)} className={classes.fontFamily}/>
          <Tab label="Projects" {...a11yProps(1)} className={classes.fontFamily}/>
          <Tab label="$UFARM" {...a11yProps(2)} className={classes.fontFamily}/>
        </Tabs>
      </AppBar>
      </div>
     
        <TabPanel value={value} index={0} dir={theme.direction} >
          {
            Ipad?
            <IpadExploreSection/>
            :Mobile?
           <MobileUser/>
           : <Explorel/>
          }
        
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {
            Ipad?
            <IpadProject ContactClickIpad={IpadProjectClick}/>
            :Mobile?
            <MobileProjetc ContactClickMobile={MobileProjectClick}/>
            :
            <Project ContactClick={ProjectClick}/>
          }
       </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {
              Ipad?
              <IpadUfarm/>
              :Mobile?
              <MobileUfarm/>
              :
              <Ufarm/>
          }
      
        </TabPanel>
      
    </div>
  );
}
export default ExploreTab