import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  mobileCarousel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "0 auto",
    marginTop: "2rem",
    width: "342px",
    height: "458px",

    /* background/main */

    background: "#FFFFFF",
    /* font/disabled */

    border: "1px solid  rgba(224, 224, 224, 1)",
    boxSizing: "border-box",
    boxShadow: "4px 4px 25px #E0E0E0",
    borderRadius: "15px",
  },

  BannerForIdo: {
    display: "flex",
    justifyContent: "center",
  },

  onlyText: {
    [theme.breakpoints.between(600, 900)]: {
      width: "230px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "220px",
    },
  },

  textRocket: {
    position: "relative",
    marginTop: "25%",
    left: "-40%",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "26px",
    color: "#FFFFFF",
    [theme.breakpoints.between(600, 900)]: {
      marginTop: "15%",
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      left: "-50%",
      marginTop: "17%",
    },
  },

  buttons: {
    display: "flex",
    width: "425px",
    justifyContent: "space-between",
    marginTop: "20px",
    [theme.breakpoints.between(600, 900)]: {
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      left: "-30%",
      top: "-7%",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      left: "-30%",
      top: "-7%",
    },
  },

  individButton1: {
    border: "1px solid #FFFFFF",
    borderRadius: "30px",
    textTransform: "capitalize",
    width: "50%",
    height: "39px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFFFFF",
    [theme.breakpoints.between(600, 900)]: {
      width: "40%",
      fontSize: "12px",
      marginTop: "2%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "40%",
      fontSize: "12px",
      marginTop: "2%",
    },
  },
  individButton2: {
    background:
      "linear-gradient(93.92deg, #FFFFFF 1.76%, #FFFFFF 84%) !important",
    boxShadow: "0px 6px 24px rgba(203, 33, 142, 0.3)",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#663399",
    borderRadius: "30px",
    textTransform: "capitalize",
    width: "40%",
    height: "39px",
    [theme.breakpoints.between(600, 900)]: {
      width: "40%",
      fontSize: "12px",
      marginTop: "3%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "40%",
      fontSize: "12px",
      marginTop: "3%",
    },
  },

  sliderHeader: {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginTop: "2.5rem",
    fontSize: "40px",
    fontWeight: 800,
  },
  SliderRoot: {
    height: 310,
    marginTop: "1.8rem",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: "transparent !important",
    marginTop: "5rem",
    [theme.breakpoints.down["xs"]]: {
      display: "flex",
      flexFlow: "wrap",
      columnGap: 30,
    },
  },
  myTab: {
    [theme.breakpoints.down("xs")]: {
      display: "unset",
      overflow: "auto",
    },
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root": {
      background: "#F7F7F7 !important",
      padding: "7px 8px",
      color: "black",
      textTransform: "capitalize",
      borderRadius: 30,
      border: "1px solid #CB218E !important",
      marginRight: "1rem",
      width: 181,
      [theme.breakpoints.between("sm", "md")]: {
        width: 100,
      },
      //    borderImageSource: "linear-gradient(96.31deg, #CB218E 9.42%, #673AB7 100%) !important"
    },
    "&>.MuiTabs-scroller .MuiTabs-flexContainer": {
      justifyContent: "center !important",
    },
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected":
      {
        background:
          "linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%) !important",
        padding: "7px 8px",
        color: "white",
        textTransform: "capitalize",
        borderRadius: 30,
        boxShadow: "0px 6px 24px rgba(203, 33, 142, 0.3) !important",
      },
    "&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorPrimary": {
      maxWidth: 165,
      minWidth: 0,
      padding: "7px 8px",

      textTransform: "capitalize",
    },
  },
  appbar: {
    borderRadius: 35,
    boxShadow: "none",

    background: "transparent",
  },
  indicator: {
    background: "transparent",
  },
  tabHeader: {
    textAlign: "center",
    marginBottom: "2rem",
    fontSize: "40px",
    fontWeight: 800,
  },
  TabPanel: {
    marginTop: "1.5rem",
  },
  UpcommingCardRoot: {
    width: "380px",
    height: "515px",
    left: "0px",
    top: "0px",
    background: "transparent",
    // border: "1px solid #F282AE",
    boxSizing: "border-box",
    borderRadius: "15px",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "535px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "345px",
      height: "540px",
    },
  },
  UpcommingImg: {
    width: "418px",
    height: "235px",
  },
  UpcommingCardContent: {
    height: "232px",
    paddingLeft: "19px",
    paddingRight: "19px",
    color: "white",
  },
  UpcommingCardSpan: {
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "26px",
    color: "#332233",
    textAlign: "left",
    // marginLeft: "0.5rem",
  },
  UpcommingIdoDiv: {
    width: "161.09px",
    height: "56px",
    display: "flex",
    marginTop: "0.5rem",
    "&>img": {
      width: "42px",
      height: "42px",
      marginRight: "0.5rem",
    },
    "&>span": {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "30px",

      color: "white",
    },
  },
  TabPanelRoot: {
    display: "flex",
    columnGap: "20px",
    flexFlow: "wrap",
    rowGap: 20,
  },
  UpcommingDivContent: {
    textAlign: "left",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#332233",
  },
  PastCardRoot: {
    //       top: "1652px",
    // left: "38px",
    width: "390px",
    // border: "1px solid #E0E0E0",
    height: "479px",
    background: "transparent",
    // boxSizing: "border-box",
    borderRadius: "15px",
    marginBottom: "1REM",
    [theme.breakpoints.down("xs")]: {
      width: "365px",
      height: "auto",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "340px",
      height: "100%",
    },
  },
  PastImage: {
    width: "418px",
    height: "189px",
  },
  pastLabel: {
    width: "70px",
    height: "70px",
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    boxSizing: "border-box",
    borderRadius: "15px",
    marginTop: "-35px",
    marginLeft: "25px",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  labelImage: {
    width: "54px",
    height: "50px",
  },
  SolodOutLable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 10px 10px 12px",
    position: "absolute",
    height: "39px",
    left: "71%",
    marginTop: "-11rem",
    background: "#DFFFED",
    border: "1px solid #009F42",
    boxSizing: "border-box",
    borderRadius: "30px",
    textTransform: "capitalize",
    "&>span": {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#009F42",
    },
    [theme.breakpoints.down("xs")]: {
      left: "65%",
    },
  },
  PastHeading: {
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "50px",
    color: "#332233",
  },
  PastContent: {
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#332233",
  },
  PastContentDiv: {
    paddingLeft: "1.48rem",
    paddingTop: "3.5rem",
    paddingRight: "0.5rem",
    height: "220px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  PastButtons: {
    border: "1px solid #F282AE",
    borderRadius: "34px",
    padding: "3px 9px 3px 9px",
    marginRight: "10px",
    marginLeft: 25,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 20,
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "1rem",
    },
    "&>span": {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#332233",
    },
  },
  UpcommingButtons: {
    border: "1px solid #F282AE",
    borderRadius: "34px",
    padding: "3px 9px 3px 9px",
    marginRight: "10px",
    width: 115,
    textAlign: "center",
    "&>span": {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#332233",
    },
  },
  ViewMoreDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
  },
  ViewMore: {
    background: "linear-gradient(93.92deg, #CB218E 1.76%, #663399 84%)",
    boxShadow: "6px 4px 14px rgba(103, 58, 183, 0.45)",
    borderRadius: "70px",
    width: "145px",
    height: "46px",
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
  pastHeader: {
    textAlign: "center",
    marginBottom: "1.5rem",
    fontSize: "40px",
    fontWeight: 800,
    marginTop: "3rem",
  },
  hoverSpan: {
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#332233",
  },
  HoverDiv: {
    display: "flex",
    alignItems: "center",
    marginLeft: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  hoverCardRoot: {
    marginTop: "-7rem",
  },
  PastCardResponsive: {
    display: "flex",
    flexFlow: "wrap",
    columnGap: 30,
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      rowGap: 20,
      justifyContent: "space-between",
    },
  },
}));
